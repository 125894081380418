import React, { Component } from 'react';
import './BasicHero.scss';

export default class BasicHero extends Component {
  render() {
    let { heroImg, headline } = this.props;

    const heroStyle = {
      backgroundImage: `url(${heroImg.background.dataOriginal})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    };

    return (
      <div className={`hero hero--basic ctnr`} style={heroStyle}>
        <div className={`hero__content`}>
          <h1 className="headline headline--alt headline--alt-lg yellow">
            {headline}
          </h1>
        </div>
      </div>
    );
  }
}
