import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./GoogleMap.scss";

export default class SearchResults extends Component {
  render() {
    if(this.props.zoom < 6) {
      return null;
    }

    let collapseText = this.props.resultsOpen ? "Hide List" : "Show List"
    
    if(this.props.activeShop) {
      return (
        <div className={`search__results-ctnr`}>
          <div className={`search__results ${this.props.resultsOpen ? "open" : ""}`}>
            <div className="search__return">
              <a className="yellow link--yellow" onClick={this.props.backToResults}><span className="arrow arrow--flipped"></span>Back to Results</a>
            </div>
            <div className="search__result">
              <h2 className={`search__name headline headline--italic ${this.props.activeShop["OL?"] ? 'orange' : ''}`}>{ this.props.activeShop["Shop Name"] }</h2>
              <p className="copy search__desc">{ this.props.activeShop.desc }</p>
              {this.props.activeShop.Phone !== "N/A" && (
                <p className="search__copy"><a className="copy copy--sans search__cta" href={`tel:${this.props.activeShop.Phone}`}>{ this.props.activeShop.Phone }</a></p>
              )}
              <p className="copy copy--sans search__copy">{ this.props.activeShop.Address }</p>
              <div className="feat-shop">
                { this.props.activeShop["OL?"] && (
                  <img src="/icons/maps/Carrot-03.svg" alt="Orange Label" className="ol-icon" />
                )}
                { this.props.activeShop["Enabled"] && (
                  <Link className="link copy copy--sans shop-link" to={`/${this.props.activeShop["Shop Page"]}`}>Featured Skateshop Page</Link>
                )}
                {(this.props.activeShop.Website !== "N/A" && !this.props.activeShop.Enabled) && (
                  <p className="search__copy">
                    <a  className="copy copy--sans search__cta"
                        target="blank"
                        href={`${ this.props.activeShop.Website }`}
                        data-track-click={JSON.stringify({
                            event_action: "Skateshop click",
                            event_category: "Shop Website",
                            event_label: `Skateshop click: ${this.props.activeShop.Website}`,
                        })}
                    >{ this.props.activeShop.Website }</a>
                  </p>
                )}
              </div>
              { this.props.activeShop.Enabled && (
                  <a  href={`/${this.props.activeShop["Shop Page"]}`}
                      data-track-click={JSON.stringify({
                        event_action: "Skateshop click",
                        event_category: "Shop Website",
                        event_label: `Skateshop click: ${this.props.activeShop.Website}`,
                      })}
                  >
                    <div
                      className="search__result-profile-photo"
                      dangerouslySetInnerHTML={{ __html: this.props.activeShop.HeroImg }}
                    />
                  </a>
                ) 
              }
            </div>
            <button className="collapse-results" onClick={this.props.toggleResults}>
              {collapseText}
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className={`search__results-ctnr`}>
        <div className={`search__results ${this.props.resultsOpen ? "open" : ""}`}>
          {this.props.markers.length ? this.props.markers.map((marker, i) => {
            let key = "mapMarker:" + i;
            return (
              <div className="search__result" key={key}>
                <h2 className={`search__name headline headline--italic ${marker["OL?"] ? 'orange' : ''}`} onClick={ () => this.props.goToShop(marker) }>{ marker["Shop Name"] }</h2>
                {marker.Phone !== "N/A" && (
                  <p className="search__copy"><a className="copy copy--sans search__cta" href={`tel:${marker.Phone}`}>{ marker.Phone }</a></p>
                )}
                <p className="copy copy--sans search__copy">{ marker.Address }</p>
                <div className="feat-shop">
                  { marker["OL?"] && (
                    <img src="/icons/maps/Carrot-03.svg" alt="Orange Label" className="ol-icon" />
                  )}
                  { marker["Enabled"] && (
                    <Link className="link copy copy--sans shop-link" to={`/${marker["Shop Page"]}`}>View Profile</Link>
                  )}
                  {(marker.Website !== "N/A" && !marker.Enabled) && (
                    <p className="search__copy">
                      <a  className="copy copy--sans search__cta" 
                          target="blank"
                          href={`${ marker.Website }`}
                          data-track-click={JSON.stringify({
                            event_action: "Skateshop click",
                            event_category: "Shop Website",
                            event_label: `Skateshop click: ${marker.Website}`,
                          })}
                      >
                      { marker.Website }
                      </a>
                    </p>
                  )}
                </div>
                { marker["Enabled"] ? (
                  <Link to={`/${marker["Shop Page"]}`}>
                    <div
                      className="search__result-profile-photo"
                      dangerouslySetInnerHTML={{ __html: marker.HeroImg }}
                    />
                  </Link>
                )
                : (
                  <a className="yellow link--yellow search__result-arrow" onClick={ () => this.props.goToShop(marker) }><span className="arrow"></span></a>
                )
                }
              </div>
            );
          }) : (
            <h2>No results found, try another search.</h2>
          )}
          <button className="collapse-results" onClick={this.props.toggleResults}>
            {collapseText}
          </button>
        </div>
      </div>
    );
  }
}
