import React, { Component } from 'react';
import './ShoeOverImage.scss';
import { Link } from 'react-router-dom';

// expected props:
// shoeImageURL: string
// backgroundImageURL: string
// linkURL: string

export default class ShoeOverImage extends Component {
  render() {
    let gridArea = this.props.gridArea || '';
    let { bgImage, ctaCopy, productImage, uri, moduleName, eventAction } = this.props;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: `${eventAction}`,
        event_category: "Page Content",
        event_label: `${eventAction}: ${moduleName} - ${ctaCopy}`,
      }
    }

    return (
      <Link
        to={`${uri}`}
        className="parent"
        data-count-grid-module
        data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <div className={`shoe-over-image ${gridArea}`}>
          <div className="shoe-over-image__images">
            <div
              className="shoe-over-image__image"
              dangerouslySetInnerHTML={{ __html: bgImage.asset }}
            />
            <div
              className="shoe-over-image__shoe"
              dangerouslySetInnerHTML={{ __html: productImage.asset }}
            />
          </div>
          <span
            className="link cta-span"
          >
            <span className="arrow" />
            <span className="link__text">{ctaCopy}</span>
          </span>
        </div>
      </Link>
    );
  }
}
