import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import Measure from 'react-measure';
import AriaModal from 'react-aria-modal';
import VideoModal from '../VideoModal/VideoModal';
import './SquareOnSquare.scss';

export default class SquareOnSquare extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false, isOverlayOpen: false, contentRect: {} };
  }
  openOverlay = () => {
    this.setState({ isOverlayOpen: true });
  };
  closeOverlay = () => {
    this.setState({ isOverlayOpen: false, hover: false });
  };
  toggleOverlay = () => {
    this.state.isOverlayOpen ? this.closeOverlay() : this.openOverlay();
  };
  getApplicationNode = () => {
    return document.getElementById('root');
  };
  render() {
    let gridArea = this.props.gridArea || '';

    let { borderColor, image, bgImage, youtubeId, copy, ctaCopy, customText, value, target, moduleName, eventAction} = this.props;
    let background = bgImage && bgImage.background ? bgImage.background.dataOriginal : '';

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: `${eventAction}`,
        event_category: "Page Content",
        event_label: `${eventAction}: ${moduleName} - ${ctaCopy}`,
      }
    }
    return (
      <button
        className={`square-on-square ${gridArea}`}
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={this.toggleOverlay}
        data-track-click={JSON.stringify(trackClickOptions.module)}
        data-count-grid-module
      >
        <Measure
          bounds
          offset
          scroll
          client
          margin
          onResize={contentRect => this.setState({ contentRect })}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <div
                className="square-on-square__background"
                style={{
                  backgroundImage: `url(${background})`,
                  backgroundPosition: `${bgImage.focus.x * 100}% ${bgImage.focus.y * 100}%`,
                  backgroundColor: borderColor
                }}
              >
                <div className="square-on-square__tap-target" />
                {youtubeId ? (
                  <ReactPlayer
                    className="square-on-square__mask"
                    width="80%"
                    height="auto"
                    url={youtubeId}
                    playing={this.props.width >= 769 && !this.state.isOverlayOpen && this.state.hover}
                    loop={true}
                    playsinline={true}
                    muted={true}
                    config={{
                      youtube: {
                        playerVars: { rel: 0, controls: 0 },
                        embedOptions: {
                          host: 'https://www.youtube-nocookie.com'
                        }
                      }
                    }}
                  />
                ) : (
                  <div
                    className="square-on-square__mask"
                    dangerouslySetInnerHTML={{ __html: image.asset }}
                  />
                )}
              </div>
            </div>
          )}
        </Measure>
        <div className="square-on-square__content">
          {copy ? (
            <div className="copy" dangerouslySetInnerHTML={{ __html: copy }}>
            </div>
          ) : (
            ''
          )}
          {customText && value ?  (
            <a href={value}
               className="cta-span link"
               target={target}
               onClick={e => e.stopPropagation()}
            >
              <span className="play-icon" />
              <span className="link__text">{customText}</span>
            </a>
          ) : ctaCopy ? (
            <span className="cta-span link">
              <span className="play-icon" />
              <span className="link__text">{ctaCopy}</span>
            </span>
          ) : (
            ''
          )}
        </div>

        {this.state.isOverlayOpen && (
          <AriaModal
            titleText="Nike SB Mobile Navigation"
            initialFocus="#close"
            getApplicationNode={this.getApplicationNode}
            underlayStyle={{ background: 'rgba(0, 0, 0, 0.8)' }}
            underlayClass=""
            dialogStyle={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              textAlign: 'unset',
              opacity: 1
            }}
            dialogClass="mobile-nav--dialog sos-video-dialog"
          >
            <VideoModal
              url={youtubeId}
              contentRect={this.state.contentRect}
            />
            <button className="btn--close icon" onClick={() => this.closeOverlay()}>
              <img
                id="close"
                src="/icons/nav/Video_CloseIcon.svg"
                alt="close navigation button"
              />
            </button>
          </AriaModal>
        )}
      </button>
    );
  }
}
