/* global google */
import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import mapStyles from "./MapStyles.json";
import "./GoogleMap.scss";
import SearchResults from "./SearchResults";
import { Link } from 'react-router-dom';

const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

let clusterStyles = [{
  fontFamily: "Roboto-Mono, HelveticaNeue, Arial, sans-serif",
  fontWeight: "normal",
  height: 55,
  textColor: "white",
  textSize: 16,
  url: "/icons/maps/cluster-icon.svg",
  width: 55
}];

const GMap = withScriptjs(withGoogleMap((props) => {
  let position = google.maps.ControlPosition.RIGHT_CENTER;

  if(typeof window !== "undefined") {
    if(document.getElementById("search-box") && document.getElementById("search-box").style.zIndex !== 1000002) {
      document.getElementById("search-box").style.zIndex = 1000002;
    }
  }

  return (
    <GoogleMap
      defaultZoom={props.zoom}
      defaultCenter={{ lat: 37.0902, lng: 95.7129 }}
      defaultOptions={{
        styles: mapStyles,
        zoomControlOptions: {
          position: position
        },
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        minZoom: 3
      }}
      center={ props.latlng }
      ref={props.reference}
      onZoomChanged={ props.onZoomChanged }
      onDragEnd={ props.onDragEnd }
      zoom={ props.zoom }
    >
      <SearchBox ref={props.searchRef} onPlacesChanged={props.updateLocation} activeMarkerCount={props.activeMarkerCount} controlPosition={google.maps.ControlPosition.TOP_LEFT}>
        <div id="search-box" className="search" style={{zIndex: 1000002}}>
          <input
            type="text"
            placeholder="Where are you?"
            id="search-input"
          />
          {props.activeSearch && (
            <div className="reset-btn" onClick={props.clearSearch}></div>
          )}
          <div className={`filters ${props.resultsOpen ? "open" : ""}`}>
            <div className="filters__trigger" onClick={props.toggleAll}>
              <div className={`filters__radio ${(!props.onlyProfiles && !props.onlyOL) ? 'checked' : ''}`} />
              All
              <span className="filters__count">({`${props.activeMarkerCount.all}`})</span>
            </div>
            <div className="filters__trigger" onClick={props.toggleProfiles}>
              <div className={`filters__radio ${props.onlyProfiles ? 'checked' : ''}`} />
              <button className="filters__tooltip">
                Profiles
                <div className={`filters__tooltip__content profile ${props.profileTooltipOpen ? 'open' : ''}`} onClick={props.handleTooltipBodyClick}>
                  <img
                    className="btn--close icon close-tooltip"
                    src="/icons/nav/CloseX-01.svg"
                    onClick={props.closeTooltip}
                    alt="close navigation button"
                  />
                  <p className="headline headline--italic">Skateshop Profile</p>
                  <p className="black">Learn more about skateshops near you and across the globe.</p>
                </div>
              </button>
              <span className="filters__count">({`${props.activeMarkerCount.profile}`})</span>
            </div>
            <div className="filters__trigger" onClick={props.toggleOL}>
              <div className={`filters__radio ${props.onlyOL ? 'checked' : ''}`} />
              <button className="filters__tooltip">
                Orange Label
                <div className={`filters__tooltip__content ol filters__tooltip__content--ol ${props.olTooltipOpen ? 'open' : ''}`} onClick={props.handleTooltipBodyClick}>
                  <img
                    className="btn--close icon close-tooltip"
                    src="/icons/nav/CloseX-01.svg"
                    onClick={props.closeTooltip}
                    alt="close navigation button"
                  />
                  <p className="headline headline--italic">Nike SB Orange Label</p>
                  <p className="black">Only Available In Skateshops. Our favorite styles. The best materials.</p>
                  <Link className="tooltip-link" to="/orangelabel" data-track-click={JSON.stringify({event_action: 'Orange Label CTA Click',event_category: "Page Content", event_label: "Orange Label CTA"})}>Learn More</Link>
                </div>
              </button>
              <span className="filters__count">({`${props.activeMarkerCount.ol}`})</span>
            </div>
          </div>
          <SearchResults resultsOpen={props.resultsOpen} toggleResults={props.toggleResults} activeShop={props.activeShop} markers={props.markers} zoom={props.zoom} backToResults={props.backToResults} goToShop={props.goToShop} />
        </div>
      </SearchBox>
      <Markers markers={props.allShops} goToShop={props.goToShop} onlyOL={props.onlyOL} onlyProfiles={props.onlyProfiles}/>
    </GoogleMap>
  );
}));

export class Markers extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.markers.length !== this.props.markers.length || nextProps.onlyOL !== this.props.onlyOL || nextProps.onlyProfiles !== this.props.onlyProfiles) ;
  }

  render() {
    let image = {
      url: "/icons/maps/Carrot-01.svg",
      scaledSize: new google.maps.Size(34, 47)
    };

    let ol_image = {
      url: "/icons/maps/Carrot-02.svg",
      scaledSize: new google.maps.Size(34, 47)
    };

    return (
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
        styles={ clusterStyles }
        ignoreHidden={true}
      >
        {this.props.markers && this.props.markers.map((marker, i) => {
          const key = 'Marker:' + i;
          const markerImage = marker["OL?"] ? ol_image : image;
          return (
            <Marker
              key={key}
              icon={markerImage}
              position={{ "lat": marker.Latitude, "lng": marker.Longitude }}
              onClick={ () => this.props.goToShop(marker) }
              visible={((this.props.onlyOL && marker["OL?"]) || (this.props.onlyProfiles && marker["Enabled"]) || (!this.props.onlyOL && !this.props.onlyProfiles)) ? true : false}>
            </Marker>
          );
        })}
      </MarkerClusterer>
    );
  }
}

export default class MapComponent extends Component {
  render() {
    return <GMap {...this.props} />
  }
}
