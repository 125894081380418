import React from 'react'

const NineToEleven = ({ data, links }) => {
  return (
    <React.Fragment>
      {
        (links.soccerTrophy.url !== "") && (
          <div key={data.soccerTrophy.id} className={`sandysplace__${data.soccerTrophy.id}`}>
            <a href={links.soccerTrophy.url} {...(links.soccerTrophy.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.soccerTrophy.image.src}
                alt={data.soccerTrophy.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.runnersBib.url !== "") && (
          <div key={data.runnersBib.id} className={`sandysplace__${data.runnersBib.id}`}>
            <a href={links.runnersBib.url} {...(links.runnersBib.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.runnersBib.image.src}
                alt={data.runnersBib.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.basketball.url !== "") && (
          <div key={data.basketball.id} className={`sandysplace__${data.basketball.id}`}>
            <a href={links.basketball.url} {...(links.basketball.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.basketball.image.src}
                alt={data.basketball.image.alt}
              />
            </a>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default NineToEleven
