import React from 'react';
import { hydrate } from 'react-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After } from '../after';
import './scss/Main.scss';
import Layout from './Layout';
import routes from './routes';
import { CookiesProvider } from 'react-cookie';

ensureReady(routes).then(data =>
  hydrate(
    <CookiesProvider>
      <React.Fragment>
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/khv0txu.css" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
        </Helmet>

        <BrowserRouter>
          <After data={data} routes={routes} Layout={Layout} />
        </BrowserRouter>
      </React.Fragment>
    </CookiesProvider>,
    document.getElementById('root')
  )
);

if (module.hot) {
  module.hot.accept();
}
