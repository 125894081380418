import React, { Component } from 'react';
import './ImageStandalone.scss';

export default class ImageStandalone extends Component {
  render() {
    let gridArea = this.props.gridArea || '';

    let {
      image,
      orientation = 'square',
      borderColor = '',
      instagramImage,
      index,
      bgImage
    } = this.props;

    if(!image) {
      return null;
    }

    let asset = image ? image.asset : instagramImage.asset;

    let shift = index % 2 === 0 ? 'left' : 'right';

    return (
      <React.Fragment>
        {bgImage && bgImage.background ? (
          <div
            className={`image-standalone ${orientation} ${gridArea} ${shift}`}
          >
            <div 
              className="image-standalone__bg"
              style={{
                backgroundImage: `url(${bgImage.background.dataOriginal})`,
                backgroundPosition: `${bgImage.focus.x * 100}% ${bgImage.focus.y * 100}%`
              }}
              dangerouslySetInnerHTML={{ __html: asset }}>
            </div>
          </div>
        ) : (
          <div
            className={`image-standalone ${orientation} ${
              borderColor ? 'border' : ''
            } ${gridArea} ${shift}`}
          >
            <div
              className={`image-standalone__${orientation} ${
                borderColor
                  ? `border-color--${borderColor} border-thickness--thick`
                  : ''
              }`}
              dangerouslySetInnerHTML={{ __html: asset }}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
