import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import './SandysPlace.scss';
import FiveToEight from './randomized/FiveToEight';
import NineToEleven from './links/NineToEleven';
import data from './items.json'
import { ArticleCard, ArticleTilt, MaskedHeadline, ShoeBorder, ShoeOverImage, LateralShoe } from '../../components/';
import OneToFour from './OneToFour';
import TwelveToFourteen from './TwelveToFourteen';
import FifteenToTwenty from './randomized/FifteenToTwenty';
import TwentyOneToThrirtyTwo from './links/TwentyOneToThrirtyTwo';
import ThirtyThreeToFortyOne from './links/ThirtyThreeToFortyOne';
import FortyTwoToFortyEight from './links/FortyTwoToFortyEight';

export default class SandysPlace extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      playing: false,
    }
  }

  componentDidMount() {
    document.body.classList.add("sandysplace-homepage");
  }

  componentWillUnmount() {
    document.body.classList.remove("sandysplace-homepage");
  }

  render() {
    let settings = {
      className: 'carousel-wrapper',
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    let { links, tvContent, posters, skateDecks, randomizedImageCounts } = this.props

    return (
      <div className="sandysplace">
        <div className="sandysplace__mobile-view">
          <div className="sandysplace__bg-image-mobile">
            <div className="sandysplace__phone-carousel-cntr">
              <div className="sandysplace__bg-color-mobile" />
              <img
                src="/SandysMobile_Phone.png"
                alt=""
              /> 
              <div className="sandysplace__mobile-carousel">
              <Slider {...settings}>
                <div key={data.tvItem.id} className={`sandysplace__${data.tvItem.id} ${ this.state.playing ? 'playing' : '' }`}>
                  <div className="sandysplace__youTube-wrapper">
                    <ReactPlayer
                      width={'100%'}
                      height={'100%'}
                      className="react-player"
                      url={tvContent.youtubeId}
                      playing={this.state.playing}
                      loop={true}
                      playsinline={false}
                      config={{
                        youtube: {
                          playerVars: { rel: 0, controls: 1, playsinline: 0 },
                          embedOptions: {
                            host: 'https://www.youtube-nocookie.com'
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                {
                  posters.map((poster, index) => (
                    <div className={`sandysplace__poster sandysplace__p${index + 1}-poster poster-${poster.cardType}`} key={index}>
                      {
                        (poster.cardType === "articleCardTall") ? (
                          <ArticleCard
                            headline={poster.card.headline}
                            copy={poster.card.copy}
                            date={poster.date}
                            textColor={poster.card.textColor}
                            highlightColor={poster.card.highlightColor}
                            image={poster.card.image}
                            ctaCopy={poster.card.ctaCopy}
                            uri={poster.uri}
                            moduleName="article-tall"
                          />
                        ) : (poster.cardType === "articleCardTilted") ? (
                          <ArticleTilt
                            tiltDirection={poster.type}
                            ctaCopy={poster.card.ctaCopy}
                            textColor={poster.card.textColor}
                            highlightColor={poster.card.highlightColor}
                            headline={poster.card.headline}
                            uri={poster.uri}
                            image={poster.card.image}
                            moduleName="article-tilted"
                          />
                        ) : (poster.cardType === "articleCardMasked") ? (
                          <MaskedHeadline
                            backgroundColor={poster.card.backgroundColor} 
                            headline={poster.card.headline} 
                            textColor={poster.card.textColor} 
                            image={poster.card.image} 
                            uri={poster.uri}
                            moduleName="article-masked"
                          />
                        ) : (poster.cardType === "shoeWCopy") ? (
                          <ShoeBorder
                            {...poster.props}
                            {...poster.card}
                            uri={poster.uri}
                            slug={poster.slug}
                            gridArea={poster.gridArea}
                            moduleName="shoe-with-copy"
                          />
                        ) : (poster.cardType === "shoeWImg") ? (
                          <ShoeOverImage
                            {...poster.props}
                            {...poster.card}
                            uri={poster.uri}
                            slug={poster.slug}
                            gridArea={poster.gridArea}
                            moduleName="shoe-with-image"
                          />
                        ) : (poster.cardType === "lateralShoe") ? (
                          <LateralShoe
                            title={poster.title}
                            {...poster.props}
                            {...poster.card}
                            uri={poster.uri}
                            slug={poster.slug}
                            gridArea={poster.gridArea}
                            moduleName="lateral-shoe"
                          />
                        ) : null
                      }
                    </div> 
                  ))
                }
              </Slider>
              </div>
            </div>
          </div>
        </div>
        
        <div className="sandysplace__tablet-view">
          <picture>
            <source media="(min-width: 1600px)" srcSet="/LivingRoom4K-BG_Ultrawide.jpg" sizes="100vw" />
            <source media="(min-width: 1201px)" srcSet="/LivingRoom4K-BG_Desktop.jpg" sizes="100vw" />
            <img src="/LivingRoom4K-BG_Tablet.jpg" alt="" />
          </picture>
          <OneToFour data={data} tvContent={tvContent} posters={posters} playing={this.state.playing} />
          <FiveToEight data={data} links={links} randomizedImageCounts={randomizedImageCounts} />
          <NineToEleven data={data} links={links} />
          <TwelveToFourteen skateDecks={skateDecks} />
          <FifteenToTwenty data={data} links={links} randomizedImageCounts={randomizedImageCounts} />
          <TwentyOneToThrirtyTwo data={data} links={links} /> 
          <ThirtyThreeToFortyOne data={data} links={links} />
          <FortyTwoToFortyEight data={data} links={links} />
        </div>

      </div>
    )
  }
}
