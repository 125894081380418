import React, { Component } from 'react';
import './ArchiveDetailEditorial.scss';

// expected props
// imageURL: string
// textColor: string
// text: string
// boxBorderColor: string
// imageCopyAlignment: string

export default class ArchiveDetailEditorial extends Component {
  render() {
    let { copy, textColor, borderColor, image, imageCopyAlignment } = this.props;
    const alignment = imageCopyAlignment ? imageCopyAlignment : '';

    const textStyles = {};
    if (textColor && textColor.startsWith('#')) {
      textStyles.color = textColor;
    }

    const cid = borderColor ? `cid-${borderColor.replace('#', '')}` : '';

    return (
      <div className={`archive-detail-editorial ${cid}`}>
        {borderColor && borderColor.startsWith('#') && (
          <style>{`
            .archive-detail-editorial.${cid} .archive-detail-editorial__copy-ctnr:after {
              border-style: solid;
              border-color: ${borderColor};
            }
          `}</style>
        )}
        <div className={`ctnr archive-detail-editorial__ctnr ${alignment}`}>
        { image && (<div className="archive-detail-editorial__image"
            dangerouslySetInnerHTML={{__html: image.asset}}>
        </div>)}
        <div className="archive-detail-editorial__content">
          <div className={`archive-detail-editorial__copy-ctnr border-color--${borderColor}`}>
            <div className={`copy copy--intro copy-url ${textColor}`} style={textStyles} dangerouslySetInnerHTML={{ __html: copy }} />
          </div>
        </div>
        </div>
      </div>
    );
  }
}
