import React from 'react'
import ReactPlayer from 'react-player';
import { ArticleCard, ArticleTilt, MaskedHeadline, ShoeBorder, ShoeOverImage, LateralShoe } from '../../components/';

const OneToFour = ({ data, tvContent, posters, playing }) => {
  return (
    <React.Fragment>
      <div key={data.tvItem.id} className={`sandysplace__${data.tvItem.id} ${ playing ? 'playing' : '' }`}>
        <div className="sandysplace__youTube-wrapper">
          <ReactPlayer
            width={'100%'}
            height={'100%'}
            className="react-player"
            url={tvContent.youtubeId}
            playing={playing}
            loop={true}
            playsinline={false}
            config={{
              youtube: {
                playerVars: { rel: 0, controls: 1, playsinline: 0 },
                embedOptions: {
                  host: 'https://www.youtube-nocookie.com'
                }
              }
            }}
          />
        </div>
      </div> 
      {
        posters.map((poster, index) => (
          <div className={`sandysplace__poster sandysplace__p${index + 1}-poster poster-${poster.cardType}`} key={index}>
            {
              (poster.cardType === "articleCardTall") ? (
                <ArticleCard
                  headline={poster.card.headline}
                  copy={poster.card.copy}
                  date={poster.date}
                  textColor={poster.card.textColor}
                  highlightColor={poster.card.highlightColor}
                  image={poster.card.image}
                  ctaCopy={poster.card.ctaCopy}
                  uri={poster.uri}
                  moduleName="article-tall"
                />
              ) : (poster.cardType === "articleCardTilted") ? (
                <ArticleTilt
                  tiltDirection={poster.type}
                  ctaCopy={poster.card.ctaCopy}
                  textColor={poster.card.textColor}
                  highlightColor={poster.card.highlightColor}
                  headline={poster.card.headline}
                  uri={poster.uri}
                  image={poster.card.image}
                  moduleName="article-tilted"
                />
              ) : (poster.cardType === "articleCardMasked") ? (
                <MaskedHeadline
                  backgroundColor={poster.card.backgroundColor} 
                  headline={poster.card.headline} 
                  textColor={poster.card.textColor} 
                  image={poster.card.image} 
                  uri={poster.uri}
                  moduleName="article-masked"
                />
              ) : (poster.cardType === "shoeWCopy") ? (
                <ShoeBorder
                  {...poster.props}
                  {...poster.card}
                  uri={poster.uri}
                  slug={poster.slug}
                  gridArea={poster.gridArea}
                  moduleName="shoe-with-copy"
                />
              ) : (poster.cardType === "shoeWImg") ? (
                <ShoeOverImage
                  {...poster.props}
                  {...poster.card}
                  uri={poster.uri}
                  slug={poster.slug}
                  gridArea={poster.gridArea}
                  moduleName="shoe-with-image"
                />
              ) : (poster.cardType === "lateralShoe") ? (
                <LateralShoe
                  title={poster.title}
                  {...poster.props}
                  {...poster.card}
                  uri={poster.uri}
                  slug={poster.slug}
                  gridArea={poster.gridArea}
                  moduleName="lateral-shoe"
                />
              ) : null
            }
          </div> 
        ))
      }
    </React.Fragment>
  )
}

export default OneToFour
