import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './ArchiveDetailVideoCaption.scss';

// expected props:
// videoURL: string
// captionText: string

export default class ArchiveDetailVideoCaption extends Component {
  constructor(props) {
    super(props);
    this.state = { playing: false };
  }
  render() {
    let { copy, videoPlaceholder, youtubeId, textColor } = this.props;

    // let videoURL = '/Videos/Wallride_Nose_Manuel.mov';

    const textStyle = {};
    if (textColor && textColor.startsWith('#')) {
      textStyle.color = textColor;
    }

    return (
      <div className="ctnr">
        <div
          className={`archive-detail-video-caption ${
            this.state.playing ? 'playing' : ''
          }`}
          onClick={() => {
            this.setState({ playing: true });
          }}
        >
          <div
            className={`archive-detail-video-caption__video ${
              !videoPlaceholder ? 'no-placeholder' : ''
            }`}
          >
            {videoPlaceholder && (
              <React.Fragment>
                <img
                  src="/icons/misc/VideoPlay.svg"
                  className="play-btn"
                  alt="Play video"
                />
                <div
                  dangerouslySetInnerHTML={{ __html: videoPlaceholder.asset }}
                />
              </React.Fragment>
            )}
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              className="react-player"
              url={youtubeId}
              playing={this.state.playing}
              loop={true}
              playsinline={false}
              config={{
                youtube: {
                  playerVars: { rel: 0, controls: 1, playsinline: 0 },
                  embedOptions: {
                    host: 'https://www.youtube-nocookie.com'
                  }
                }
              }}
            />
          </div>
          <div className={`archive-detail-video-caption__content ${textColor}`} style={textStyle}>
            <div className="date copy-url" dangerouslySetInnerHTML={{ __html: copy }} />
          </div>
        </div>
      </div>
    );
  }
}
