import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './VideoWCopy.scss';

export default class VideoWCopy extends Component {
  constructor(props) {
    super(props);
    this.state = { playing: false,
                   modalOpen: false };
  }
  changeModalClosed = (e) => {
    console.log(e);
    e.stopPropagation();
    this.setState({modalOpen: false});
  }
  changeModalOpen = (e) => {
    console.log(e);
    e.stopPropagation();
    this.setState({modalOpen: true});
  }

  render() {
    let {
      videoModuleStyle,
      backgroundColor,
      textColor,
      headline,
      copy,
      youtubeId,
      videoPlaceholder,
      imageCopyAlignment,
      ctaCopy,
      ctaLink,
      ctaTarget,
    } = this.props;
    const align = videoModuleStyle === 'sideBySide' && imageCopyAlignment ? imageCopyAlignment : '';
    const fontSize = videoPlaceholder && videoPlaceholder.caption && videoPlaceholder.caption.length > 60 ? 'fs-small' : 'fs-large';
    let modalClass = this.state.modalOpen ? 'open' : '';

    let module = 'body';
    let headlineStyle = 'headline headline--italic';
    if (videoModuleStyle === 'headlineOverVideo') {
      module = 'headline';
      headlineStyle = 'headline--alt headline--alt-md';
    }
    const isBlank = ctaTarget ? '_blank' : '';

    const textColorStyle = {};
    const backgroundColorStyle = {};
    const backgroundGradientStyle = {};

    if (textColor && textColor.startsWith('#')) {
      textColorStyle.color = textColor;
    }
    if (backgroundColor && backgroundColor.startsWith('#')) {
      if (module === 'headline') {
        backgroundGradientStyle.background = `linear-gradient(to bottom, ${backgroundColor}, ${backgroundColor} 70%, transparent 70%)`;
      }
      backgroundColorStyle.backgroundColor = backgroundColor;
    }

    return (
      <div
        className={`video-${module} video-${module}--${backgroundColor}`}
        style={module === 'headline' ? backgroundGradientStyle : backgroundColorStyle}
        onClick={() => {
          this.setState({ playing: true });
        }}
      >
        <div className={`ctnr video-${module}__ctnr ${align}`}>
          <div className={`video-${module}__text ${textColor}`} style={textColorStyle}>
            <h2 className={`${headlineStyle} video-${module}__headline`}>
              {headline}
            </h2>
            <div className={`copy video-${module}__copy copy-url`} dangerouslySetInnerHTML={{ __html: copy }} />
            { ctaCopy &&
              <a href={`${ctaLink}`} className={`btn btn--${textColor} btn--bg-${backgroundColor}`} target={`${isBlank}`} style={{...textColorStyle, ...backgroundColorStyle}}>
                <span class={`arrow bg--${textColor}`} style={textColorStyle}></span>
                {ctaCopy}
              </a>
            }
          </div>
          <div
            className={`video-${module}__video ${
              !videoPlaceholder ? 'no-placeholder' : ''
            } ${this.state.playing ? 'playing' : ''}`}
            >
            {videoPlaceholder && (
              <React.Fragment>
                <img
                  src="/icons/misc/VideoPlay.svg"
                  className="play-btn"
                  alt="Play video"
                />
                {videoPlaceholder && videoPlaceholder.caption &&
                  <div class="info">
                    <img onClick={this.changeModalOpen} src="/icons/misc/NikeSB_Caption-Icon_M.png" alt="information" />
                  </div>}
                {videoPlaceholder && videoPlaceholder.caption &&
                  <div className={`group ${modalClass} ${textColor}`} style={textColorStyle}>
                    <img onClick={this.changeModalClosed} src="/icons/misc/NikeSB_CaptionClose-Icon_M.png" alt="close" />
                    <p className={`caption ${fontSize}`}>{videoPlaceholder.caption}</p>
                  </div>}
                <div
                  className="placeholder"
                  dangerouslySetInnerHTML={{ __html: videoPlaceholder.asset }}
                />
              </React.Fragment>
            )}
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              url={`${youtubeId}`}
              playing={this.state.playing}
              playsinline={false}
              className={'react-player'}
              tabIndex={1}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  this.setState({ playing: true });
                }
              }}
              config={{
                youtube: {
                  playerVars: { rel: 0, controls: 1, playsinline: 0 },
                  embedOptions: {
                    host: 'https://www.youtube-nocookie.com'
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
