import React, { Component } from 'react';
import './QuoteSidebar.scss';

export default class QuoteSidebar extends Component {
  render() {
    // let { copy, textColor, borderColor, alignment } = this.props;
    // let textColor = 'red';
    // let borderColor = 'green';
    let alignment = 'top';
    let gridArea = this.props.gridArea || '';

    let { textColor, borderColor, copy } = this.props;

    const colorStyles = {};
    if (textColor && textColor.startsWith('#')) {
      colorStyles.color = textColor;
    }
    if (borderColor && borderColor.startsWith('#')) {
      colorStyles.borderLeft = `30px solid ${borderColor}`;
    }

    // let copy = `Stefan's latest stays rough around the edges with an inside-out seam and hairy suede.`;

    return (
      <div className={`quote-sidebar ctnr`}>
        <div
          className={`quote-sidebar__ctnt quote-sidebar__ctnt--${alignment}`}
        >
          <div
            className={`quote-sidebar__headline headline headline--lg headline--quote ${gridArea} headline--quote-${borderColor} ${textColor}`}
            style={colorStyles}
            dangerouslySetInnerHTML={{ __html: copy }}>
          </div>
        </div>
      </div>
    );
  }
}
