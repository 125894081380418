import React, { Component } from 'react';

export default class AthleteAmIcon extends Component {
  render() {
    return (
    <svg viewBox="0 0 94 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33 17C33 25.8364 25.8367 33 16.9998 33C8.16332 33 1 25.8364 1 17C1 8.16361 8.16332 1 16.9998 1C25.8367 1 33 8.16361 33 17Z" fill="white"/>
    <path d="M33 17C33 25.8364 25.8367 33 16.9998 33C8.16332 33 1 25.8364 1 17C1 8.16361 8.16332 1 16.9998 1C25.8367 1 33 8.16361 33 17Z" fill="#FFF617" stroke="#0064D3" stroke-width="2"/>
    <path d="M21.317 24.9757C23.1822 25.7538 26.1047 26.645 27.3273 24.7093C28.0115 23.6442 27.5764 21.9642 26.5718 20.439C25.9395 19.4656 25.1098 18.5748 24.2911 17.6836C24.7478 18.4108 26.0323 20.8587 24.3427 22.0673C23.6901 22.5486 22.5399 22.5894 21.1095 22.2206L4 17.7551L21.317 24.9757Z" fill="#0064D3"/>
    <path d="M21.0193 9.87102C21.0193 12.0068 20.4762 13.7385 19.806 13.7385C19.1362 13.7385 18.5928 12.0068 18.5928 9.87102C18.5928 7.73526 19.1362 6.00391 19.806 6.00391C20.4762 6.00391 21.0193 7.73526 21.0193 9.87102Z" fill="#0064D3"/>
    <path d="M14.1941 9.87102C14.1941 12.0068 13.651 13.7385 12.9808 13.7385C12.311 13.7385 11.7676 12.0068 11.7676 9.87102C11.7676 7.73526 12.311 6.00391 12.9808 6.00391C13.651 6.00391 14.1941 7.73526 14.1941 9.87102Z" fill="#0064D3"/>
    <path d="M47.25 19.825L46.155 22H41.91L48.195 10.225H53.04L58.98 22H54.645L53.61 19.825H47.25ZM52.335 17.185L50.925 14.335L50.505 13.27L50.055 14.35L48.57 17.185H52.335ZM76.7089 22H72.8989V15.64L70.1089 22H66.6889L63.9289 15.64V22H60.1039V10.225H65.3539L68.4139 17.29L71.6239 10.225H76.7089V22ZM92.9051 18.34C92.9051 20.83 90.4751 22.18 85.6901 22.18C81.2351 22.18 79.3151 20.92 78.5801 19.27C78.4151 18.88 78.3401 18.4 78.2951 18.1H82.6151C82.6451 18.28 82.7501 18.55 82.9001 18.73C83.2601 19.165 84.2651 19.42 85.9751 19.42C88.1801 19.42 88.7051 19.105 88.7051 18.52C88.7051 17.89 88.1651 17.755 86.1701 17.545C85.3751 17.47 84.1151 17.35 83.5901 17.305C79.7801 16.96 78.3701 15.775 78.3701 13.75C78.3701 11.65 80.8601 10.045 85.4051 10.045C89.4401 10.045 91.3151 11.245 92.0951 12.745C92.3351 13.195 92.4851 13.705 92.5301 14.065H88.2101C88.1951 13.9 88.1351 13.735 87.9701 13.525C87.6401 13.09 86.8001 12.805 85.3001 12.805C83.1851 12.805 82.6301 13.06 82.6301 13.6C82.6301 14.2 83.3351 14.335 85.6901 14.59C86.6051 14.665 88.0601 14.815 88.5551 14.875C91.6001 15.205 92.9051 16.285 92.9051 18.34Z" fill="#0064D3"/>
    </svg>
    );
  }
}