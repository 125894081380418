import React, { Component } from 'react';
import './LateralShoe.scss';
import { Link } from 'react-router-dom';

// expected props:
// shoeImageURL: string
// shoeDirection: string ('right' || 'left')
// linkURL: string

export default class LateralShoe extends Component {
  render() {
    let { productImage, ctaCopy, pageAlignment, uri, title, headline, eventAction } = this.props;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: `${eventAction}`,
        event_category: "Page Content",
        event_label: `${eventAction}: ${headline} - ${ctaCopy}`,
      }
    }

    return (
      <Link
        to={`/${uri}`} className="parent"
        data-count-grid-module
        data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <div className={`lateral-shoe lateral-shoe--${pageAlignment}`}>
          <div
            className="lateral-shoe__shoe"
            dangerouslySetInnerHTML={{ __html: productImage.asset }}
          />
          <span
            className="cta-span link"
          >
            <span className="arrow" />
            <span className="link__text">{ctaCopy ? ctaCopy : title}</span>
          </span>
        </div>
      </Link>
    );
  }
}
