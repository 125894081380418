import React from 'react'
import "./TwentyOneToThrirtyTwo.scss"

const TwentyOneToThrirtyTwo = ({ data, links }) => {
  return (
    <React.Fragment>
      {
        (links.plaque.url !== "") && (
          <div key={data.plaque.id} className={`sandysplace__${data.plaque.id}`}>
            <a href={links.plaque.url} {...(links.plaque.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.plaque.image.src}
                alt={data.plaque.image.alt}
              /> 
            </a>
          </div>
        )
      }
      {
        (links.trophyHand.url !== "") && (
          <div key={data.trophyHand.id} className={`sandysplace__${data.trophyHand.id}`}>
            <a href={links.trophyHand.url} {...(links.trophyHand.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.trophyHand.image.src}
                alt={data.trophyHand.image.alt}
              /> 
            </a>
          </div>
        )
      }
      {
        (links.trophyTruck.url !== "") && (
          <div key={data.trophyTruck.id} className={`sandysplace__${data.trophyTruck.id}`}>
            <a href={links.trophyTruck.url} {...(links.trophyTruck.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.trophyTruck.image.src}
                alt={data.trophyTruck.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.trophyEyeball.url !== "") && (
          <div key={data.trophyEyeball.id} className={`sandysplace__${data.trophyEyeball.id}`}>
            <a href={links.trophyEyeball.url} {...(links.trophyEyeball.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.trophyEyeball.image.src}
                alt={data.trophyEyeball.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.trophySkateboard.url !== "") && (
          <div key={data.trophySkateboard.id} className={`sandysplace__${data.trophySkateboard.id}`}>
            <a href={links.trophySkateboard.url} {...(links.trophySkateboard.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.trophySkateboard.image.src}
                alt={data.trophySkateboard.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.cans.url !== "") && (
          <div key={data.cans.id} className={`sandysplace__${data.cans.id}`}>
            <a href={links.cans.url} {...(links.cans.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.cans.image.src}
                alt={data.cans.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.vhsTapeShelf.url !== "") && (
          <div key={data.vhsTapeFour.id} className={`sandysplace__${data.vhsTapeFour.id}`}>
            <a href={links.vhsTapeShelf.url} {...(links.vhsTapeShelf.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.vhsTapeFour.image.src}
                alt={data.vhsTapeFour.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.paperStack.url !== "") && (
          <div key={data.paperStack.id} className={`sandysplace__${data.paperStack.id}`}>
            <a href={links.paperStack.url} {...(links.paperStack.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.paperStack.image.src}
                alt={data.paperStack.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.ticket.url !== "") && (
          <div key={data.ticket.id} className={`sandysplace__${data.ticket.id}`}>
            <a href={links.ticket.url} {...(links.ticket.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.ticket.image.src}
                alt={data.ticket.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.trucks.url !== "") && (
          <div key={data.trucks.id} className={`sandysplace__${data.trucks.id}`}>
            <a href={links.trucks.url} {...(links.trucks.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.trucks.image.src}
                alt={data.trucks.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.nikeBadge.url !== "") && (
          <div key={data.nikeBadge.id} className={`sandysplace__${data.nikeBadge.id}`}>
            <a href={links.nikeBadge.url} {...(links.nikeBadge.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.nikeBadge.image.src}
                alt={data.nikeBadge.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.lobster.url !== "") && (
          <div key={data.lobster.id} className={`sandysplace__${data.lobster.id}`}>
            <a href={links.lobster.url} {...(links.lobster.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.lobster.image.src}
                alt={data.lobster.image.alt}
              />
            </a>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default TwentyOneToThrirtyTwo
