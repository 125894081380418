import React, { Component } from 'react';
import "./Footer.scss";
import { Link } from 'react-router-dom';
import { withCookies, useCookies } from 'react-cookie';
import { CookieBanner, CookieSettings, CookieModal } from  "../../components";
import translations from "./Translations.json";

const bannerCountries = ['GB', 'SE', 'AT', 'BE', 'BG', 'HR',
                         'CZ', 'DK', 'FI', 'FR', 'DE', 'GR',
                         'HU', 'IE', 'IT', 'LU', 'NL', 'PL',
                         'PT', 'RO', 'SK', 'SI', 'ES', 'CH',
                         'NO'];

function PrivacyLink(props) {
  const [cookies] = useCookies(['geoloc']);

  let countryCode = "US";
  let geoloc = cookies.geoloc;

  if(geoloc) {
    let geoArr = geoloc.split(",");
    countryCode = geoArr[0].split("=");
    countryCode = countryCode[1];
  }

  let base = "";
  if(typeof window !== "undefined") {
    base = window.location.host === "www.nikesb.com" ? "https://agreementservice.svs.nike.com" : "https://agreement.test.svs.nike.com";
  } else {
    base = process.env.BASE_URL === "https://www.nikesb.com" ? "https://agreementservice.svs.nike.com" : "https://agreement.test.svs.nike.com";
  }

  let url = `${base}/rest/agreement?agreementType=${props.type}&uxId=com.nike.nikesb&country=${countryCode}&requestType=styled`;

  return <a 
    className="light-gray footer__privacy" 
    target="_blank" 
    rel="noopener noreferrer" 
    href={url}
    // data-track-click={JSON.stringify({
    //   content_click:     'open ' + props.title,
    //   content_identifer: 'footer_link-' + props.type,
    //   target_page:       url
    // })}
    >
      {props.title}
  </a>;
}

class Footer extends Component {
  constructor(props) {
    super(props);
    const { cookies } = this.props;

    let countryCode = null;
    let geoloc = cookies.get("geoloc");
    if(geoloc) {
      let geoArr = geoloc.split(",");
      countryCode = geoArr[0].split("=");
      countryCode = countryCode[1];
    }

    this.state = {
      settingsOpen: false,
      countryCode: countryCode,
      bannerOpen: false,
      modalOpen: false
    };
  }

  componentDidMount() {
    const { cookies } = this.props;

    if(cookies.get('sq') === undefined && bannerCountries.includes(this.state.countryCode)) {
      setTimeout(() => {
        this.setState({bannerOpen: true});
      }, 1000);
      this.setState({modalOpen: true});
    }
  }

  toggleSettings = () => {
    this.setState({
      settingsOpen: !this.state.settingsOpen,
      bannerOpen: false,
      modalOpen: false
    });
  }

  _handleOk = () => {
    const { cookies } = this.props;

    document.body.classList.remove("modal-open");
    cookies.set('sq', 3, { path: '/', maxAge: 31536000 });
    this.closeBanner();
  }

  closeBanner = () => {
    this.setState({
      bannerOpen: false,
      modalOpen: false
    });
  }

  applySettings = (performance, social) => {
    const { cookies } = this.props;
    let newSq = (performance ? 1 : 0) + (social ? 2 : 0);

    document.body.classList.remove("modal-open");
    cookies.set('sq', newSq, { path: '/', maxAge: 31536000 });
    this.setState({settingsOpen: false, bannerOpen: false});
  }

  render() {
    let style = this.props.margin === "none" ? {marginTop: 0} : {};
    return (
      <React.Fragment>
        <div style={style} className="footer">
          <div className="footer__left">
            <Link
              to="/"
              // data-track-click={JSON.stringify({
              //   content_click:     'navigate',
              //   content_identifer: 'footer_logo',
              //   target_page:       '/'
              // })}
            >
              <img
                alt=""
                className="footer__logo"
                src="/icons/nav/logo_nikesb_White.svg"
              />
            </Link>
            <div className="footer__left-privacy">
              <PrivacyLink
                type="termsOfUse"
                title={`${bannerCountries.includes(this.state.countryCode) ? translations[this.state.countryCode]["termsLink"] : 'Terms'}`}
              />
              <PrivacyLink
                type="privacyPolicy"
                title={`${bannerCountries.includes(this.state.countryCode) ? translations[this.state.countryCode]["privacyLink"] : 'Privacy'}`}
              />
              {bannerCountries.includes(this.state.countryCode) && (
                <button className="light-gray footer__privacy" onClick={() => {this.setState({settingsOpen: true})}}>
                  {translations[this.state.countryCode]["cookieSettings"]}
                </button>
              )}
            </div>
          </div>
          <div className="footer__social">
            <a
              href="https://www.facebook.com/NikeSkateboarding/"
              target="blank"
              // data-track-click={JSON.stringify({
              //   content_click:     'navigate to social channel',
              //   content_identifer: 'footer_social-link',
              //   social_channel:    'Facebook'
              // })}
            >
              <img
                alt=""
                className="footer__social-icon"
                src="/icons/social/Social_Facebook_White.svg"
              />
            </a>
            <a
              href="https://twitter.com/nikesb"
              target="blank"
              // data-track-click={JSON.stringify({
              //   content_click:     'navigate to social channel',
              //   content_identifer: 'footer_social-link',
              //   social_channel:    'Twitter'
              // })}
            >
              <img
                alt=""
                className="footer__social-icon"
                src="/icons/social/Social_Twitter_White.svg"
              />
            </a>
            <a
              href="https://instagram.com/nikesb"
              target="blank"
              // data-track-click={JSON.stringify({
              //   content_click:     'navigate to social channel',
              //   content_identifer: 'footer_social-link',
              //   social_channel:    'Instagram'
              // })}
            >
              <img
                alt=""
                className="footer__social-icon"
                src="/icons/social/Social_Instagram_White.svg"
              />
            </a>
          </div>
        </div>

        {bannerCountries.includes(this.state.countryCode) && (
          <React.Fragment>
            <CookieSettings copy={translations[this.state.countryCode]} toggleSettings={this.toggleSettings} isOpen={this.state.settingsOpen} applySettings={this.applySettings} countryCode={this.state.countryCode} sq={this.props.cookies.get('sq')} />
            {this.state.countryCode !== "NL" && <CookieBanner copy={translations[this.state.countryCode]} handleOk={this._handleOk} countryCode={this.state.countryCode} closeBanner={this.closeBanner} toggleSettings={this.toggleSettings} bannerOpen={this.state.bannerOpen} />}
            {this.state.countryCode === "NL" && <CookieModal copy={translations[this.state.countryCode]} handleOk={this._handleOk} countryCode={this.state.countryCode} closeBanner={this.closeBanner} toggleSettings={this.toggleSettings} modalOpen={this.state.modalOpen} />}
          </React.Fragment>
        )}

      </React.Fragment>
    );
  }
}

export default withCookies(Footer);
