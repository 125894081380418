import React, { Component } from 'react';
import './NavBar.scss';
import NavMobile from './NavMobile';
import { Link } from 'react-router-dom';
import { IconHamburger } from '../../icons';
import axios from 'axios';

export default class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastScrollPos: 0,
      logoWidthClass: 'large',
      modalOpen: false,
      additionalNavItems: null
    };
  }
  componentDidMount() {
    let that = this;
    window.addEventListener('scroll', this.onScroll);

    let cmsUrl = process.env.CMS_URL ? process.env.CMS_URL : '';

    axios.get(`${cmsUrl}/api/nav.json`).then(res => {
      that.setState({additionalNavItems: res.data['additionalNavItems']});
    });
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }
  onScroll = () => {
    if (this.state.lastScrollPos < window.pageYOffset) {
      this.setState({ logoWidthClass: 'small' });
    }
    else if (window.pageYOffset === 0) {
      this.setState({ logoWidthClass: 'large' });
    }
  };
  openModal = () => {
    this.setState({ modalOpen: true });
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };
  getApplicationNode = () => {
    return document.getElementById('root');
  };
  render() {
    let path = this.props.match;
    let style = this.props.style;
    let { logoWidthClass, additionalNavItems } = this.state;

    let classes = (path === '/shops' ? 'nav nav--small nav--static' : `nav nav--${logoWidthClass}`);
    let logoClasses = (path === '/shops' ? 'nav__logo nav__logo--small' : `nav__logo nav__logo--${logoWidthClass}`);

    return (
      <React.Fragment>
        {path !== '/shops' && (
          <div className="nav-placeholder" />
        )}
        <nav
          className={classes}
          style={{ ...style, width: '100%' }}
        >
          <div className="nav__ctnr ctnr">
            <div className="nav__left">
              <Link
                to="/"
                className="nav__logo__parent"
                // data-track-click={JSON.stringify({
                //   vars: {
                //     content_click:     'navigate',
                //     content_identifer: 'nav_logo',
                //     target_page:       '/'
                //   }
                // })}
              >
                <img
                  alt=""
                  className={logoClasses}
                  src="/icons/nav/logo_nikesb_Black.svg"
                />
              </Link>

              <ul className="nav__list">
                <li className="nav__list-item">
                  <Link
                    className="nav__highlight"
                    to="/"
                    // data-track-click={JSON.stringify({
                    //   vars: {
                    //     content_click:     'navigate',
                    //     content_identifer: 'nav_link-feed',
                    //     target_page:       '/',
                    //     text:              'Feed'
                    //   }
                    // })}
                  >
                    <span className={`${path === '/' ? 'active' : ''}`}>
                      Feed
                    </span>
                  </Link>
                </li>
                <li className="nav__list-item">
                  <Link
                    className="nav__highlight"
                    to="/team"
                    // data-track-click={JSON.stringify({
                    //   vars: {
                    //     content_click:     'navigate',
                    //     content_identifer: 'nav_link-team',
                    //     target_page:       '/team',
                    //     text:              'Team'
                    //   }
                    // })}
                  >
                    <span
                      className={`${
                        path && path.startsWith('/team') && (!additionalNavItems || !additionalNavItems.find((navLink) => { return navLink.value === path; })) ? 'active' : ''
                      }`}
                    >
                      Team
                    </span>
                  </Link>
                </li>
                <li className="nav__list-item">
                  <Link
                    className="nav__highlight"
                    to="/the-vault"
                    // data-track-click={JSON.stringify({
                    //   vars: {
                    //     content_click:     'navigate',
                    //     content_identifer: 'nav_link-the-vault',
                    //     target_page:       '/the-vault',
                    //     text:              'The Vault'
                    //   }
                    // })}
                  >
                    <span
                      className={`${
                        path && path.startsWith('/the-vault') && (!additionalNavItems || !additionalNavItems.find((navLink) => { return navLink.value === path; })) ? 'active' : ''
                      }`}
                    >
                      The Vault
                    </span>
                  </Link>
                </li>
                <li className="nav__list-item">
                  <Link
                    className="nav__highlight"
                    to="/shops"
                    // data-track-click={JSON.stringify({
                    //   vars: {
                    //     content_click:     'navigate',
                    //     content_identifer: 'nav_link-skateshops',
                    //     target_page:       '/shops',
                    //     text:              'Skateshops'
                    //   }
                    // })}
                  >
                    <span
                      className={`${
                        path && path.startsWith('/shops') && (!additionalNavItems || !additionalNavItems.find((navLink) => { return navLink.value === path; })) ? 'active' : ''
                      }`}
                    >
                      Skateshops
                    </span>
                  </Link>
                </li>

                {additionalNavItems && additionalNavItems.map((navLink, i) => {
                  if(navLink && navLink.customText && navLink.value) {
                    return(
                      <li className="nav__list-item" key={`additional-nav-item${i}`}>
                        <Link
                          className="nav__highlight"
                          to={navLink.value}
                        >
                            <span
                              className={`${
                                path && path === navLink.value ? 'active' : ''
                              }`}
                            >
                              {navLink.customText}
                            </span>
                        </Link>

                      </li>
                    );
                  }

                  return false;
                })}
              </ul>
            </div>
            <div className="nav__shop">
              <a
                href="https://www.nike.com/w/skateboarding-8mfrf"
                className="nav__highlight"
                target="blank"
                data-track-click={JSON.stringify({
                  event_action: "Shop from nav",
                  event_category:     'Shop Nike.com',
                  event_label:        'Shop from nav: Top Nav'
                })}
              >
                <span className="arrow" />
                Shop Nike SB
              </a>
            </div>
            <button
              className={`nav__burger-icon nav__burger-icon--${logoWidthClass}`}
              onClick={this.openModal}
            >
              <IconHamburger />
            </button>
            {/* <img
              className={`nav__burger-icon nav__burger-icon--${logoWidthClass}`}
              src="/icons/nav/Icon_Hamburger_Nav.svg"
              alt=""
              onClick={this.openModal}
            /> */}
          </div>

          <div style={{ width: 0, height: 0, position: 'absolute' }}>
            {this.state.modalOpen ? (
              <NavMobile
                getApplicationNode={this.getApplicationNode}
                closeModal={this.closeModal}
                additionalNavItems={additionalNavItems}
              />
            ) : (
              false
            )}
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
