import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './VideoModal.scss';

export default class VideoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: true,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false
    };
  }
  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };
  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) });
  };
  toggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };
  onPlay = () => {
    // console.log('onPlay');
    this.setState({ playing: true });
  };
  onPause = () => {
    // console.log('onPause');
    this.setState({ playing: false });
  };
  onSeekMouseDown = e => {
    this.setState({ seeking: true });
  };
  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) });
  };
  onSeekMouseUp = e => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };
  onProgress = state => {
    // console.log('onProgress', state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };
  onEnded = () => {
    // console.log('onEnded');
    this.setState({ playing: this.state.loop });
  };
  onDuration = duration => {
    // console.log('onDuration', duration);
    this.setState({ duration });
  };
  ref = player => {
    this.player = player;
  };
  render() {
    const {
      playing,
      // controls,
      volume,
      muted,
      loop
      // played,
      // loaded,
      // duration,
      // playbackRate,
      // pip
    } = this.state;
    // const SEPARATOR = ' · ';

    // let { title, url, contentRect } = this.props;
    let { url } = this.props;

    return (
      <ReactPlayer
        ref={this.ref}
        className="react-player video-overlay"
        width="100%"
        height="80%"
        url={url}
        config={{
          youtube: {
            playerVars: { rel: 0, controls: 1, playsinline: 0 },
            embedOptions: {
              host: 'https://www.youtube-nocookie.com'
            }
          }
        }}
        playing={playing}
        playsinline={false}
        loop={loop}
        volume={volume}
        muted={muted}
        onPlay={this.onPlay}
        onPause={this.onPause}
        onBuffer={() => {
          /*console.log('onBuffer')*/
        }}
        onSeek={e => {
          /* console.log('onSeek', e)*/
        }}
        onEnded={this.onEnded}
        onError={e => {
          /*console.log('onError', e)*/
        }}
        onProgress={this.onProgress}
        onDuration={this.onDuration}
      />
    );
  }
}
