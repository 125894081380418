import React, { Component } from 'react';
import './ArchiveWallProducts.scss';
import { Link } from 'react-router-dom';

// expected props
// products: <Product>[];

export default class ArchiveWallProducts extends Component {
  render() {
    let { shoes } = this.props;
    return (
      <div className="archive-wall-products">
        { shoes.map((component, i) => {
          const key = 'Box:' + i;
          let orientation = i % 2 ? 'right' : 'left';
          return <Product key={key} {...{ ...component, orientation: orientation }} />;
        })}
      </div>
    );
  }
}

// expected props
// shoeImage: object;
// textColor: string;
// hightlightColor: string;
// releaseYear: string;
// shoeName: string;
// headline: string;
// slug: string;
// orientation: string;

class Product extends Component {
  render() {
    let { headline, shoeName, textColor, highlightColor, releaseYear, shoeImage, slug, orientation } = this.props;
    let linkURL = `/${slug}`;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: "Vault content click",
        event_category: "Page Content",
        event_label: `Vault content click: ${headline} - ${shoeName}`,
      }
    }
    return (
      <div
        className={`ctnr archive-wall-product archive-wall-product--${orientation}`}
      >
        <div className={`square square-a bg--${textColor}`} />

        <Link to={linkURL}
              className="archive-wall-product__shoe-image"
              data-track-click={JSON.stringify(trackClickOptions.module)}
             dangerouslySetInnerHTML={{__html: shoeImage}}>
        </Link>

        <div className={`archive-wall-product__content ${highlightColor}`}>
          <p className="date">{releaseYear}</p>
          <p className="archive-wall-product__eyebrow date">{shoeName}</p>
          <div>
            <h1
              className={`archive-wall-product__headline headline--alt headline--alt-lg headline--highlight headline--highlight-${textColor}`}
            >
              {headline}
            </h1>
          </div>
          <div className="archive-wall-product__cta">
            <Link to={linkURL}
                  className={`btn btn--${highlightColor} btn--bg-white`}
                  data-track-click={JSON.stringify(trackClickOptions.module)}>
              <span className="arrow" />Learn More
            </Link>
          </div>
        </div>

        <div className={`square square-b bg--${textColor}`} />
      </div>
    );
  }
}
