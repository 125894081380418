import React, { Component } from 'react';
import './ArticleEditorialWBackground.scss';

// expected props:
// image: object
// backgroundColor: string
// textColor: string
// copy: string
// imageCopyAlignment: string

export default class QuoteSidebar extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false
    }
  }
  changeModalClosed() {
    this.setState({modalOpen: false});
  }
  changeModalOpen() {
    this.setState({modalOpen: true});
  }
  render() {
    let { image, backgroundColor, textColor, copy, imageCopyAlignment } = this.props;
    const alignment = imageCopyAlignment ? imageCopyAlignment : '';
    const fontSize = image.caption && image.caption.length > 60 ? 'fs-small' : 'fs-large';
    let modalClass = this.state.modalOpen ? 'article-ed-w-bg__open' : '';

    const backgroundStyle = {};
    const textColorStyle = {};

    if (backgroundColor && backgroundColor.startsWith('#')) {
      backgroundStyle.backgroundColor = backgroundColor;
    }

    if (textColor && textColor.startsWith('#')) {
      textColorStyle.color = textColor;
    }

    return (
      <div className={`article-ed-w-bg article-ed-w-bg--${backgroundColor}`} style={backgroundStyle}>
        <div className={`article-ed-w-bg__ctnr ctnr ${alignment} ${textColor}`} style={textColorStyle}>
          <div className="article-ed-w-bg__image">
            { image && (
              <div
                dangerouslySetInnerHTML={{__html: image.asset}}
              />
            )}
            {image.caption &&
              <div class="article-ed-w-bg__info">
                <img onClick={this.changeModalOpen.bind(this)} src="/icons/misc/NikeSB_Caption-Icon_M.png" alt="information" />
              </div>}
            {image.caption &&
              <div className={`article-ed-w-bg__group ${modalClass}`}>
                <img onClick={this.changeModalClosed.bind(this)} src="/icons/misc/NikeSB_CaptionClose-Icon_M.png" alt="close" />
                <p className={`article-ed-w-bg__caption ${fontSize}`}>{image.caption}</p>
              </div>}
          </div>
          <div className={`article-ed-w-bg__text copy copy-url ${textColor}`}
            style={textColorStyle}
            dangerouslySetInnerHTML={{ __html: copy }}
          />
        </div>
      </div>
    );
  }
}
