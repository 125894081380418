import React, { Component } from 'react';
import './ArticleCard.scss';
import { Link } from 'react-router-dom';

export default class ArticleCard extends Component {
  render() {
    let gridArea = this.props.gridArea || '';

    let {
      headline,
      copy,
      date,
      textColor,
      highlightColor,
      image,
      ctaCopy,
      uri,
      eventAction
    } = this.props;

    let dateArray = date.split('/');

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: `${eventAction}`,
        event_category: "Page Content",
        event_label: `${eventAction}: ${headline} - ${ctaCopy}`,
      }
    }

    let hoverClass = `hov-bg--${highlightColor}`;
    const headlineStyle = {};
    if (textColor && textColor.startsWith('#')) {
      headlineStyle.color = textColor;
    }
    if (highlightColor && highlightColor.startsWith('#')) {
      headlineStyle.backgroundImage = `linear-gradient(90deg, ${highlightColor} 0, ${highlightColor} 0)`;
      hoverClass = 'hov-bg--highlight-color';
    }

    return (
      <Link
        to={`/${uri}`}
        className="parent"
        data-count-grid-module
        data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <div className={`article-card ${gridArea}`}>
          <p className="article-card__date date">
            {dateArray[0]}
            <br />
            {dateArray[1]}
            <br />
            {dateArray[2]}
          </p>
          <div className="article-card__img-ctnr">
            {image && image.background && (
              <div
                className={`article-card__background ${hoverClass}`}
                style={{
                  backgroundImage: `url(${image.background.dataOriginal})`,
                  backgroundPosition: `${image.focus.x * 100}% ${image.focus.y * 100}%`,
                  '--highlight-color': `${highlightColor}`
                }}
                dangerouslySetInnerHTML={{ __html: image.asset }}
              />
            )}
            <div
              className={`article-card__headline`}
            >
              <h2
                className={`${textColor} headline headline--lg headline--highlight headline--highlight-${highlightColor}`}
                style={headlineStyle}
              >
                {headline}
              </h2>
            </div>
          </div>
          <div className="article-card__ctnt">
            <div className="copy"
              dangerouslySetInnerHTML={{ __html: copy }}></div>
            <span
              className="cta-span link"
            >
              <span className="arrow" />
              <span className="link__text">{ctaCopy}</span>
            </span>
          </div>
        </div>
      </Link>
    );
  }
}
