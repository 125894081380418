import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './ArticleHeroA.scss';
import SocialShare from '../ArticleSocialShare/ArticleSocialShare';

export default class ArticleHeroA extends Component {
  constructor(props) {
    super(props);
    this.state = { playing: false };
  }

  render() {
    let {
      postDate,
      textColor,
      highlightColor,
      heroImage,
      title,
      youtubeId
     } = this.props;

    const headlineStyle = {};
    if (textColor && textColor.startsWith('#')) {
      headlineStyle.color = textColor;
    }
    if (highlightColor && highlightColor.startsWith('#')) {
      headlineStyle.backgroundImage = `linear-gradient(90deg, ${highlightColor} 0, ${highlightColor} 0)`
    }

    return (
      <div className="article-hero-A ctnr">
        {youtubeId &&
          <div
            className={`article-hero-A__click-target ${
              this.state.playing && youtubeId ? 'playing' : ''
            }`}
            onClick={() => {
              this.setState({ playing: true });
            }}
          />
        }
        {heroImage.asset &&
          <React.Fragment>
            <div
              className={`article-hero-A__img-ctnr ${
                this.state.playing && youtubeId ? 'playing' : ''
              }`}
            >
              <div
                className={`article-hero-A__img ${youtubeId ? 'has-video' : ''}`}
                style={{
                  backgroundImage: `url(${heroImage.background.dataOriginal})`,
                  backgroundPosition: `${heroImage.focus.x * 100}% ${heroImage.focus.y * 100}%`,
                }}
                alt=""
                dangerouslySetInnerHTML={{ __html: heroImage.asset }}
              />
              {youtubeId &&
                <React.Fragment>
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={`${youtubeId}`}
                    playing={this.state.playing}
                    playsinline={false}
                    className={'article-hero-A__video react-player'}
                    tabIndex={1}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        this.setState({ playing: true });
                      }
                    }}
                    config={{
                      youtube: {
                        playerVars: { rel: 0, controls: 1, playsinline: 0 },
                        embedOptions: {
                          host: 'https://www.youtube-nocookie.com'
                        }
                      }
                    }}
                  />
                  <img
                    src="/icons/misc/VideoPlay.svg"
                    className="article-hero-A__play-btn play-btn"
                    alt="Play video"
                  />
                </React.Fragment>
              }
            </div>
          </React.Fragment>
        }
        <div className="article-hero-A__content">
          <div className={`article-hero-A__headline`}>
            <h1
              className={` headline--xl headline--highlight headline--highlight-${highlightColor} ${textColor}`}
              style={headlineStyle}
            >
              {title}
            </h1>
          </div>

          {postDate && <p className="article-hero-A__date date">{postDate}</p>}

          <SocialShare />
        </div>
      </div>
    );
  }
}
