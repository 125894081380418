import React, { Component } from 'react';
import './CookieSettings.scss';

export default class CookieSettings extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      performance: (props.sq === '1' || props.sq === '3' || props.sq === null || props.sq === undefined), 
      social: (props.sq === '2' || props.sq === '3' || props.sq === null || props.sq === undefined)
    };
  }

  _handleClick = () => {
    this.props.applySettings(this.state.performance, this.state.social);
  }

  render() {
    let { countryCode, isOpen, copy } = this.props;

    let base = "";
    if(typeof window !== "undefined") {
      base = window.location.host === "www.nikesb.com" ? "https://agreementservice.svs.nike.com" : "https://agreement.test.svs.nike.com";
    } else {
      base = process.env.BASE_URL === "https://www.nikesb.com" ? "https://agreementservice.svs.nike.com" : "https://agreement.test.svs.nike.com";
    }
    let privacyLink = `${base}/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.nikesb&country=${countryCode}&requestType=styled`;

    return (
      <div className={`cookie-settings ${isOpen ? 'open' : ''}`}>
        <div className="cookie-settings__content">
          <img onClick={this._handleClick} src="/icons/nav/CloseX-01.svg" className="cookie-settings__close" alt="Close" />
          <h2 className="yellow headline headline--lg headline--highlight headline--highlight-blue">{copy.cookieSettings}</h2>

          <div className="cookie-settings__option">
            <div className="cookie-settings__option-btn enabled copy--sans" />
            <div className="cookie-settings__option-copy">
              <h3 className="headline headline--md">{copy.functional.headline}</h3>
              <p className="copy copy--sans">{copy.functional.copy}</p>
            </div>
          </div>

          <div className="cookie-settings__option" onClick={()=>{this.setState({performance: !this.state.performance});}}>
            <div className={`cookie-settings__option-btn copy--sans ${this.state.performance ? 'enabled' : ''}`} />
            <div className="cookie-settings__option-copy">
              <h3 className="headline headline--md">{copy.performance.headline}</h3>
              <p className="copy copy--sans">{copy.performance.copy}</p>
            </div>
          </div>

          <div className="cookie-settings__option" onClick={()=>{this.setState({social: !this.state.social});}}>
            <div className={`cookie-settings__option-btn copy--sans ${this.state.social ? 'enabled' : ''}`} />
            <div className="cookie-settings__option-copy">
              <h3 className="headline headline--md">{copy.social.headline}</h3>
              <p className="copy copy--sans">
                { copy.social.copy.map((component, i) => {
                  let key = `settings:social:${i}`;
                  switch(component.block) {
                    case "copy":
                      return <React.Fragment key={key}>{component.copy}</React.Fragment>;
                    case "privacyLink":
                      return <a className="link--inline" key={key} href={privacyLink} rel="noopener noreferrer" target="_blank">{component.copy}</a>;
                    default:
                      return false;
                  }
                })}
              </p>
            </div>
          </div>

          <button onClick={this._handleClick} className="btn btn--blue cookie-settings__complete">{copy.done}</button>
          
          <p className="copy copy--sans">
            { copy.changePreference.map((component, i) => {
              let key = `settings:${i}`;
              switch(component.block) {
                case "copy":
                  return <React.Fragment key={key}>{component.copy}</React.Fragment>;
                case "privacyLink":
                  return <a className="link--inline" key={key} href={privacyLink} rel="noopener noreferrer" target="_blank">{component.copy}</a>;
                default:
                  return false;
              }
            })}
          </p>
        </div>
      </div>
    );
  }
}
