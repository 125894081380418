import React,
 { Component } from 'react';
import './ArchiveDetailHero.scss';

// expected props
// heroMainImageURL: string
// textColor: string
// backgroundColor: string
// heroEyebrowText: string
// heroHeaderText: string
// colorwayText: string
// releaseDateText: string
// boxColorText: string
// boxImageURL: string
// bodyCopy: string
// optionalVerticalImageURL: string
// optionalVerticalImageText: string
// optionalHorizontalImageURL: string

export default class ArchiveDetailHero extends Component {
  render() {

    let {
      alignment,
      copy,
      title,
      shoeName,
      heroImage,
      colorway,
      releaseYear,
      boxName,
      boxImage,
      backgroundColor,
      textColor,
      image,
      secondaryImage
    } = this.props;

    const textColorStyle = {};
    const borderColorStyle = {};
    const backgroundColorStyle = {};
    if (textColor && textColor.startsWith('#')) {
      textColorStyle.color = textColor;
      borderColorStyle.borderColor = textColor;
      borderColorStyle.borderStyle = 'solid';
    }

    if (backgroundColor && backgroundColor.startsWith('#')) {
      backgroundColorStyle.backgroundColor = backgroundColor;
    }

    return (
      <div className="archive-detail-hero">
        <div className="ctnr archive-detail-hero__ctnr">
          <div className="archive-detail-hero__image"
              dangerouslySetInnerHTML={{__html: heroImage}} />
        </div>

        <div className={`archive-detail-hero__content bg--${backgroundColor}`} style={backgroundColorStyle}>
          <div className="ctnr">
            <div className="archive-detail-hero__header">
              <p
                className={`archive-detail-hero__header__eyebrow date ${textColor}`}
                style={textColorStyle}
              >
                {shoeName}
              </p>
              <h1
                className={`headline headline--alt headline--alt-lg ${textColor}`}
                style={textColorStyle}
              >
                {title}
              </h1>
            </div>
            <div className="archive-detail-hero__details">
              <div
                className={`archive-detail-hero__detail border-color--${textColor}`}
                style={{...borderColorStyle, ...backgroundColorStyle}}
              >
                <p className={`eyebrow ${textColor}`} style={textColorStyle}>Official colorway</p>
                <p className="headline headline--alt headline--alt-xs white">
                  {colorway}
                </p>
              </div>
              <div
                className={`archive-detail-hero__detail border-color--${textColor}`}
                style={{...borderColorStyle, ...backgroundColorStyle}}
              >
                <p className={`eyebrow ${textColor}`} style={textColorStyle}>Date Released</p>
                <p className="headline headline--alt headline--alt-xs white">
                  {releaseYear}
                </p>
              </div>
              <div
                className={`archive-detail-hero__detail archive-detail-hero__detail-box border-color--${textColor}`}
                style={{...borderColorStyle, ...backgroundColorStyle}}
              >
                <div>
                  <p className={`eyebrow ${textColor}`} style={textColorStyle}>Box Color</p>
                  <p className="headline headline--alt headline--alt-xs white">
                    {boxName}
                  </p>
                </div>
                <div className="box-icon"
                    dangerouslySetInnerHTML={{__html: boxImage}} />
              </div>
            </div>
            <div
              className={`archive-detail-hero__copy archive-detail-hero__copy--${alignment} copy copy--intro ${textColor}`}
              style={textColorStyle}
              dangerouslySetInnerHTML={{ __html: copy }}>
            </div>
          </div>
        </div>
        {image && (
          <div className="archive-detail-hero__op-img-A date"
              dangerouslySetInnerHTML={{__html: image}} />
        )}
        {secondaryImage && (
          <div className="archive-detail-hero__op-img-B"
              dangerouslySetInnerHTML={{__html: secondaryImage}} />
        )}
      </div>
    );
  }
}
