import React, { Component } from 'react';
import './ArticleCopyBlock.scss';

// expected props:
// copy: string

export default class ArticleCopyBlock extends Component {
  render() {
    let { copyBlock, textColor } = this.props;

    const copyBlockStyle = {};
    if (textColor && textColor.startsWith('#')) {
      copyBlockStyle.color = textColor;
    }

    return (
      <div className={`article-copy-block copy ${textColor}`} style={copyBlockStyle}>
        <div className="ctnr article-copy-block__ctnr copy-url"
          dangerouslySetInnerHTML={{ __html: copyBlock }}
        />
      </div>
    );
  }
}
