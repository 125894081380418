import React, { Component } from 'react';
import './ArchiveGridBox.scss';
import { Link } from 'react-router-dom';

export default class ArchiveGridBox extends Component {
  render() {
    let { boxes } = this.props;

    return (
      <div className="archive-grid ctnr">
        {boxes.map((component, i) => {
          const key = 'Box:' + i;
          return <Box {...component} key={key} />;
        })}
      </div>
    );
  }
}

class Box extends Component {
  render() {
    let { boxName, boxImage, highlightColor, dateRange, slug } = this.props;
    let highlightStyle = {
      backgroundImage: `linear-gradient(90deg, ${highlightColor} 0, ${highlightColor} 0)`
    };

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: "Vault content click",
        event_category: "Page Content",
        event_label: `Vault content click: ${boxName} - ${dateRange}`,
      }
    }
    return (
      <div className="archive-grid-box ctnr">
        <Link className="archive-grid-box__link" 
              to={`/the-vault/${slug}`}
              data-track-click={JSON.stringify(trackClickOptions.module)}>
          <div
            className="archive-grid-box__image"
            dangerouslySetInnerHTML={{ __html: boxImage }}
          />
          <div className="archive-grid-box__headline-parent">
            <p
              className={`archive-grid-box__headline headline--alt headline--alt-sm headline--highlight`}
              style={highlightStyle}
            >
              {boxName}
            </p>
          </div>
          <p className="date date--sm">{dateRange}</p>
        </Link>
      </div>
    );
  }
}
