import React, { Component } from 'react';
import './ArticleFeatured.scss';
import { Link } from 'react-router-dom';

export default class ArticleFeatured extends Component {
  render() {
    let isSecondaryAssetVideo = false;

    let {
      textColor,
      highlightColor,
      featuredArticle,
      image,
      secondaryImage,
      headline,
      copy,
      ctaCopy,
      eventAction
    } = this.props;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: `${eventAction}`,
        event_category: "Page Content",
        event_label: `${eventAction}: ${headline} - ${ctaCopy}`,
      }
    }

    return (
      <Link
        className="article-featured"
        to={`${featuredArticle}`}
        data-count-grid-module
        data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <div className="article-featured__flex-parent">
          <div className="article-featured__flex-child">
            <div className="article-featured__img-parent"
              dangerouslySetInnerHTML={{__html: image.asset}} />
          </div>
        </div>

        {isSecondaryAssetVideo ? (
          <div
            className="article-featured__secondary-media"
          />
        ) : (
          <div
            className="article-featured__secondary-media"
            dangerouslySetInnerHTML={{__html: secondaryImage.asset}}
          />
        )}

        <div className="article-featured__flex-parent">
          <div className="article-featured__content">
            <div className="article-featured__headline">
              <h3
                className={`${textColor} headline headline--xl headline--highlight headline--highlight-${highlightColor}`}
              >
                {headline}
              </h3>
            </div>
            <div className="article-featured__content-body">
              <div className="copy copy--md" 
                dangerouslySetInnerHTML={{ __html: copy }}>
              </div>
              <div
                className="link article-featured__cta"
              >
                <span className="arrow" />
                <span className="link__text">{ctaCopy}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}
