import React, { Component } from 'react';
import './InstaFeed.scss';

export default class InstaFeed extends Component {
  render() {
    let { headline, backgroundColor, instagramHandle, instagramTag, textColor, instagramImages } = this.props;

    const feedStyles = {};
    if (textColor && textColor.startsWith('#')) {
      feedStyles.color = textColor;
    }
    if (backgroundColor && backgroundColor.startsWith('#')) {
      feedStyles.backgroundColor = backgroundColor;
    }

    let instagramLink = instagramHandle ? `http://instagram.com/${instagramHandle}` : `http://instagram.com/explore/tags/${instagramTag}`;
    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: "Instagram click",
        event_category: "Instagram",
        event_label: `Instagram click: @${instagramHandle}`,
      }
    }
    return (
      <a  href={instagramLink}
          target="_blank"
          rel="noopener noreferrer"
          className={`insta-feed bg--${backgroundColor} ${textColor}`}
          style={feedStyles}
          data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <div className="ctnr insta-feed__ctnr">
          {instagramImages.map((image, i) => {
            const key = (instagramHandle ? instagramHandle : instagramTag) + ':' + i;

            return (
              <div key={key} className="insta-feed__img">
                <div className="insta-feed__img-inner" dangerouslySetInnerHTML={{__html: image.asset}} />
              </div>
            );
          })}

          <div className="insta-feed__desc">
            <p className="eyebrow">{instagramHandle ? `@${instagramHandle}` : instagramTag}</p>
            <h3 className="insta-feed__headline headline--alt headline--alt-sm">{ headline }</h3>
          </div>
        </div>
      </a>
    );
  }
}
