import React, { Component } from 'react';
import './InstaCheckMe.scss';

export default class InstaCheckMe extends Component {
  render() {
    let gridArea = this.props.gridArea || '';
    let { borderColor, author, instagramImage, postUrl, internalExternalUrl } = this.props;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: "Instagram click",
        event_category: "Instagram",
        event_label: `Instagram click: @${author}`,
      }
    }

    return (
      <a 
        href={internalExternalUrl.value ? internalExternalUrl.value : postUrl} 
        target={internalExternalUrl.target} 
        className={`insta-check-me ${gridArea}`}
        data-count-grid-module
        data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <div className={`insta-check-me__tape bg--${borderColor}`} />
        <div className="insta-check-me__ctnt">
          {/* <p className="insta-check-me__hashtag eyebrow">{hashtag}</p> */}
          <div dangerouslySetInnerHTML={{ __html: instagramImage.asset }} />
          <p className="insta-check-me__user eyebrow">{internalExternalUrl.customText ? internalExternalUrl.customText : `@${author}`}</p>
        </div>
      </a>
    );
  }
}
