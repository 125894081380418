import React, { Component } from 'react';
import './ArticleSocialShare.scss';
import AriaModal from 'react-aria-modal';
import { CSSTransition } from 'react-transition-group';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

export default class SocialShare extends Component {
  constructor(props) {
    super(props);
    this.state = { drawerOpen: false, modalOpen: false, link: '' };
  }
  componentDidMount() {
    this.setState({ link: window.location.href });
  }
  toggleDrawerOpen = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };
  toggleOverlayOpen = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };
  closeOverlay = () => {
    this.setState({ modalOpen: false });
  };
  render() {
    let link = this.state.link;

    return (
      <div className="article-social-share">
        <div className="article-social-share__flex-parent" onClick={this.toggleDrawerOpen}>
          <p
            className="article-social-share__toggle date"
          >
            <img
              className="article-social-share__share-arrow icon"
              src="/icons/social/Share_Arrow.svg"
              alt="social share button"
            />
            share
          </p>
          {this.state.drawerOpen && (
            <CSSTransition
              in={this.state.drawerOpen}
              appear
              classNames="button-drawer"
              timeout={300}
            >
              <div
                className="article-social-share__btns"
                onClick={this.toggleOpen}
              >
                <FacebookShareButton className="article-social-share__btn" url={link} key="facebook">
                  <img src="/icons/social/Social_Facebook_Black.svg" alt="" />
                </FacebookShareButton>
                <TwitterShareButton className="article-social-share__btn" url={link} key="twitter">
                  <img src="/icons/social/Social_Twitter_Black.svg" alt="" />
                </TwitterShareButton>
                <div role="button" className="article-social-share__btn" onClick={this.toggleOverlayOpen}>
                  <img src="/icons/social/clipboard.svg" alt="" />
                </div>
              </div>
            </CSSTransition>
          )}
        </div>
        {this.state.modalOpen && (
          <CopyLinkOverlay closeOverlay={this.closeOverlay} link={link} />
        )}
      </div>
    );
  }
}

class CopyLinkOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      copied: false
    };
  }
  render() {
    return (
      <AriaModal
        titleText="Nike SB - Copy Link"
        initialFocus="#close"
        onExit={this.props.closeOverlay}
        underlayStyle={{ background: 'rgba(0, 0, 0, 0.8)' }}
        underlayClass="copy-link__parent"
        dialogClass="copy-link"
        dialogStyle={{
          display: 'flex',
          textAlign: 'center'
        }}
      >
        <img
          id="close"
          className="copy-link__close btn--close icon"
          src="/icons/nav/Video_CloseIcon.svg"
          onClick={this.props.closeOverlay}
          alt="close navigation button"
        />
        <h3 className="headline headline--alt">Copy Link</h3>
        <p className="copy-link__link-text eyebrow">
          <span>{this.props.link}</span>
        </p>
        <CopyToClipboard
          text={this.props.link}
          id={'copy'}
          onCopy={() => this.setState({ copied: true })}
        >
          <p className="btn copy-link__btn date">Copy</p>
        </CopyToClipboard>
        {this.state.copied ? (
          <p className="copy-link__status date">Copied</p>
        ) : (
          <p className="copy-link__status date" />
        )}
      </AriaModal>
    );
  }
}
