import React, { Component } from 'react';
import './InstaEmoji.scss';
const emoji = require('emoji-dictionary');

export default class InstaEmoji extends Component {
  render() {
    let gridArea = this.props.gridArea || '';
    let { emojiName, instagramImage, postUrl, author, internalExternalUrl } = this.props;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: "Instagram click",
        event_category: "Instagram",
        event_label: `Instagram click: @${author}`,
      }
    }

    return (
      <a 
        href={internalExternalUrl.value ? internalExternalUrl.value : postUrl} 
        target={internalExternalUrl.target} 
        className={`insta-emoji ${gridArea}`}
        data-count-grid-module
        data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <p className="insta-emoji__emoji">{emojiName ? emoji.getUnicode(emojiName) : ''}</p>
        <div dangerouslySetInnerHTML={{ __html: instagramImage.asset }} />
        <div className="insta-emoji__ctnt">
          <p className="eyebrow">{internalExternalUrl.customText ? internalExternalUrl.customText : `@${author}`}</p>
        </div>
      </a>
    );
  }
}
