import React, { Component } from 'react';
import './CloseButton.scss';

export default class CloseButton extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    if(!this.buttonRef.current) return;

    this.buttonRef.current.focus();
  }

  render() {
    return (
      <button
        className={`close-button ${this.props.className}`}
        title={this.props.title || 'close'}
        onClick={this.props.onClick || null}
        ref={this.buttonRef}
      >
        <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
          <rect className="close-button__square" x="0.5" y="0.5" width="41" height="41" fill="white" stroke="black"/>
          <path className="close-button__x" d="M12.2734 13.4612L13.4614 12.2732L29.727 28.5389L28.5391 29.7268L12.2734 13.4612Z" fill="black"/>
          <path className="close-button__x" d="M28.5391 12.2732L29.727 13.4611L13.4614 29.7268L12.2734 28.5388L28.5391 12.2732Z" fill="black"/>
        </svg>
      </button>
    );
  }
}