import React, { Component } from 'react';

export default class AthleteSbIcon extends Component {
  render() {
    return (
      <svg viewBox="0 0 88 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34 18C34 26.8364 26.8367 34 17.9998 34C9.16332 34 2 26.8364 2 18C2 9.16361 9.16332 2 17.9998 2C26.8367 2 34 9.16361 34 18Z" fill="white"/>
      <path d="M17.9998 35C27.389 35 35 27.3887 35 18C35 8.61133 27.389 1 17.9998 1C8.61102 1 1 8.61135 1 18C1 27.3887 8.61102 35 17.9998 35Z" fill="#FFF617" stroke="#338022" stroke-width="2"/>
      <path d="M21.317 25.9737C23.1822 26.7519 26.1047 27.6431 27.3273 25.7073C28.0115 24.6422 27.5764 22.9622 26.5718 21.437C25.9395 20.4637 25.1098 19.5728 24.2911 18.6816C24.7478 19.4088 26.0323 21.8568 24.3427 23.0653C23.6901 23.5467 22.5399 23.5874 21.1095 23.2187L4 18.7532L21.317 25.9737Z" fill="#338022"/>
      <path d="M22.0183 10.871C22.0183 13.0068 21.4753 14.7385 20.8051 14.7385C20.1352 14.7385 19.5918 13.0068 19.5918 10.871C19.5918 8.73526 20.1352 7.00391 20.8051 7.00391C21.4753 7.00391 22.0183 8.73526 22.0183 10.871Z" fill="#338022"/>
      <path d="M15.1941 10.871C15.1941 13.0068 14.651 14.7385 13.9808 14.7385C13.311 14.7385 12.7676 13.0068 12.7676 10.871C12.7676 8.73526 13.311 7.00391 13.9808 7.00391C14.651 7.00391 15.1941 8.73526 15.1941 10.871Z" fill="#338022"/>
      <path d="M58.075 19.34C58.075 21.83 55.645 23.18 50.86 23.18C46.405 23.18 44.485 21.92 43.75 20.27C43.585 19.88 43.51 19.4 43.465 19.1H47.785C47.815 19.28 47.92 19.55 48.07 19.73C48.43 20.165 49.435 20.42 51.145 20.42C53.35 20.42 53.875 20.105 53.875 19.52C53.875 18.89 53.335 18.755 51.34 18.545C50.545 18.47 49.285 18.35 48.76 18.305C44.95 17.96 43.54 16.775 43.54 14.75C43.54 12.65 46.03 11.045 50.575 11.045C54.61 11.045 56.485 12.245 57.265 13.745C57.505 14.195 57.655 14.705 57.7 15.065H53.38C53.365 14.9 53.305 14.735 53.14 14.525C52.81 14.09 51.97 13.805 50.47 13.805C48.355 13.805 47.8 14.06 47.8 14.6C47.8 15.2 48.505 15.335 50.86 15.59C51.775 15.665 53.23 15.815 53.725 15.875C56.77 16.205 58.075 17.285 58.075 19.34ZM63.8495 23H59.7695V19.01H63.8495V23ZM80.243 21.8C79.553 22.64 78.263 23 76.598 23H66.143V11.225H76.313C77.933 11.225 78.968 11.615 79.538 12.2C80.048 12.71 80.273 13.385 80.273 14.15C80.273 14.915 80.093 15.545 79.703 16.01C79.388 16.4 78.848 16.7 78.278 16.775C79.118 16.88 79.913 17.3 80.363 17.885C80.738 18.32 80.918 18.98 80.918 19.67C80.918 20.48 80.693 21.275 80.243 21.8ZM76.103 14.855C76.103 14.57 76.013 14.33 75.818 14.165C75.608 13.97 75.218 13.895 74.648 13.895H70.073V15.815H74.753C75.263 15.815 75.653 15.74 75.863 15.545C76.013 15.38 76.103 15.14 76.103 14.855ZM76.553 20.015C76.703 19.865 76.763 19.64 76.763 19.355C76.763 19.055 76.688 18.83 76.508 18.65C76.283 18.455 75.893 18.365 75.233 18.365H70.073V20.33H75.188C75.893 20.33 76.313 20.24 76.553 20.015ZM86.6571 23H82.5771V19.01H86.6571V23Z" fill="#338022"/>
      </svg>
    );
  }
}