


import React, { Component } from 'react';

export default class FilterIcon extends Component {
  render() {
    return (
      <svg viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2874 3H0.712589C0.31829 3 0 2.77667 0 2.5C0 2.22333 0.31829 2 0.712589 2H17.2874C17.6817 2 18 2.22333 18 2.5C18 2.77667 17.6817 3 17.2874 3Z" fill="black"/>
        <path d="M17.1991 9H0.800949C0.357757 9 0 8.77667 0 8.5C0 8.22333 0.357757 8 0.800949 8H17.1991C17.6422 8 18 8.22333 18 8.5C18 8.77667 17.6422 9 17.1991 9Z" fill="black"/>
        <path d="M17.2033 15H0.796695C0.355857 15 0 14.7767 0 14.5C0 14.2233 0.355857 14 0.796695 14H17.2033C17.6441 14 18 14.2233 18 14.5C18 14.7767 17.6441 15 17.2033 15Z" fill="black"/>
        <circle className="filter-icon__control--left" cx="5.5" cy="2.5" r="2.125" fill="#F6F6F6" stroke="black" strokeWidth="0.85"/>
        <circle className="filter-icon__control--left" cx="5.5" cy="14.5" r="2.125" fill="#F6F6F6" stroke="black" strokeWidth="0.85"/>
        <circle className="filter-icon__control--right" cx="12.5" cy="8.5" r="2.125" fill="#F6F6F6" stroke="black" strokeWidth="0.85"/>
      </svg>
    );
  }
}