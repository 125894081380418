import React, { Component } from 'react';
import './Editorial.scss';

export default class Editorial extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false
    }
  }
  changeModalClosed() {
    this.setState({modalOpen: false});
  }
  changeModalOpen() {
    this.setState({modalOpen: true});
  }
  render() {
    let { textColor, copy, borderColor, image, secondaryImage, backgroundColor, copyImageAlignment } = this.props;
    const alignment = copyImageAlignment ? copyImageAlignment : '';
    const fontSize = image.caption && image.caption.length > 60 ? 'fs-small' : 'fs-large';
    let modalClass = this.state.modalOpen ? 'editorial__right__open' : '';

    const textStyles = {};
    if (textColor && textColor.startsWith('#')) {
      textStyles.color = textColor;
    }

    const bgStyles = {};
    if (backgroundColor && backgroundColor.startsWith('#')) {
      bgStyles.backgroundColor = backgroundColor;
    }

    const cid = borderColor ? `cid-${borderColor.replace('#', '')}` : '';

    return (
      <div className={`editorial ctnr ${alignment} ${cid}`}>
        {borderColor && borderColor.startsWith('#') && (
          <style>{`
            .editorial.${cid} .editorial__copy-ctnr:after {
              border-style: solid;
              border-color: ${borderColor};
            }
          `}</style>
        )}
        <div className="editorial__left">
          <div className={`editorial__copy-ctnr border-color--after-${borderColor}`}>
            <div style={textStyles} className={`copy copy--intro copy-url ${ textColor }`} dangerouslySetInnerHTML={{ __html: copy }} />
          </div>
          { secondaryImage && (
            <div className={`editorial__img-secondary`}
              dangerouslySetInnerHTML={{__html: secondaryImage.asset}}>
            </div>
          )}
        </div>
        <div className={`editorial__right bg--${backgroundColor}`} style={bgStyles}>
          <div dangerouslySetInnerHTML={{__html: image.asset}}></div>
          {image.caption &&
            <div class="editorial__right__info">
              <img onClick={this.changeModalOpen.bind(this)} src="/icons/misc/NikeSB_Caption-Icon_M.png" alt="information" />
            </div>}
          {image.caption &&
            <div className={`editorial__right__group ${modalClass}`}>
              <img onClick={this.changeModalClosed.bind(this)} src="/icons/misc/NikeSB_CaptionClose-Icon_M.png" alt="close" />
              <p className={`editorial__right__caption ${fontSize}`}>{image.caption}</p>
            </div>}
        </div>
      </div>
    );
  }
}