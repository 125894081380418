import React, { Component } from 'react';
import ReactPlayer from 'react-player';
// import './HeroSkateshop.scss';

export default class HeroSkateshop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false ,
      tooltipOpen: false
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.onclick = () => {
        this.setState({
          tooltipOpen: false
        });
      }
    }
  }

  toggleTooltip = (e) => {
    e.stopPropagation();

    let tooltipOpen = !this.state.tooltipOpen;

    this.setState({
      tooltipOpen
    });
  }

  handleTooltipBodyClick = (e) => {
    e.stopPropagation();
  }

  closeTooltip = (e) => {
    e.stopPropagation();

    this.setState({
      tooltipOpen: false
    })
  }

  render() {
    let {
      shopName,
      heroImage,
      yearEstablished,
      address,
      shopWebsite,
      phoneNumber,
      textColor,
      backgroundColor,
      youtubeId,
      shopLogo,
      orangeLabel
    } = this.props;

    const textColorStyle = {};
    const borderColorStyle = {};
    const backgroundColorStyle = {};
    if (textColor && textColor.startsWith('#')) {
      textColorStyle.color = textColor;
      borderColorStyle.borderColor = textColor;
      borderColorStyle.borderStyle = 'solid';
    }

    if (backgroundColor && backgroundColor.startsWith('#')) {
      backgroundColorStyle.backgroundColor = backgroundColor;
      backgroundColorStyle.borderStyle = 'solid';
    }


    return (
      <div className={`athlete-hero--${backgroundColor} athlete-hero`} style={backgroundColorStyle}>
        <div className="ctnr athlete-hero__ctnr">
          <div className={`athlete-hero__content`}>
            { shopLogo && <div
              className={`athlete-hero__profile-pic border-color--${textColor}`}
              style={textColorStyle}
              dangerouslySetInnerHTML={{ __html: shopLogo.asset }}
            /> }
            <div>
              <h1
                className={`athlete-hero__headline headline headline--alt headline--alt-lg ${textColor}`}
                style={textColorStyle}
              >
                {shopName}
              </h1>
              {yearEstablished &&
                <p className="athlete-hero__copy copy copy--lg white">Established {yearEstablished}</p>
              }
              {orangeLabel &&
                <div className="athlete-hero__ol">
                  <img src="/icons/maps/Carrot-03.svg" alt="Orange Label" className="ol-icon" />
                  <button className="athlete-hero__ol__tooltip" onClick={this.toggleTooltip}>
                    Orange Label Shop
                    <div className={`tooltip__content ${this.state.tooltipOpen ? 'open' : ''}`} onClick={this.handleTooltipBodyClick}>
                      <img
                        className="btn--close icon close-tooltip"
                        src="/icons/nav/CloseX-01.svg"
                        onClick={this.closeTooltip}
                        alt="close navigation button"
                      />
                      <p className="headline headline--italic">Nike SB Orange Label</p>
                      <p className="black">Only Available In Skateshops. Our favorite styles. The best materials.</p>
                      <a className="tooltip-link" href="/orangelabel">Learn More</a>
                    </div>
                  </button>
                </div>
              }
            </div>
          </div>
          { heroImage && (
            <div
              className={`athlete-hero__img-ctnr ${
                this.state.playing && youtubeId ? 'playing' : ''
              }`}
              onClick={() => {
                this.setState({ playing: true });
              }}
            >
              <div
                className={`athlete-hero__img ${youtubeId ? 'has-video' : ''}`}
                dangerouslySetInnerHTML={{ __html: heroImage.asset }}
              />
              {youtubeId && (
                <React.Fragment>
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={`${youtubeId}`}
                    playing={this.state.playing}
                    playsinline={false}
                    className={'athlete-hero__video react-player'}
                    config={{
                      youtube: {
                        playerVars: { rel: 0, controls: 1, playsinline: 0 },
                        embedOptions: {
                          host: 'https://www.youtube-nocookie.com'
                        }
                      }
                    }}
                  />
                  <img
                    src="/icons/misc/VideoPlay.svg"
                    className="athlete-hero__play-btn play-btn"
                    alt="Play video"
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <div className={`athlete-details ctnr`}>
          <div
            className={`athlete-details__ctnr bg--${backgroundColor} border-color--${textColor}`}
            style={{...borderColorStyle, ...backgroundColorStyle}}
          >
            <p className={`athlete-details__eyebrow eyebrow ${textColor}`} style={textColorStyle}>
              Address
            </p>
            <h3 className="white headline--alt athlete-details__headline">
              {address.address} {address.city}, {address.state} {address.zipCode}
            </h3>
          </div>
          <div
            className={`athlete-details__ctnr bg--${backgroundColor} border-color--${textColor}`}
            style={{...borderColorStyle, ...backgroundColorStyle}}
          >
            <p
              className={`athlete-details__eyebrow eyebrow ${textColor}`}
              style={textColorStyle}
            >
              Phone
            </p>
            <h3 className="white headline--alt athlete-details__headline">
              {phoneNumber}
            </h3>
          </div>
          <a
            href={shopWebsite}
            target="blank"
            className={`athlete-details__ctnr bg--${backgroundColor} skate-shoe border-color--${textColor}`}
            style={{...borderColorStyle, ...backgroundColorStyle}}
            data-track-click={JSON.stringify({
              event_action: "Skateshop click",
              event_category: 'Shop Website',
              event_label: `Skateshop click: ${shopName}`,
            })}
          >
            <div className="skate-shoe__details">
              <p
                className={`athlete-details__eyebrow eyebrow ${textColor}`}
                style={textColorStyle}
              >
                Website
              </p>
              <h3 className="white headline--alt athlete-details__headline">
                {shopWebsite}
              </h3>
            </div>
          </a>
        </div>
      </div>
    );
  }
}
