import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import Measure from 'react-measure';
import AriaModal from 'react-aria-modal';
import VideoModal from '../VideoModal/VideoModal';
import './VideoMaybeBorder.scss';

export default class VideoMaybeBorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      isOverlayOpen: false,
      contentRect: {},
      playing: false
    };
  }
  /*
    =====
    PAUSED WORK ON CUSTOM VIDEO CONTROLS

    Controlled Video Component modified from react-player
    https://github.com/CookPete/react-player/blob/master/src/demo/App.js

    Transition strategy using react-measure and react-aria-modal
    - does not update getboundingClientRect except on resize
    - need a way to grab distance to top of viewport on scroll, or - ideally - onClick for overlay
    https://github.com/souporserious/react-measure/blob/master/src/with-content-rect.js

    Custom Styling Video Elements:
    https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery/cross_browser_video_player
    https://developer.mozilla.org/en-US/docs/Web/Guide/Audio_and_video_delivery/Video_player_styling_basics
    https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
    =====
  */

  openOverlay = () => {
    this.setState({ isOverlayOpen: true });
  };
  closeOverlay = () => {
    this.setState({ isOverlayOpen: false, hover: false });
  };
  toggleOverlay = () => {
    this.state.isOverlayOpen ? this.closeOverlay() : this.openOverlay();
  };

  render() {
    let { youtubeId, ctaCopy, copy, borderColor, borderSize, customText, value, target, moduleName, eventAction } = this.props;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: `${eventAction}`,
        event_category: "Page Content",
        event_label: `${eventAction}: ${moduleName} - ${ctaCopy}`,
      }
    }

    return (
      <div
        className="video-maybe-border"
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={this.toggleOverlay}
        data-track-click={JSON.stringify(trackClickOptions.module)}
        data-count-grid-module
      >
        <Measure
          bounds
          offset
          scroll
          client
          margin
          onResize={contentRect => this.setState({ contentRect })}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <div
                className={`border-color--${borderColor} border-thickness--${borderSize} video-maybe-border__ctnr`}
              >
                <div className="video-maybe-border__tap-target" />
                <ReactPlayer
                  width={'100%'}
                  height={'100%'}
                  url={youtubeId}
                  playing={this.props.width >= 769 && !this.state.isOverlayOpen && this.state.hover}
                  loop={true}
                  playsinline={true}
                  muted={true}
                  className={'react-player react-player--no-events'}
                  config={{
                    youtube: {
                      playerVars: { rel: 0, controls: 0 },
                      embedOptions: {
                        host: 'https://www.youtube-nocookie.com'
                      }
                    },
                    file: {
                      attributes: {
                        className: `${
                          borderColor !== '' && borderSize !== ''
                            ? `border-color--${borderColor} border-thickness--${borderSize}`
                            : ''
                        }`
                      }
                    }
                  }}
                />
              </div>
            </div>
          )}
        </Measure>

        { customText && value ? (
          <div className="video-maybe-border__content">
            <div className="copy"
              dangerouslySetInnerHTML={{ __html: copy }}></div>
            <a href={value}
               className="cta-span link"
               target={target}
               onClick={e => e.stopPropagation()}
            >
              <span className="play-icon" />
              <span className="link__text">{customText}</span>
            </a>
          </div> 
        ) : (
          <div className="video-maybe-border__content">
            <div className="copy"
              dangerouslySetInnerHTML={{ __html: copy }}></div>
            <span className="cta-span link">
              <span className="play-icon" />
              <span className="link__text">{ctaCopy}</span>
            </span>
          </div>
        )}
       
        {this.state.isOverlayOpen && (
          <AriaModal
            titleText="Nike SB Mobile Navigation"
            initialFocus="#close"
            getApplicationNode={() => document.getElementById('root')}
            underlayStyle={{ background: 'rgba(0, 0, 0, 0.8)' }}
            underlayClass=""
            dialogStyle={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              textAlign: 'unset',
              opacity: 1
            }}
            dialogClass="mobile-nav--dialog"
          >
            <VideoModal
              url={youtubeId}
              contentRect={this.state.contentRect}
            />
            <img
              id="close"
              className="btn--close icon"
              src="/icons/nav/Video_CloseIcon.svg"
              onClick={() => this.closeOverlay()}
              alt="close navigation button"
            />
          </AriaModal>
          )}
      </div>
    );
  }
}

// function Duration({ className, seconds }) {
//   return (
//     <time dateTime={`P${Math.round(seconds)}S`} className={className}>
//       {format(seconds)}
//     </time>
//   );
// }

// function format(seconds) {
//   const date = new Date(seconds * 1000);
//   const hh = date.getUTCHours();
//   const mm = date.getUTCMinutes();
//   const ss = pad(date.getUTCSeconds());
//   if (hh) {
//     return `${hh}:${pad(mm)}:${ss}`;
//   }
//   return `${mm}:${ss}`;
// }

// function pad(string) {
//   return ('0' + string).slice(-2);
//}

/*

  PREVIOUS RETURN JSX


      <div
        className="video-player-parent"
        style={{
          width: `${contentRect.bounds.width}px`,
          position: 'absolute',
          top: `${contentRect.bounds.top -
            2 * (contentRect.offset.height + contentRect.offset.top)}px`,
          left: `${contentRect.bounds.left}px`
        }}
      >
        <div className="video-player-parent__controls-top">
          <p>{title}</p>
        </div>
        <ReactPlayer
          ref={this.ref}
          className="react-player"
          width="100%"
          height="100%"
          url={url}
          playing={playing}
          loop={loop}
          volume={volume}
          muted={muted}
          onPlay={this.onPlay}
          onPause={this.onPause}
          onBuffer={() => console.log('onBuffer')}
          onSeek={e => console.log('onSeek', e)}
          onEnded={this.onEnded}
          onError={e => console.log('onError', e)}
          onProgress={this.onProgress}
          onDuration={this.onDuration}
        />

        <table>
          <tbody>
            <tr>
              <th>Controls</th>
              <td>
                <button onClick={this.playPause}>
                  {playing ? 'Pause' : 'Play'}
                </button>
              </td>
            </tr>
            <tr>
              <th>Seek</th>
              <td>
                <input
                  type="range"
                  min={0}
                  max={1}
                  step="any"
                  value={played}
                  onMouseDown={this.onSeekMouseDown}
                  onChange={this.onSeekChange}
                  onMouseUp={this.onSeekMouseUp}
                />
              </td>
            </tr>
            <tr>
              <th>Volume</th>
              <td>
                <input
                  type="range"
                  min={0}
                  max={1}
                  step="any"
                  value={volume}
                  onChange={this.setVolume}
                />
              </td>
            </tr>

            <tr>
              <th>
                <label htmlFor="muted">Muted</label>
              </th>
              <td>
                <input
                  id="muted"
                  type="checkbox"
                  checked={muted}
                  onChange={this.toggleMuted}
                />
              </td>
            </tr>

            <tr>
              <th>Played</th>
              <td>
                <progress max={1} value={played} />
              </td>
            </tr>
          </tbody>
        </table>
        <h2>State</h2>

        <table>
          <tbody>
            <tr>
              <th>duration</th>
              <td>
                <Duration seconds={duration} />
              </td>
            </tr>
            <tr>
              <th>elapsed</th>
              <td>
                <Duration seconds={duration * played} />
              </td>
            </tr>
            <tr>
              <th>remaining</th>
              <td>
                <Duration seconds={duration * (1 - played)} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

 */
