import React, { Component } from 'react';
import './ArticleTilt.scss';
import { Link } from 'react-router-dom';

export default class ArticleTilt extends Component {
  render() {
    let gridArea = this.props.gridArea || '';
    let {
      ctaCopy,
      textColor,
      highlightColor,
      headline,
      image,
      tiltDirection,
      uri,
      eventAction
    } = this.props;

    if (!image) {
      return null;
    }

    const headlineStyle = {};
    if (textColor && textColor.startsWith('#')) {
      headlineStyle.color = textColor;
    }
    if (highlightColor && highlightColor.startsWith('#')) {
      headlineStyle.backgroundImage = `linear-gradient(90deg, ${highlightColor} 0, ${highlightColor} 0)`
    }

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: `${eventAction}`,
        event_category: 'Page Content',
        event_label: `${eventAction}: ${headline} - ${ctaCopy}`,
      }
    }

    return (
      <Link
        className="parent"
        to={`/${uri}`}
        style={{ width: '100%' }}
        data-count-grid-module
        data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <div
          className={`article-tilt article-tilt__${tiltDirection} ${gridArea}`}
        >
          <div
            className="article-tilt__headline"
          >
            <h3
              className={`${textColor} headline headline--italic headline--highlight headline--highlight-${highlightColor}`}
              style={headlineStyle}
            >
              {headline}
            </h3>
          </div>
          <div
            className="article-tilt__img"
            dangerouslySetInnerHTML={{ __html: image.asset }}
          />
          <div className="cta-link-wrapper">
            <span
              className="cta-span link article-tilt__cta"
            >
              <span className="arrow" />
              <span className="link__text">{ ctaCopy ? ctaCopy : "Read More" }</span>
            </span>
          </div>
        </div>
      </Link>
    );
  }
}
