import React, { Component } from 'react';
import './ArchiveDetailSimpleImage.scss';

// expected props:
// imageURL: string

export default class ArchiveDetailSimpleImage extends Component {
  render() {
    let { image } = this.props;

    return (
      <div className="archive-detail-simple-image">
        { image && (
          <div className="ctnr"
            dangerouslySetInnerHTML={{__html: image.asset}} />
        )}
      </div>
    );
  }
}
