import React, { Component } from 'react';
import './SignatureShoe.scss';
// import { Link } from 'react-router-dom';

export default class SignatureShoe extends Component {
  render() {
    let {
      headline,
      copy,
      ctaCopy,
      highlightColor,
      textColor,
      productImage,
      ctaLink,
      copyImageAlignment
    } = this.props;
    const alignment = copyImageAlignment ? copyImageAlignment : '';

    const headlineStyle = {};

    if (textColor && textColor.startsWith('#')) {
      headlineStyle.color = textColor;
    }
    if ( highlightColor && highlightColor.startsWith('#')) {
      headlineStyle.backgroundImage = `linear-gradient(90deg, ${highlightColor} 0, ${highlightColor} 0)`
    }

    return (
      <div className="sig-shoe">
        <div className={`ctnr sig-shoe__ctnr ${alignment}`}>
          <div className="sig-shoe__copy">
            <h2
              className={`headline headline--highlight headline--highlight-${highlightColor} ${textColor} headline--lg`}
              style={headlineStyle}
            >
              {headline}
            </h2>
            <div className="copy copy-url"
              dangerouslySetInnerHTML={{ __html: copy }}
            />
            {ctaLink && ctaCopy && (
              <a
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                href={ctaLink}
                data-track-click={JSON.stringify({
                  event_action: "Shop from team",
                  event_category: "Shop Nike.com",
                  event_label: `Shop from team: ${headline} - ${ctaCopy}`,
                })}
              >
                <span className="arrow" />
                {ctaCopy}
              </a>
            )}
          </div>
          {productImage && (
            <div
              className="sig-shoe__img"
              dangerouslySetInnerHTML={{ __html: productImage.asset }}
            />
          )}
        </div>
      </div>
    );
  }
}
