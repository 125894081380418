import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Wayfinder.scss';

export default class Wayfinder extends Component {
  render() {
    let { nextPage, sectionTitle, sectionURL } = this.props;

    return (
      <div className="wayfinder">
        <Link className="link date wayfinder__left-link" to={sectionURL}>
          <span className="arrow arrow--left" />
          Back to {sectionTitle}
        </Link>
        {nextPage && (
          <Link
            className="link date wayfinder__right-link"
            to={`/${nextPage.uri}`}
          >
            {nextPage.title}
            <span className="arrow arrow--right" />
          </Link>
        )}
      </div>
    );
  }
}
