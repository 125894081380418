import React, { Component } from 'react';
import './ArticleImagePair.scss';

// expected props
// image: object
// secondaryImage: object

export default class ArticleImagePair extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen1: false,
      modalOpen2: false
    }
  }
  changeModalClosed(num) {
    num === 1 ? this.setState({modalOpen1: false}) : this.setState({modalOpen2: false});
  }
  changeModalOpen(num) {
    num === 1 ? this.setState({modalOpen1: true}) : this.setState({modalOpen2: true});
  }
  render() {
    let { image, secondaryImage } = this.props;
    const fontSize1 = image.caption && image.caption.length > 70 ? 'fs-small' : 'fs-large';
    const fontSize2 = secondaryImage.caption && secondaryImage.caption.length > 70 ? 'fs-small' : 'fs-large';
    let modalClass1 = this.state.modalOpen1 ? 'article-image-pair__open' : '';
    let modalClass2 = this.state.modalOpen2 ? 'article-image-pair__open' : '';

    return (
      <div className="article-image-pair">
        <div className="ctnr">
          <div className="article-image-pair__parent">

            <div className="article-image-pair__child">
              <div className="article-image-pair__image"
                dangerouslySetInnerHTML={{__html: image.asset}}>
              </div>
              {image.caption &&
                <div class="article-image-pair__info">
                  <img onClick={this.changeModalOpen.bind(this, 1)} src="/icons/misc/NikeSB_Caption-Icon_M.png" alt="information" />
                </div>}
              {image.caption &&
                <div className={`article-image-pair__group ${modalClass1}`}>
                  <img onClick={this.changeModalClosed.bind(this, 1)} src="/icons/misc/NikeSB_CaptionClose-Icon_M.png" alt="close" />
                  <p className={`article-image-pair__caption ${fontSize1}`}>{image.caption}</p>
                </div>}
            </div>


            <div className="article-image-pair__child">
              <div className="article-image-pair__image"
                  dangerouslySetInnerHTML={{__html: secondaryImage.asset}}>
              </div>
              {secondaryImage.caption &&
                <div class="article-image-pair__info">
                  <img onClick={this.changeModalOpen.bind(this, 2)} src="/icons/misc/NikeSB_Caption-Icon_M.png" alt="information" />
                </div>}
              {secondaryImage.caption &&
                <div className={`article-image-pair__group ${modalClass2}`}>
                  <img onClick={this.changeModalClosed.bind(this, 2)} src="/icons/misc/NikeSB_CaptionClose-Icon_M.png" alt="close" />
                  <p className={`article-image-pair__caption ${fontSize2}`}>{secondaryImage.caption}</p>
                </div>}
            </div>

          </div>
        </div>
      </div>
    );
  }
}
