import { Component } from 'react';
// import debounce from 'lodash.debounce';
import 'element-closest';

let count = 0;

export default class Analytics extends Component {
  // constructor(props) {
    // super(props);
    // Create a debounced version of the eventListener for the input field that
    // can be added and removed as necessary.
    // this.debouncedFilterFieldInputListener = debounce(this._trackFilterFieldInputEvent, 250);

    // Set analytics defaults.
    // const pageName = 'nikesbcom>' + this.props.title.replace('-', ' ');
    // this.analyticsBaseProps = {
    //   prop2:    'nikesbcom', // Site Name
    //   prop10:   'brand', // Page Division
    //   prop14:   this.props.countryCode, // Country Code
    //   prop15:   'en', // Language Code
    //   prop17:   this.props.section,
    //   pageName: pageName,
    //   prop50:   'guest', // What is the UPM ID?
    //   eVar4:    'not logged in' // logged in status
    // }
  // }

  /*
    Pageload tracking.
  */
 _trackPageLoad = () => {
    if (window.gtag) {
      // window.gtag('config', 'UA-158820462-1', {'page_path' : document.location.pathname, 'page_title': document.title});

      // window.gtag('event', document.location.pathname, {
      //   event_category: 'Page View',
      //   event_label: document.location.pathname
      // });

      // DEBUG:
      // console.log('Page load tracked:', window.dataLayer)
    }
    else if (count <= 20) {
      setTimeout(() => {
        count++
        this._trackPageLoad();
      }, 100);
    }
  }

  /*
    Event tracking.
  */
  _trackClickEvents = (event) => {
    // Track a click event if the element that was clicked has a
    // `data-track-click` attribute.
    if (event.target && event.target.closest('[data-track-click]')) {
      // DEBUG:
      // console.log('Click-tracking element clicked:', event.target.closest('[data-track-click]'));

      var element = event.target.closest('[data-track-click]');
      var trackingOptions = JSON.parse(element.dataset.trackClick);

      // DEBUG:
      // console.log('Tracking props:', trackingOptions);

      // Save the tracking event.
      this._trackEvent(trackingOptions);
    }
  }

  /*
    Event tracking.
  */
  _trackKeyEvents = (event) => {
    // Track a keyup event if the element that was typed within has a
    // `data-track-keyup` attribute.
    if (event.target && event.target.closest('[data-track-keyup]')) {
      // DEBUG:
      // console.log('Keyup tracking element:', event.target.closest('[data-track-keyup]'));

      var element = event.target.closest('[data-track-keyup]');
      var trackingOptions = JSON.parse(element.dataset.trackKeyup);

      // If this is from the Filter Input, we want to track the Enter Key differently
      if(trackingOptions.event_action === "Filter Input Change" && event.keyCode === 13) {
        trackingOptions.event_label = "Enter Pressed";
      }

      // DEBUG:
      // console.log('Tracking props:', trackingOptions);

      // Save the tracking event.
      this._trackEvent(trackingOptions);
    }
  }

  _trackEvent = (trackingVars) => {
    // DEBUG:
    // console.log('Tracking event properties:', trackingVars);

    if (window.gtag) {
      if(trackingVars.hasOwnProperty('event_action')) {
        window.gtag('event', trackingVars.event_action, trackingVars);
      } else {
        window.gtag('event', 'click', trackingVars);
      }

      // DEBUG:
      // console.log('Event tracked!', trackingVars);
      // console.log('window.gtag:', window.gtag);
    }
  }

  componentDidMount() {
    // Track pageload.
    count = 0;
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        count++;
        this._trackPageLoad();
      }, 100);
    }
    // Track all click events on the document.
    document.addEventListener('click', this._trackClickEvents);
    document.addEventListener('keyup', this._trackKeyEvents);
  }

  componentWillUnmount() {
    // Remove event listeners when components unmount.
    document.removeEventListener('click', this._trackClickEvents);
    document.removeEventListener('keyup', this._trackKeyEvents);
  }

  render() {
    return null;
  }
}
