import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withCookies } from 'react-cookie';
import { Analytics } from '../../components/';

const bannerCountries = ['GB', 'SE', 'AT', 'BE', 'BG', 'HR',
                         'CZ', 'DK', 'FI', 'FR', 'DE', 'GR',
                         'HU', 'IE', 'IT', 'LU', 'NL', 'PL',
                         'PT', 'RO', 'SK', 'SI', 'ES', 'CH',
                         'NO'];

class SEO extends Component {
  constructor(props) {
    super(props);
    const { cookies } = this.props;

    let countryCode = "US";
    let geoloc = cookies.get("geoloc");
    if(geoloc) {
      let geoArr = geoloc.split(",");
      countryCode = geoArr[0].split("=");
      countryCode = countryCode[1];
    }

    let analyticsEnabled = true;
    let sq = cookies.get("sq");

    // If the SQ cookie is not properly set and the country code is included in
    // the banner countries list, disable analytics
    if(bannerCountries.includes(countryCode) && (sq !== '3' && sq !== '1')) {
      analyticsEnabled = false;
    }

    let analyticsLoaded = false;

    if(typeof window !== "undefined") {
      if(typeof window.dataLayer !== "undefined") {
        analyticsLoaded = true;
      } else {
        let host = window.location.host;
        window.s_account = host === 'www.nikesb.com' ? 'nikebrandprod' : 'nikebranddev';
      }
      // if(window.location.host === "nikesb.test:8080") {
      //   analyticsEnabled = false;
      // }
    }

    this.state = {
      settingsOpen: false ,
      countryCode: countryCode,
      analyticsLoaded: analyticsLoaded,
      analyticsEnabled: analyticsEnabled
    };
  }

  componentDidMount() {
    if(window !== "undefined") {
      window.gaLoaded = () => this.setState({ analyticsLoaded: true });
    }
  }

  componentDidUpdate() {
    let {cookies} = this.props;
    let sq = cookies.get("sq");

    if(typeof window !== "undefined" && window.location.host === "nikesb.test:8080") {
      return false;
    }

    if((sq === '3' || sq === '1') && !this.state.analyticsEnabled) {
      this.setState({analyticsEnabled: true});
    }
  }

  render() {
    let { match, title, seo, globalSeo, analyticsTitle, analyticsSection, defaultDesc } = this.props;

    // If the page title or meta title is homepage, set to the global SEO title
    let pageTitle = title !== "Homepage" ? `${title} - ` : "";
    let metaTitle = seo.title ? seo.title : title;
    if(metaTitle === "Homepage") {
      metaTitle = globalSeo && globalSeo.name ? globalSeo.name : '';
    }

    let image = seo && seo.image ? seo.image : globalSeo.image;

    // Set to default description or global, then entry-specific if it's set
    let description = defaultDesc ? defaultDesc : globalSeo.description;
    description = seo && seo.description ? seo.description : description;

    let canonicalUrl = "";
    let rootUrl = "";
    if (typeof window !== "undefined") {
      canonicalUrl = `${window.location.origin}${match.url}`;
      rootUrl = `${window.location.origin}`;
    } else {
      canonicalUrl = `${process.env.BASE_URL}${match.url}`;
      rootUrl = `${process.env.BASE_URL}`;
    }

    // Set the appropriate analytics ID based on the environment
    let gaId = rootUrl === 'https://www.nikesb.com' ? 'G-NWX5YSPB90' : (rootUrl === 'https://sandbox.nikesb.com' ? 'G-42BL9094HV' : 'G-F46P6Z7GDL');

    let lytics = rootUrl === 'https://www.nikesb.com' && this.state.analyticsEnabled;

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}{globalSeo.name}</title>
          <link rel="canonical" href={canonicalUrl} />
          <meta name="title" content={metaTitle} />
          <meta name="keywords" content={seo && seo.keywords ? seo.keywords : ''} />
          <meta name="description" content={description} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={`${globalSeo.url}${image}`} />
          <meta property="twitter:title" content={metaTitle} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:image" content={`${globalSeo.url}${image}`} />
          {!this.state.analyticsLoaded && this.state.analyticsEnabled && ( 
            <script async onload="gaLoaded()" src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}></script>
          )}
          {!this.state.analyticsLoaded && this.state.analyticsEnabled && ( 
            // <!-- Global site tag (gtag.js) - Google Analytics --> 
            <script>
              {`window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments)}
              gtag('js', new Date());
              gtag('config', '${gaId}');`}
            </script>
          )}
          {!this.state.analyticsLoaded && this.state.analyticsEnabled && ( 
            // <!-- Google Tag Manager -->
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NFNRT8J');`}</script>
            // <!-- End Google Tag Manager -->
          )}
          {lytics && (
            <script type="text/javascript">
              {`window.jstag=function(){function t(t){return function(){return t.apply(this,arguments),this}}function n(){var n=["ready"].concat(c.call(arguments));return t(function(){n.push(c.call(arguments)),this._q.push(n)})}var i={_q:[],_c:{},ts:(new Date).getTime(),ver:"2.0.0"},c=Array.prototype.slice;return i.init=function(t){return i._c=t,t.synchronous||i.loadtagmgr(t),this},i.loadtagmgr=function(t){var n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=t.url+"/api/tag/"+t.cid+"/lio.js";var i=document.getElementsByTagName("script")[0];i.parentNode.insertBefore(n,i)},i.ready=n(),i.send=n("send"),i.mock=n("mock"),i.identify=n("identify"),i.pageView=n("pageView"),i.bind=t(function(t){i._q.push([t,c.call(arguments,1)])}),i.block=t(function(){i._c.blockload=!0}),i.unblock=t(function(){i._c.blockload=!1}),i}(),window.jstag.init({cid:"40877f87c14d820b15f019db1d492ce1", url:"//c.lytics.io", min:true, loadid:false});`}
            </script>
          )}
        </Helmet>
        {this.state.analyticsLoaded && this.state.analyticsEnabled && (
          <Analytics
            countryCode={this.state.countryCode}
            title={analyticsTitle}
            section={analyticsSection}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withCookies(SEO);
