import React, { Component } from 'react';
import './ImageWithCopy.scss';

export default class ImageWithCopy extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false
    }
  }
  changeModalClosed() {
    this.setState({modalOpen: false});
  }
  changeModalOpen() {
    this.setState({modalOpen: true});
  }
  render() {
    let { borderColor, textColor, copy, image, imageCopyAlignment } = this.props;
    const assetOrder = imageCopyAlignment ? imageCopyAlignment : '';
    const fontSize = image.caption && image.caption.length > 60 ? 'fs-small' : 'fs-large';
    let modalClass = this.state.modalOpen ? 'img-copy__open' : '';

    const textStyles = {};
    if (textColor && textColor.startsWith('#')) {
      textStyles.color = textColor;
    }

    const borderStyles = {};
    if (borderColor && borderColor.startsWith('#')) {
      borderStyles.borderColor = borderColor;
    }

    const cid = borderColor ? `cid-${borderColor.replace('#', '')}` : '';

    return (
      // Check flex css change order prop
      <div className={`img-copy ctnr ${assetOrder} ${cid}`}>
        {borderColor && borderColor.startsWith('#') && (
          <style>{`
            .img-copy.${cid} .img-copy__copy p {
              border-color: ${borderColor};
            }
            .img-copy.${cid} .img-copy__copy:before,
            .img-copy.${cid} .img-copy__copy:after {
              background-color: ${borderColor};
            }
          `}</style>
        )}
        <div className={`img-copy__group ${assetOrder}`}>
          {image.caption &&
            <div class="img-copy__info">
              <img onClick={this.changeModalOpen.bind(this)} src="/icons/misc/NikeSB_Caption-Icon_M.png" alt="information" />
            </div>}
          {image.caption &&
            <div className={`img-copy__caption-group ${modalClass}`}>
              <img onClick={this.changeModalClosed.bind(this)} src="/icons/misc/NikeSB_CaptionClose-Icon_M.png" alt="close" />
              <p className={`img-copy__caption ${fontSize}`}>{image.caption}</p>
            </div>}
          <div className="img-copy__img"
            dangerouslySetInnerHTML={{__html: image.asset}}>
          </div>
        </div>
        <div className={`img-copy__copy img-copy__copy--${borderColor}`} style={borderStyles}>
          <div className={`copy copy--intro copy-url ${textColor}`} style={textStyles} dangerouslySetInnerHTML={{ __html: copy }} />
        </div>
      </div>
    );
  }
}