import React from 'react'
import "./FifteenToTwenty.scss"

const FifteenToTwenty = ({data, links, randomizedImageCounts}) => {
  let tShirt = Math.ceil(Math.random() * randomizedImageCounts['t-shirt']);
  let tShirtFilename = 'tshirt' + tShirt.toString().padStart(3, '0') + '.png'
  const [tShirtFile] = React.useState(tShirtFilename)

  let tShirtStack = Math.ceil(Math.random() * randomizedImageCounts['t-shirt-stack']);
  let tShirtStackFilename = 'tShirtStack' + tShirtStack.toString().padStart(3, '0') + '.png'
  const [tShirtStackFile] = React.useState(tShirtStackFilename)

  let sandyShorts = Math.ceil(Math.random() * randomizedImageCounts['sandy-shorts']);
  let sandyShortsFilename = 'sandyShorts' + sandyShorts.toString().padStart(3, '0') + '.png'
  const [sandyShortsFile] = React.useState(sandyShortsFilename)

  let cap = Math.ceil(Math.random() * randomizedImageCounts['cap']);
  let capFilename = 'cap' + cap.toString().padStart(3, '0') + '.png'
  const [capFile] = React.useState(capFilename)

  let mask = Math.ceil(Math.random() * randomizedImageCounts['mask']);
  let maskFilename = 'mask' + mask.toString().padStart(3, '0') + '.png'
  const [maskFile] = React.useState(maskFilename)

  let chickenHead = Math.ceil(Math.random() * randomizedImageCounts['chicken-head']);
  let chickenHeadFilename = 'chickenHead' + chickenHead.toString().padStart(3, '0') + '.png'
  const [chickenHeadFile] = React.useState(chickenHeadFilename)

  return (
    <React.Fragment>
      {
        (links.tShirt.url !== "") && (
          <div className={`sandysplace__${data.tShirt.id}`}>
            <a href={links.tShirt.url} {...(links.tShirt.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
            })}>
              <img
                src={'/icons/sandysplace/t-shirt/' + tShirtFile}
                alt={data.tShirt.image.alt} 
              /> 
            </a>
          </div>
        )
      }
      {
        (links.tShirtStack.url !== "") && (
          <div className={`sandysplace__${data.tShirtStack.id}`}>
            <a href={links.tShirtStack.url} {...(links.tShirtStack.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
            })}>
              <img
                src={'/icons/sandysplace/t-shirt-stack/' + tShirtStackFile}
                alt={data.tShirtStack.image.alt} 
              /> 
            </a>
          </div>
        )
      }
      {
        (links.sandyShorts.url !== "") && (
          <div className={`sandysplace__${data.sandyShorts.id}`}>
            <a href={links.sandyShorts.url} {...(links.sandyShorts.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
            })}>
              <img
                src={'/icons/sandysplace/sandy-shorts/' + sandyShortsFile}
                alt={data.sandyShorts.image.alt} 
              /> 
            </a>
          </div>
        )
      } 
      {
        (links.cap.url !== "") && (
          <div className={`sandysplace__${data.cap.id}`}>
            <a href={links.cap.url} {...(links.cap.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
            })}>
              <img
                src={'/icons/sandysplace/cap/' + capFile}
                alt={data.cap.image.alt} 
              /> 
            </a>
          </div>
        )
      } 
      {
        (links.mask.url !== "") && (
          <div className={`sandysplace__${data.mask.id}`}>
            <a href={links.mask.url} {...(links.mask.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
            })}>
              <img
                src={'/icons/sandysplace/mask/' + maskFile}
                alt={data.mask.image.alt} 
              /> 
            </a>
          </div>
        )
      } 
      {
        (links.chickenHead.url !== "") && (
          <div className={`sandysplace__${data.chickenHead.id}`}>
            <a href={links.chickenHead.url} {...(links.chickenHead.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
            })}>
              <img
                src={'/icons/sandysplace/chicken-head/' + chickenHeadFile}
                alt={data.chickenHead.image.alt} 
              /> 
            </a>
          </div>
        )
      } 
      
    </React.Fragment>
  )
}

export default FifteenToTwenty
