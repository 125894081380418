import React, { Component } from 'react';

export default class YoutubeIcon extends Component {
  render() {
    return (
      <a
        href="https://www.youtube.com/c/nikesb"
        target="blank"
      >
        <svg aria-hidden="true" className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 23.9995C18.6274 23.9995 24 18.6269 24 11.9995C24 5.37209 18.6274 -0.000488281 12 -0.000488281C5.37258 -0.000488281 0 5.37209 0 11.9995C0 18.6269 5.37258 23.9995 12 23.9995ZM12.0002 6.81079C19.3652 6.81079 19.5082 6.89779 19.5002 11.9998C19.5082 17.1018 19.3652 17.1898 12.0002 17.1898C4.63525 17.1898 4.49225 17.1018 4.50025 11.9998C4.49225 6.89879 4.63424 6.81079 12.0002 6.81079ZM10.5762 14.3238L14.2932 11.9998L10.5762 9.67578V14.3238Z" fill="#F23A0B"/>
        </svg>
      </a>
    );
  }
}