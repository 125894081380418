import React, { Component } from 'react';
import './ArticleImageCarousel.scss';

import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// expected props:
// carouselImages: object[]

export default class ArticleImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      lightboxOpen: false,
      multiImageCarousel: false
    };
  }

  componentDidMount() {
    if (this.props.carouselImages.length > 1) {
      this.setState({ multiImageCarousel: true });
    }

    this.targetElement = document.querySelector('.article-image-carousel');
  }

  componentDidUpdate() {
    this.slider.slickGoTo(this.state.currentIndex);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  handleCarouselClick = (e) => {
    if ($(e.target).parents().hasClass('slick-current')) {
      this.toggleLightbox();
    } else {
      let adjacentSlideIndex = e.target.closest('.slick-slide').dataset.index;
      this.panCarousel(parseInt(adjacentSlideIndex, 10));
    }
  }

  toggleLightbox = () => {
    disableBodyScroll(this.targetElement);
    this.setState({
      lightboxOpen: !this.state.lightboxOpen
    });
  }

  panCarousel = (index) => {
    if (index > this.state.currentIndex) {
      this.slider.slickNext();
    } else if (index < this.state.currentIndex) {
      this.slider.slickPrev();
    }
  }

  handleKeyDown = (e) => {
    if(!this.slider || this.state.lightboxOpen) return;
    if (e.key === 'ArrowRight') {
      this.slider.slickNext();
    } else if (e.key === 'ArrowLeft') {
      this.slider.slickPrev();
    } else if (e.key === 'Enter') {
      this.toggleLightbox();
    }
  }

  render() {
    let settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      variableWidth: true,
      speed: 500,
      dots: true,
      customPaging: i => <button className="slick-dots__target"/>,
      appendDots: dots => <ul>{dots}</ul>,
      beforeChange: (current, next) => this.setState({ currentIndex: next })
    };
    let { carouselImages } = this.props;
    let { currentIndex } = this.state;


    let carouselPadding = 100;
    if (typeof window !== 'undefined') {
      carouselPadding = window.innerWidth < 768 ? 20 : 100;
    }

    return (
      <div className="article-image-carousel">
        <div className="ctnr" tabIndex={0} onKeyDown={this.handleKeyDown}>
          <Slider ref={slider => (this.slider = slider)} {...settings} >
            {carouselImages.length > 1 && carouselImages.map((image, i) => (
              <div
                className="pony"
                key={i}
                tabIndex={1}
              >
                <div
                  className="image"
                  dangerouslySetInnerHTML={{__html: image.asset}}
                  onClick={(e) => this.handleCarouselClick(e)}
                />
                {image.caption &&
                  <div class="article-image-carousel__info">
                    <img src="/icons/misc/NikeSB_Caption-Icon_M.png" alt="information" />
                </div>}
              </div>
            ))}
            {carouselImages.asset && (
              <div className="pony">
                <div
                  className="image"
                  dangerouslySetInnerHTML={{__html: carouselImages.asset}}
                  onClick={(e) => this.handleCarouselClick(e)}
                />
              </div>
            )}
          </Slider>
          {/* multi-image lightbox */}
          {
            this.state.lightboxOpen && this.state.multiImageCarousel && (
              <Lightbox
                mainSrc={carouselImages[currentIndex].background.dataOriginal}
                nextSrc={carouselImages[(currentIndex + 1) % carouselImages.length].background.dataOriginal}
                prevSrc={carouselImages[(currentIndex + carouselImages.length - 1) % carouselImages.length].background.dataOriginal}
                onCloseRequest={() => this.setState({ lightboxOpen: false }, enableBodyScroll(this.targetElement))}
                onMovePrevRequest={() =>
                  this.setState({
                    currentIndex: (currentIndex + carouselImages.length - 1) % carouselImages.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    currentIndex: (currentIndex + 1) % carouselImages.length,
                  })
                }
                imagePadding={carouselPadding}
                enableZoom={false}
                imageCaption={carouselImages[currentIndex].caption}
              />
            )
          }
          {/* single-image lightbox */}
          {
            this.state.lightboxOpen && !this.state.multiImageCarousel && (
              <Lightbox
                imagePadding={0}
                mainSrc={carouselImages.background.dataOriginal}
                onCloseRequest={() => this.setState({ lightboxOpen: false }, enableBodyScroll(this.targetElement))}
                enableZoom={false}
              />
            )
          }
        </div>
        <div className="article-image-carousel__cta-ctnr">
          <button className="cta-span link" onClick={() => this.toggleLightbox(currentIndex)}>
            <span className="arrow" />
            <span className="link__text">View Gallery</span>
          </button>
        </div>
      </div>
    );
  }
}
