import React, { Component } from 'react';
import './ArchiveDetailQuote.scss';

// expected props:
// textColor: string
// borderColor: string
// copy: string

export default class ArchiveDetailQuote extends Component {
  render() {
    let { textColor, borderColor, copy } = this.props;

    return (
      <div className="ctnr">
        <div
          className={`archive-detail-quote headline--quote headline--quote-${borderColor}`}
        >
          <div className={`archive-detail-quote__before bg--${borderColor}`} />
          <p className={`headline headline--lg ${textColor}`}>
            { copy }
          </p>
          <div className={`archive-detail-quote__after bg--${borderColor}`} />
        </div>
      </div>
    );
  }
}
