import React, { Component } from 'react';
import './P2Carousel.scss';
import ReactPlayer from 'react-player';
import AriaModal from 'react-aria-modal';
import VideoModal from '../VideoModal/VideoModal';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

let dragging = false;

class P2Asset extends Component {
  constructor(props) {
    super(props);
    this.state = {isOverlayOpen: false, hover: false, contentRect: {} };
  }

  openOverlay = () => {
    this.setState({ isOverlayOpen: true });
  };
  closeOverlay = () => {
    this.setState({ isOverlayOpen: false, hover: false });
  };
  toggleOverlay = () => {
    if(!dragging) {
      this.state.isOverlayOpen ? this.closeOverlay() : this.openOverlay();
    }
  };

  render() {
    let { asset } = this.props;

    return (
      <React.Fragment>
        {asset && asset.type === 'image' &&
          <div className="p2__card-img" dangerouslySetInnerHTML={{__html: asset.asset}}></div>
        }
        {asset && asset.type === 'video' &&
          <React.Fragment>
            <div
              className="p2__card-img"
              onMouseEnter={() => {
                this.setState({ hover: true });
              }}
              onMouseLeave={() => this.setState({ hover: false })}
              onClick={this.toggleOverlay}
            >
              {asset.posterImg &&
                <div className="p2__card-poster">
                  <div className="p2__card-poster-img" dangerouslySetInnerHTML={{__html: asset.posterImg}}></div>
                  <img src="/icons/misc/VideoPlay.svg" alt="Play Video" className="p2__card-poster-icon" />
                </div>
              }
              <div className="p2__card-tap-target" />
              {asset.videoUrl && <ReactPlayer
                width={'100%'}
                height={'100%'}
                url={asset.videoUrl}
                playing={!this.state.isOverlayOpen && this.state.hover}
                loop={true}
                playsinline={true}
                muted={true}
                className={'react-player'}
                config={{
                  youtube: {
                    playerVars: { rel: 0, controls: 0 },
                    embedOptions: {
                      host: 'https://www.youtube-nocookie.com'
                    }
                  }
                }}
              /> }
            </div>

            {this.state.isOverlayOpen && (
              <AriaModal
                titleText="Nike SB Mobile Navigation"
                initialFocus="#close"
                getApplicationNode={() => document.getElementById('root')}
                underlayStyle={{ background: 'rgba(0, 0, 0, 0.8)' }}
                underlayClass=""
                dialogStyle={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  textAlign: 'unset',
                  opacity: 1
                }}
                dialogClass="mobile-nav--dialog"
              >
                <VideoModal
                  url={asset.videoUrl}
                  contentRect={this.state.contentRect}
                />
                <img
                  id="close"
                  className="btn--close icon"
                  src="/icons/nav/Video_CloseIcon.svg"
                  onClick={() => this.closeOverlay()}
                  alt="close navigation button"
                />
              </AriaModal>
            )}
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

class P2Card extends Component {
  handleClick = (e) => {
    if(dragging) {
      e.preventDefault()
    }
  };

  // These cards shouldn't need to rerender - this prevents the video cards from flickering on resize
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    let { headline, textColor, highlightColor, asset, uri, target, linkType } = this.props;

    let trackClickOptions = {
      module: {
        event_action: "Card Click",
        event_category: "P2 Carousel",
        event_label: `Clicked carousel card: ${headline}`,
      }
    }

    const ConditionalLink = ({children, to, tracking, clickable}) => {
      if(to !== null && linkType === 'external') {
        return <a data-track-click={tracking} rel="noopener noreferrer" target="_blank" onClickCapture={this.handleClick} href={to} className='p2__card p2__card--has-clickthrough'>{children}</a>;
      } else if (to !== null) {
        return <Link data-track-click={tracking} target={target} onClickCapture={this.handleClick} to={to} className='p2__card p2__card--has-clickthrough'>{children}</Link>;
      }
      return <div data-track-click={tracking} className={`p2__card ${clickable ? 'p2__card--has-clickthrough' : ''}`}>{children}</div>;
    };

    return (
      <ConditionalLink to={uri} tracking={JSON.stringify(trackClickOptions.module)} clickable={uri !== null || asset.type === 'video'}>
        <div className="p2__card-headline">
          <h2
            className={`${textColor} headline headline--sm headline--highlight headline--highlight-${highlightColor}`}
          >
            { headline }
          </h2>
        </div>
        <P2Asset asset={asset} />
      </ConditionalLink>
    );
  }
}

export default class P2Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = { playing: false };
  }

  onScroll = () => {
    if(typeof window !== "undefined") {
      if(document.getElementById('p2-slider').getBoundingClientRect().top < 100) {
        let slides = document.getElementsByClassName('p2__card');
        for (let i = 0; i < slides.length; i++) {
          slides.item(i).classList.add('do-animation');
        }

        window.removeEventListener('scroll', this.onScroll, false);
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.preventTouch, {passive: false});
  }

  touchStart(e){
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e){
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if(Math.abs(this.clientX) > minValue){
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }

  render() {
    let {
      headline,
      backgroundColor,
      cards,
    } = this.props;

    let settings = {
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false,
      infinite: false,
      slidesToShow: 5,
      variableWidth: true,
      dots: true,
      appendDots: dots => <ul>{dots}</ul>,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };

    return (
      <div id="p2-slider" className={`p2 p2--${backgroundColor}`}>
        <div className="p2__content">
          <h2 className="eyebrow p2__headline">{ headline }</h2>
          <div className="p2__cards">
            <Slider {...settings} ref={slider => this.slider = slider}>
              {cards.map((component, i) => {
                const key = 'carouselCardSlider:' + i;
                return (
                    <P2Card
                      key={key}
                      {...component}
                    ></P2Card>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
