import React from 'react';

import { asyncComponent } from '../after';
import NotFound from './pages/NotFound/NotFound';

export default [
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Home/Home'),
      Placeholder
    })
  },
  {
    path: '/article',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Article/Article'),
      Placeholder
    })
  },
  {
    path: '/articles/:articleId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Article/Article')
    })
  },
  {
    path: '/team',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Team/Team'),
      Placeholder
    })
  },
  {
    path: '/team/:athleteId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Team/Athlete')
    })
  },
  {
    path: '/skateshops/:shopId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/SkateShops/SkateShop')
    })
  },
  {
    path: '/the-vault',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/ArchiveGrid/ArchiveGrid'),
      Placeholder
    })
  },
  {
    path: '/the-vault/:boxId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/ArchiveBoxWall/ArchiveBoxWall'),
      Placeholder
    })
  },
  {
    path: '/the-vault/:boxId/:shoeId',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/ArchiveDetail/ArchiveDetail'),
      Placeholder
    })
  },
  {
    path: '/shops',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/SkateShops/SkateShops'),
      Placeholder
    })
  },
  {
    path: '/orangelabel',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrangeLabel/OrangeLabel'),
      Placeholder
    })
  },
  {
    path: '/reuse-a-shoe',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/ReuseAShoe/ReuseAShoe'),
      Placeholder
    })
  },
  {
    path: '/:slug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomPages/CustomPages'),
      Placeholder
    })
  },
  /*
  {
    path: '/styleguide',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Styleguide/Styleguide'),
      Placeholder
    })
  },
  {
    path: '/module-library',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/ModuleLibrary/ModuleLibrary'),
      Placeholder
    })
  },
  */
  { path: '**', component: NotFound }
];

// DRYed out from boilerplate
function Placeholder() {
  return <div>...LOADING...</div>;
}
