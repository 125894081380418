import React from 'react'
import "./TwelveToFourteen.scss"

const TwelveToFourteen = ({ skateDecks }) => {
  return (
    <React.Fragment>
    <div className={`sandysplace__skateboard sandysplace__s1-skateboard`}>
      { skateDecks[0] !== undefined && (<img src="/icons/sandysplace/Skateboard-Left.png" alt="" style={{position: "absolute", top: "0px", left: "-3px", zIndex: "2", height: "97%", width: "112%", pointerEvents: "none"}} />) }
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.7 506.9">
        <clipPath id="image1">
          <path id="Skateboard-Deck-Left" d="M38.5 0c47.3 0 72.4 37.3 80 93.8 4.8 35.7 2.9 76.9 4.2 124.7 2.2 84.2 2.1 175.5 2.1 180.4 0 21.3-4.4 61.5-22.6 80.8-11.5 12.1-24.3 27.2-42.3 27.2-15.5 0-38.2-2.8-48.6-18.8-8.9-13.8-6.6-38.1-7.5-69.9C.4 299.3 0 87.5 0 62 0 9.6 15.2 0 38.5 0z" fill="transparent"/>
        </clipPath>
        {
          skateDecks[0] !== undefined && (
            <a href={skateDecks[0].url}>
              <image x="-100%" y="0" clipPath="url(#image1)" height="100%" href={skateDecks[0].deckImage.dataOriginal} />
            </a>
          )
        }
      </svg>
    </div>
    <div className={`sandysplace__skateboard sandysplace__s2-skateboard`}>
      { skateDecks[1] !== undefined && (<img src="/icons/sandysplace/Skateboard-Center.png" alt="" style={{position: "absolute", top: "-2px", left: "-3px", zIndex: "2", height: "97%", width: "116%", pointerEvents: "none"}} />) }
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.9 493.1">
        <clipPath id="image2">
          <path id="Skateboard-Deck-Center" d="M32.7 0c31.4 0 71.9 41.3 80 90.4 5.8 35.6 2.9 76.9 4.2 124.7 2.2 84.2 2.1 175.5 2.1 180.4 0 21.3-3.1 52.3-21.3 71.6-11.5 12.1-25.5 26-43.5 26-28.9 0-58-5.2-53.6-73.7C8.4 295.5 0 86.7 0 61.1 0 8.8 9.3 0 32.7 0z" fill="transparent"/>
        </clipPath>
        {
          skateDecks[1] !== undefined && (
            <a href={skateDecks[1].url}>
              <image x="-100%" y="0" clipPath="url(#image2)" height="100%" href={skateDecks[1].deckImage.dataOriginal} />
            </a>
          )
        }
      </svg>
    </div>
    <div className={`sandysplace__skateboard sandysplace__s3-skateboard`}>
      { skateDecks[2] !== undefined && (<img src="/icons/sandysplace/Skateboard-Right.png" alt="" style={{position: "absolute", top: "-8px", left: "-5px", zIndex: "2", height: "100%", width: "125%", pointerEvents: "none"}} />) }
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.9 482.7">
        <clipPath id="image3">
          <path id="Skateboard-Deck-Right" d="M31.3 0c28.4 0 75.1 40.6 75.1 90.4 0 32.9 4.2 73.9 5.4 121.8 2.2 84.2 2.1 175.5 2.1 180.4 0 21.3-4.7 46.5-22.9 65.7-11.5 12.1-21 24.3-45 24.3-40.9 0-45-59.4-45-88.3C.8 365.4 0 82 0 56.5S7.9 0 31.3 0z" fill="transparent"/>
        </clipPath>
        {
          skateDecks[2] !== undefined && (
            <a href={skateDecks[2].url}>
              <image x="-100%" y="0" clipPath="url(#image3)" height="100%" href={skateDecks[2].deckImage.dataOriginal} />
            </a>
          )
        }
      </svg>
    </div>
    </React.Fragment>
  )
}

export default TwelveToFourteen
