import React, { Component } from 'react';

export default class AthleteProIcon extends Component {
  render() {
    return (
    <svg viewBox="0 0 90 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 16C32 24.8364 24.8367 32 15.9998 32C7.16332 32 0 24.8364 0 16C0 7.16361 7.16332 0 15.9998 0C24.8367 0 32 7.16361 32 16Z" fill="#FFF617"/>
    <path d="M31 16C31 24.2841 24.2844 31 15.9998 31C7.71563 31 1 24.2841 1 16C1 7.71588 7.71563 1 15.9998 1C24.2844 1 31 7.71589 31 16Z" fill="#FFF617" stroke="#F23A0B" stroke-width="2"/>
    <path d="M20.317 23.9757C22.1822 24.7538 25.1047 25.645 26.3273 23.7093C27.0115 22.6442 26.5764 20.9642 25.5718 19.439C24.9395 18.4656 24.1098 17.5748 23.2911 16.6836C23.7478 17.4108 25.0323 19.8587 23.3427 21.0673C22.6901 21.5486 21.5399 21.5894 20.1095 21.2206L3 16.7551L20.317 23.9757Z" fill="#F23A0B"/>
    <path d="M20.0193 8.87102C20.0193 11.0068 19.4762 12.7385 18.806 12.7385C18.1362 12.7385 17.5928 11.0068 17.5928 8.87102C17.5928 6.73526 18.1362 5.00391 18.806 5.00391C19.4762 5.00391 20.0193 6.73526 20.0193 8.87102Z" fill="#F23A0B"/>
    <path d="M13.1941 8.87102C13.1941 11.0068 12.651 12.7385 11.9808 12.7385C11.311 12.7385 10.7676 11.0068 10.7676 8.87102C10.7676 6.73526 11.311 5.00391 11.9808 5.00391C12.651 5.00391 13.1941 6.73526 13.1941 8.87102Z" fill="#F23A0B"/>
    <path d="M56.18 13.47C56.18 14.73 55.865 15.675 55.265 16.335C54.455 17.265 53.12 17.685 51.38 17.7C50.84 17.715 46.22 17.7 46.22 17.7V21H42.185V9.225H51.32C52.94 9.225 54.17 9.555 54.935 10.23C55.73 10.86 56.18 11.925 56.18 13.47ZM52.13 13.53C52.13 13.14 52.055 12.81 51.92 12.63C51.665 12.255 51.185 12.075 50.405 12.075H46.19V14.955H50.375C51.215 14.955 51.71 14.76 51.95 14.385C52.07 14.175 52.13 13.905 52.13 13.53ZM71.7356 12.81C71.7356 13.725 71.5106 14.505 71.1356 15.03C70.7156 15.6 70.0706 16.08 69.2756 16.2L72.8306 21H68.3606L65.4356 16.845H61.7906V21H57.7856V9.225H67.1606C69.0206 9.225 70.2356 9.675 70.9406 10.425C71.4806 10.98 71.7356 11.745 71.7356 12.81ZM67.8356 13.185C67.8356 12.96 67.8056 12.72 67.7006 12.57C67.4906 12.18 67.0706 12.075 66.4406 12.075H61.8056V14.22H66.5156C67.1606 14.22 67.5506 14.1 67.7006 13.8C67.8056 13.62 67.8356 13.38 67.8356 13.185ZM81.1435 8.955C86.4085 8.955 89.0485 11.22 89.0485 15.03C89.0485 18.78 86.7085 21.18 81.1435 21.18C76.0885 21.18 73.2235 19.11 73.2235 15.03C73.2235 11.415 75.9535 8.955 81.1435 8.955ZM81.1435 18.165C84.1135 18.165 84.9685 16.875 84.9685 15.075C84.9685 13.38 84.2635 12.015 81.1285 12.015C78.2635 12.015 77.3335 13.23 77.3335 15.075C77.3335 16.95 78.1885 18.165 81.1435 18.165Z" fill="#F23A0B"/>
    </svg>

    );
  }
}