import React, { Component } from 'react';
import './Grid.scss';
import sortAndPack from './sortAndPack';
import flatten from 'lodash.flatten';
// import posed, { PoseGroup } from 'react-pose';
// import { CSSTransition } from 'react-transition-group';
import {
  ArticleFeatured,
  ArticleCard,
  ArticleTilt,
  MaskedHeadline,
  VideoMaybeBorder,
  SquareOnSquare,
  ImageStandalone,
  InstaEmoji,
  InstaCheckMe,
  InstaBorder,
  ShoeOverImage,
  LateralShoe,
  ShoeBorder,
  QuoteSidebar,
  HomepageQuoteMarks
} from '../../components/';
import $ from 'jquery'
// var uniqid = require('uniqid');

let gridEventAction = '';

class Grid extends Component {
  constructor(props) {
    super(props);

    let { isHomepage } = props;

    this.state = {
      numberOfGridRowsShowing: isHomepage ? 2 : 1,
      isLoading: false,
      newModules: false,
      loadMoreCount: 0
    };
  }

  componentDidMount() {
    if (this.props.isHomepage) {
      this.addGridRows(4);
    }
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    if(typeof window !== "undefined") {
      $(".col, .article-featured").each(function() {
        if (!$(this).hasClass("show") && ($(this).offset().top < ($(window).scrollTop() + ($(window).height() - 200)))) {
          $(this).addClass("show");
        }
      });
    }
  };

  componentDidUpdate(newProps) {
    width = this.props.width;

    if (newProps.gridItems !== this.props.gridItems) {
      this.setState({ newModules: true, numberOfGridRowsShowing: 1 });
      setTimeout(
        () => this.setState({ newModules: false, numberOfGridRowsShowing: 2 }),
        1000
      );
    }
  }

  addGridRows = (rowsToAdd, loading = false) => {
    this.setState({
      isLoading: loading,
      loadMoreCount: this.state.loadMoreCount + 1
    });
    this.spinWheels({
      numberOfGridRowsShowing: this.state.numberOfGridRowsShowing + rowsToAdd
    });
  };

  spinWheels = (state = {}) => {
    setTimeout(() => {
      this.setState({
        isLoading: false, ...state,
        loadMoreCount: this.state.loadMoreCount + 1
      });
      if(typeof window !== "undefined") {
        $(".col, .article-featured").each(function() {
          if ($(this).offset().top < ($(window).scrollTop() + ($(window).height() - 200))) {
            $(this).addClass("show");
          }
        });
      }
    }, 1000);
  };

  render() {
    let { toggleLoad = x => x, showLoad, width, gridItems, eventAction, stickers, filterFunction = x => x } = this.props;
    let { numberOfGridRowsShowing, isLoading, newModules } = this.state;

    gridEventAction = eventAction;

    modCount = 0;
    rowCount = 0;

    let filteredModules = null;

    if(showLoad) {
      setTimeout(() => {
        filteredModules = gridItems.filter(filterFunction);
        toggleLoad();

        if(typeof window !== "undefined") {
          $(".col, .article-featured").each(function() {
            if ($(this).offset().top < ($(window).scrollTop() + ($(window).height() - 200))) {
              $(this).addClass("show");
            }
          });
        }
      }, 500);

      return <div style={{minHeight: '100vh'}}><Loading /></div>;
    } else {
      filteredModules = gridItems.filter(filterFunction);
    }

    let modules = sortAndPack(filteredModules, stickers);

    let showAll = modules.length <= numberOfGridRowsShowing;

    let visibleModules = showAll
      ? modules
      : modules.slice(0, numberOfGridRowsShowing);

    ////
    // FOR INDIVIDUAL MODULE TESTING
    /*
    return (
      <div className={`grid ctnr ${newModules ? 'reloading' : ''}`}>
        {gridItems
          .slice(5, 6)
          .map(module => renderAll([module, module, module, module], 1, width))}
      </div>
    );
    */
    ////

    if (width < 769) {
      // console.log('rendering mobile view: ', width);
      return (
        <React.Fragment>
          <div className="grid ctnr">
              {// flatten grid items for mobile
              flatten(visibleModules).map((module, index) => (
                  matchAPIModuleObjectToGridComponent(module, null, index)
              ))}
          </div>
          {!showAll && !isLoading ? (
            <LoadMore addGridRows={() => this.addGridRows(3, true)} loadMoreCount={ this.state.loadMoreCount } />
          ) : (
            false
          )}
        </React.Fragment>
      );
    } else {
      if (width > 1440) {
        width = 1440;
      }

      // console.log('rendering desktop view: ', width);

      return (
        <div className={`grid ctnr ${newModules ? 'reloading' : ''}`}>
          {visibleModules.map((gridItem, index) => {
            if (!Array.isArray(gridItem)) {
              // render Featured Article
              return (
                matchAPIModuleObjectToGridComponent(gridItem)
              );
            } else {
              let sortedByAspect = gridItem
                .sort((a, b) => {
                  if (a.aspect < b.aspect) {
                    return -1;
                  }
                  if (a.aspect > b.aspect) {
                    return 1;
                  }
                  return 0;
                })
                .reverse();

              // Determine whether to render conformation A or B
              let numberOfVerticalModules = gridItem.reduce(
                (acc, m) => (m.aspect === 'vertical' ? acc + 1 : acc),
                0
              );

              return (
                numberOfVerticalModules < 2
                    ? renderGridRowA(sortedByAspect, index, width)
                    : renderGridRowB(sortedByAspect, index, width)
              );
            }
          })}
          {!showAll && !isLoading ? (
            <LoadMore addGridRows={() => this.addGridRows(3, true)} loadMoreCount={ this.state.loadMoreCount } />
          ) : (
            false
          )}
          {isLoading ? <Loading /> : false}
        </div>
      );
    }
  }
}
export default Grid;

let rowCount = 0;

function renderGridRowA(modules, index, width) {
  rowCount++;
  if (modules.length === 4) {
    switch (index % 4) {
      case 0:
        return <GridRowA key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
      case 1:
        return <GridRowAReverseRetrograde key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
      case 2:
        return <GridRowAReverse key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
      case 3:
      default:
        return <GridRowARetrograde key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
    }
  } else {
    switch (index % 2) {
      case 0:
        return <GridRowA key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
      case 1:
      default:
        return <GridRowAReverse key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
    }
  }
}

function renderGridRowB(modules, index, width) {
  rowCount++;
  if (modules.length === 4) {
    switch (index % 4) {
      case 0:
        return <GridRowB key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
      case 1:
        return <GridRowBPhase key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
      case 2:
        return <GridRowBReverse key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
      case 3:
      default:
        return <GridRowBPhaseReverse key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
    }
  } else {
    switch (index % 2) {
      case 0:
        return <GridRowB key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
      case 1:
      default:
        return <GridRowBPhase key={`${rowCount}:${index}`} {...{ modules, index, width }} />;
    }
  }
}

////
// FOR INDIVIDUAL MODULE TESTING
/*
function renderAll(modules, index, width) {
  return (
    <React.Fragment>
      <GridRowA {...{ modules, index, width }} />
      <GridRowAReverse {...{ modules, index, width }} />
      <GridRowARetrograde {...{ modules, index, width }} />
      <GridRowAReverseRetrograde {...{ modules, index, width }} />
      <GridRowB {...{ modules, index, width }} />
      <GridRowBReverse {...{ modules, index, width }} />
      <GridRowBPhase {...{ modules, index, width }} />
      <GridRowBPhaseReverse {...{ modules, index, width }} />;
    </React.Fragment>
  );
}
*/
////

function GridRowA(props) {
  let { modules, index, width } = props;
  let [One, Three, Four, Two] = modules;

  return (
    <div
      className={`grid-row`}
      style={{ minHeight: width * 0.65 + 'px' }}
      key={index}
    >
      <div className="col col-6 row-2">
        {matchAPIModuleObjectToGridComponent(One, 'A1 left-vertical')}
      </div>

      <div className="col col-6 row-1">
        {matchAPIModuleObjectToGridComponent(Two, 'A2 top-right-horizontal')}
      </div>

      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Three, 'A3 center-bottom-square')}
      </div>

      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Four, 'A4 right-bottom-square')}
      </div>
    </div>
  );
}

function GridRowAReverse(props) {
  let { modules, index, width } = props;
  let [One, Three, Four, Two] = modules;

  return (
    <div
      className={`grid-row`}
      style={{ minHeight: width * 0.65 + 'px' }}
      key={index}
    >
      <div className="col col-6 row-1">
        {matchAPIModuleObjectToGridComponent(Two, 'A2 top-left-horizontal')}
      </div>
      <div className="col col-6 row-2">
        {matchAPIModuleObjectToGridComponent(One, 'A1 right-vertical')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Four, 'A4 left-bottom-square')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Three, 'A3 center-bottom-square')}
      </div>
    </div>
  );
}

function GridRowARetrograde(props) {
  let { modules, index, width } = props;
  let [One, Three, Four, Two] = modules;

  return (
    <div
      className={`grid-row`}
      style={{ minHeight: width * 0.65 + 'px' }}
      key={index}
    >
      <div className="col col-6 row-2">
        {matchAPIModuleObjectToGridComponent(One, 'A1 left-vertical')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Three, 'A3 center-top-square')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Four, 'A4 right-top-square')}
      </div>
      <div className="col col-6 row-1">
        {matchAPIModuleObjectToGridComponent(Two, 'A2 bottom-right-horizontal')}
      </div>
    </div>
  );
}

function GridRowAReverseRetrograde(props) {
  let { modules, index, width } = props;
  let [One, Three, Four, Two] = modules;

  return (
    <div
      className={`grid-row`}
      style={{ minHeight: width * 0.65 + 'px' }}
      key={index}
    >
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Four, 'A4 left-top-square')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Three, 'A3 center-top-square')}
      </div>
      <div className="col col-6 row-2">
        {matchAPIModuleObjectToGridComponent(One, 'A1 right-vertical')}
      </div>
      <div className="col col-6 row-1">
        {matchAPIModuleObjectToGridComponent(Two, 'A2 bottom-left-horizontal')}
      </div>
    </div>
  );
}

function GridRowB(props) {
  let { modules, index, width } = props;
  let [One, Three, Four, Two] = modules;

  return (
    <div
      className={`grid-row`}
      style={{ minHeight: width * 0.45 + 'px' }}
      key={index}
    >
      <div className="col col-4 row-2">
        {matchAPIModuleObjectToGridComponent(One, 'B1 left-vertical')}
      </div>

      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Two, 'B2 center-top-square')}
      </div>

      <div className="col col-5 row-2">
        {matchAPIModuleObjectToGridComponent(Three, 'B3 right-vertical')}
      </div>

      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Four, 'B4 center-bottom-square')}
      </div>
    </div>
  );
}

function GridRowBReverse(props) {
  let { modules, index, width } = props;
  let [One, Three, Four, Two] = modules;

  return (
    <div
      className={`grid-row`}
      style={{ minHeight: width * 0.45 + 'px' }}
      key={index}
    >
      <div className="col col-5 row-2">
        {matchAPIModuleObjectToGridComponent(Three, 'B3 left-vertical')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Two, 'B2 center-top-square')}
      </div>
      <div className="col col-4 row-2">
        {matchAPIModuleObjectToGridComponent(One, 'B1 right-vertical')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Four, 'B4 center-bottom-square')}
      </div>
    </div>
  );
}

function GridRowBPhase(props) {
  let { modules, index, width } = props;
  let [One, Three, Four, Two] = modules;

  return (
    <div
      className={`grid-row`}
      style={{ minHeight: width * 0.45 + 'px' }}
      key={index}
    >
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Two, 'B2 left-top-square')}
      </div>
      <div className="col col-5 row-2">
        {matchAPIModuleObjectToGridComponent(Three, 'B3 center-vertical')}
      </div>
      <div className="col col-4 row-2">
        {matchAPIModuleObjectToGridComponent(One, 'B1 right-vertical')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Four, 'B4 left-bottom-square')}
      </div>
    </div>
  );
}

function GridRowBPhaseReverse(props) {
  let { modules, index, width } = props;
  let [One, Three, Four, Two] = modules;

  return (
    <div
      className={`grid-row`}
      style={{ minHeight: width * 0.45 + 'px' }}
      key={index}
    >
      <div className="col col-4 row-2">
        {matchAPIModuleObjectToGridComponent(One, 'B1 left-vertical')}
      </div>
      <div className="col col-5 row-2">
        {matchAPIModuleObjectToGridComponent(Three, 'B3 center-vertical')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Two, 'B2 right-top-square')}
      </div>
      <div className="col col-3 row-1">
        {matchAPIModuleObjectToGridComponent(Four, 'B4 right-bottom-square')}
      </div>
    </div>
  );
}

let width = 0;
let modCount = 0; // The number of modules in this row.

function matchAPIModuleObjectToGridComponent(moduleObject, gridArea, i) {
  if (!moduleObject) {
    return false;
  }
  let { name, props, cardType, card, internalExternalUrl, date, uri, slug, title } = moduleObject;
  if (cardType) {
    name = cardType;
  }

  card.eventAction = gridEventAction;

  let key = `${gridArea}:${name}:${modCount}`;
  modCount++;

  switch (name) {
    case 'articleFeatured':
      return (
        <ArticleFeatured
          key={key}
          {...props}
          {...card}
          gridArea={gridArea}
        />
      );
    case 'articleCardTall':
      return (
        <ArticleCard
          key={key}
          {...props}
          {...card}
          uri={uri}
          slug={slug}
          date={date}
          gridArea={gridArea}
          moduleName="article-tall"
        />
      );
    case 'articleCardTilted':
      return (
        <ArticleTilt
          key={key}
          {...props}
          {...card}
          uri={uri}
          slug={slug}
          gridArea={gridArea}
          moduleName="article-tilted"
        />
      );
    case 'articleCardMasked':
      return (
        <MaskedHeadline
          key={key}
          {...props}
          {...card}
          uri={uri}
          slug={slug}
          gridArea={gridArea}
          moduleName="article-masked"
        />
      );
    case 'videoCardVertical':
      return (
        <VideoMaybeBorder
          key={key}
          {...props}
          {...card}
          gridArea={gridArea}
          width={width}
          slug={slug}
          moduleName="video-vertical"
        />
      );
    case 'videoCardHorizontal':
      return (
        <VideoMaybeBorder
          key={key}
          {...props}
          {...card}
          gridArea={gridArea}
          width={width}
          slug={slug}
          moduleName="video-horizontal"
        />
      );
    case 'shoeWCopy':
      return (
        <ShoeBorder
          key={key}
          {...props}
          {...card}
          uri={uri}
          slug={slug}
          gridArea={gridArea}
          moduleName="shoe-with-copy"
        />
      );
    case 'lateralShoe':
      return (
        <LateralShoe
          key={key}
          title={title}
          {...props}
          {...card}
          uri={uri}
          slug={slug}
          gridArea={gridArea}
          moduleName="lateral-shoe"
        />
      );
    case 'shoeWImg':
      return (
        <ShoeOverImage
          key={key}
          {...props}
          {...card}
          uri={uri}
          slug={slug}
          gridArea={gridArea}
          moduleName="shoe-with-image"
        />
      );
    case 'instagramWEmoji':
      return <InstaEmoji
        key={key}
        {...props}
        {...card}
        gridArea={gridArea}
        slug={slug}
        moduleName="instagram-with-emoji"
      />;
    case 'instagramWTape':
      return (
        <InstaCheckMe
          key={key}
          {...props}
          {...card}
          gridArea={gridArea}
          slug={slug}
          moduleName="instagram-with-tape"
        />
      );
    case 'instagramWBorder':
      return (
        <InstaBorder
          key={key}
          {...props}
          {...card}
          date={date}
          gridArea={gridArea}
          slug={slug}
          moduleName="instagram-with-border"
        />
      );
    case 'videoCardWBgImage':
      return (
        <SquareOnSquare
          key={key}
          {...props}
          {...card}
          {...internalExternalUrl}
          gridArea={gridArea}
          width={width}
          slug={slug}
          moduleName="video-with-bg"
        />
      );
    case 'squareImageWBorder':
    case 'image':
    case 'sticker':
      return (
        <ImageStandalone
          key={key}
          {...props}
          {...card}
          gridArea={gridArea}
          index={i}
        />
      );
    case 'quote':
      return (
        <HomepageQuoteMarks
          key={key}
          {...props}
          {...card}
          gridArea={gridArea}
        />
      );
    case 'quoteWBorder':
      return (
        <QuoteSidebar
          key={key}
          {...props}
          {...card}
          gridArea={gridArea}
        />
      );
    default:
      return false;
  }
}

function LoadMore({ addGridRows, loadMoreCount }) {
  // Analytics.
  // let trackClickProps = {
  //   content_click:    'load more content',
  //   pages_of_content: loadMoreCount
  // }

  return (
    <div className="load-more">
      <p
        className="date"
        onClick={addGridRows}
        // data-track-click={JSON.stringify(trackClickProps)}
      >
        <img src="/icons/misc/DownArrow_DownArrow_Icon.svg" alt="Load More" />
        Load More
      </p>
    </div>
  );
}

function Loading() {
  return (
    <div className="loading">
      <p className="date">Loading...</p>
      <img src="/icons/misc/loading.gif" alt="Loading..." />
    </div>
  );
}

// const Transition = posed.div({
//   enter: {
//     opacity: 1,
//     delay: 400,
//     beforeChildren: true,
//     transition: { duration: 400 }
//   },
//   exit: { opacity: 0, transition: { duration: 400 } }
// });
