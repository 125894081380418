import React, { Component } from 'react';
import './MaskedHeadline.scss';
import { Link } from 'react-router-dom';

export default class MaskedHeadline extends Component {
  render() {
    let gridArea = this.props.gridArea || '';

    let { backgroundColor, headline, textColor, image, uri, slug, eventAction } = this.props;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: `${eventAction}`,
        event_category: "Page Content",
        event_label: `${eventAction}: ${headline} - ${slug}`,
      }
    }

    const headlineStyle = {};
    if (textColor && textColor.startsWith('#')) {
      headlineStyle.color = textColor;
    }
    if (backgroundColor && backgroundColor.startsWith('#')) {
      headlineStyle.backgroundColor = backgroundColor;
    }

    const cid = `cid-${textColor ? textColor.replace('#', '') : ''}--${backgroundColor ? backgroundColor.replace('#', '') : ''}`;

    return (
      <div className={`masked-headline ${gridArea}`}>
        {headlineStyle.color && headlineStyle.backgroundColor && (
          <style>{`
            .masked-headline__background-link.${cid}:hover {
              color: ${backgroundColor} !important;
              background-color: ${textColor} !important;
            }
          `}</style>
        )}
        <Link
          to={`/${uri}`}
          className={`masked-headline__background-link ${textColor} hov--${backgroundColor} bg--${backgroundColor} hov-bg--${textColor} parent ${cid}`}
          style={{
            backgroundImage: `url(${
              image.background ? image.background.dataOriginal : null
            })`,
            backgroundPosition: `${image.focus.x * 100}% ${image.focus.y * 100}%`,
            ...headlineStyle
          }}
          data-count-grid-module
          data-track-click={JSON.stringify(trackClickOptions.module)}
        >
          <p
            className="masked-headline__text-content headline--deco"
          >
            {headline}
          </p>
          <div
            className={`masked-headline__img`}
            dangerouslySetInnerHTML={{ __html: image.asset }}
          />
        </Link>
      </div>
    );
  }
}
