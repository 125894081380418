import flatten from 'lodash.flatten';

export default function sortAndPack(arrayOfModules, arrayOfStickers) {
  // Count the number of modules between each featured article
  // ex: [23, {ArticleFeatured}, {ArticleFeatured}, 14, {ArticleFeatured}, 17, ...]
  let lengthsBetweenFeatures = arrayOfModules.reduce(
    (accumulator, module, index) => {
      let previousAccumulation = accumulator[accumulator.length - 1];

      if (module.cardType === 'articleFeatured') {
        return [...accumulator, module];
      }
      if (index === 0) {
        return [1];
      }
      if (previousAccumulation.cardType === 'articleFeatured') {
        return [...accumulator, 1];
      } else {
        return [
          ...accumulator.slice(0, accumulator.length - 1),
          previousAccumulation + 1
        ];
      }
    },
    []
  );
  // console.log(lengthsBetweenFeatures);

  // Round the lengths of modules between featured articles down, to a multiple of 4
  // ex: [20, {ArticleFeatured}, {ArticleFeatured}, 16, {ArticleFeatured}, 18]
  let roundedLengthsBetweenFeatures = lengthsBetweenFeatures.reduce(
    (accumulator, lengthOrFeature) => {
      if (typeof lengthOrFeature === 'number') {
        // apply modifier
        let length = lengthOrFeature + accumulator.mod;
        let newMod = length % 4;
        let newLength = length - newMod;
        return { arr: [...accumulator.arr, newLength], mod: newMod };
      } else {
        return {
          arr: [...accumulator.arr, lengthOrFeature],
          mod: accumulator.mod
        };
      }
    },
    { arr: [], mod: 0 }
  );
  // console.log('roundedLengthsBetweenFeatures', roundedLengthsBetweenFeatures);

  // Append the remaining length to the end of the accumulated lengths between features
  let finalLengthsBetweenFeatures = [
    ...roundedLengthsBetweenFeatures.arr,
    roundedLengthsBetweenFeatures.mod
  ];
  // console.log('finalLengthsBetweenFeatures: ', finalLengthsBetweenFeatures);

  // Create array of arrays of modules in sets of 4
  // !! vars undergo mutation
  var arrayOfModulesSansFeatures = arrayOfModules.filter(
    module => module.cardType !== 'articleFeatured'
  );
  // console.log('original items: ', arrayOfModulesSansFeatures);

  // Apply Constraints
  var { constrainedArray } = applyConstraintsToArrayOfModulesSansFeatures(
    arrayOfModulesSansFeatures,
    arrayOfStickers
  );
  // console.log('constrained array: ', constrainedArray);

  let rowBlocksAndFeatures = finalLengthsBetweenFeatures.map((unit, i) => {
    if (typeof unit !== 'number') {
      return unit;
    } else {
      var numberOfGridRowsToCreate = unit / 4;
      var gridRows = [];
      while (numberOfGridRowsToCreate > 0) {
        gridRows.push(constrainedArray.slice(0, 4));
        constrainedArray = constrainedArray.slice(4);
        --numberOfGridRowsToCreate;
      }
      return gridRows;
    }
  });
  // console.log('rowBlocksAndFeatures: ', rowBlocksAndFeatures);
  // console.log('remaining constrained Array: ', constrainedArray);

  // prepare modules for consumption by front-end
  let caboose = groupIntoFours(constrainedArray);

  return flatten([
    ...rowBlocksAndFeatures,
    ...(caboose[0].length > 0 ? [caboose] : [])
  ]);
}

// UTILITY FUNCTIONS

function applyConstraintsToArrayOfModulesSansFeatures(
  arrayOfModules,
  arrayOfStickers
) {
  let {
    getNextVerticalSticker,
    getNextSquareSticker,
    getNextHorizontalSticker
  } = stickerInc(arrayOfStickers);
  return arrayOfModules.reduce(
    (accumulator, item, index, original) => {
      let { constrainedArray, countUp, countDown } = accumulator;

      /*
          console.log('NEW LOOP');
          console.log('CONSTRAINED ARRAY:');
          console.log(constrainedArray);
          console.log('COUNT UP: ', countUp);
          console.log('COUNT DOWN: ', countDown);
          console.log('ITEM ASPECT: ', item.aspect);
      */

      // CONSTRAINT I.
      // INSTEAD OF APPENDING A THIRD VERTICAL ITEM, APPEND TWO SQUARE STICKERS, THEN THE VERTICAL ITEM
      if (countUp.vertical === 2 && item.aspect === 'vertical') {
        let newCounts = appendVertical(
          appendSquare(appendSquare({ countUp, countDown }))
        );

        return {
          constrainedArray: [
            ...constrainedArray,
            getNextSquareSticker(),
            getNextSquareSticker(),
            item
          ],
          countUp: newCounts.countUp,
          countDown: newCounts.countDown
        };
      }

      // CONSTRAINT II.
      // WHEN APPENDING A FOURTH ITEM,
      // IF THE PRIOR 3 ITEMS CONTAIN EXACTLY 1 VERTICAL ITEM AND 0 HORIZONTAL ITEMS
      // AND THE CURRENT ITEM IS NOT HORIZONTAL, THEN APPEND A HORIZONTAL STICKER
      if (
        countDown === 1 &&
        countUp.vertical === 1 &&
        countUp.horizontal === 0 &&
        item.aspect !== 'horizontal'
      ) {
        let newCounts =
          item.aspect === 'vertical'
            ? appendVertical(appendHorizontal({ countUp, countDown }))
            : appendSquare(appendHorizontal({ countUp, countDown }));

        return {
          constrainedArray: [
            ...constrainedArray,
            getNextHorizontalSticker(),
            item
          ],
          countUp: newCounts.countUp,
          countDown: newCounts.countDown
        };
      }

      // CONSTRAINT III.
      // WHEN APPENDING A FOURTH ITEM,
      // IF THE PRIOR 3 ITEMS CONTAIN EXACTLY 1 VERTICAL ITEM AND 2 HORIZONTAL ITEMS
      // AND THE CURRENT ITEM IS NOT VERTICAL, THEN APPEND A VERTICAL STICKER
      if (
        countDown === 1 &&
        countUp.vertical === 1 &&
        countUp.horizontal === 2 &&
        item.aspect !== 'vertical'
      ) {
        let newCounts =
          item.aspect === 'square'
            ? appendSquare(appendVertical({ countUp, countDown }))
            : appendHorizontal(appendVertical({ countUp, countDown }));

        return {
          constrainedArray: [
            ...constrainedArray,
            getNextVerticalSticker(),
            item
          ],
          countUp: newCounts.countUp,
          countDown: newCounts.countDown
        };
      }

      // ADDITIONAL CONSTRAINTS
      /* */

      // FALL THROUG CASE :: NO CONSTRAINT WILL BE APPLIED
      var newCounts;
      if (item.aspect === 'vertical') {
        newCounts = appendVertical({ countUp, countDown });
      }
      if (item.aspect === 'square') {
        newCounts = appendSquare({ countUp, countDown });
      }
      if (item.aspect === 'horizontal') {
        newCounts = appendHorizontal({ countUp, countDown });
      }
      // console.log('FALL THROUGH CASE');
      // console.log('newCounts: ', newCounts);
      return {
        constrainedArray: [...constrainedArray, item],
        ...newCounts
      };
    },
    {
      constrainedArray: [],
      countUp: { vertical: 0, square: 0, horizontal: 0 },
      countDown: 4
    }
  );
}

function appendVertical({ countUp, countDown }) {
  if (countDown === 1) {
    return {
      countUp: { vertical: 1, square: 0, horizontal: 0 },
      countDown: 4
    };
  } else {
    return {
      countUp: {
        vertical: countUp.vertical + 1,
        square: countUp.square,
        horizontal: countUp.horizontal
      },
      countDown: countDown - 1
    };
  }
}

function appendSquare({ countUp, countDown }) {
  if (countDown === 1) {
    return {
      countUp: { vertical: 0, square: 1, horizontal: 0 },
      countDown: 4
    };
  } else {
    return {
      countUp: {
        vertical: countUp.vertical,
        square: countUp.square + 1,
        horizontal: countUp.horizontal
      },
      countDown: countDown - 1
    };
  }
}

function appendHorizontal({ countUp, countDown }) {
  if (countDown === 1) {
    return {
      countUp: { vertical: 0, square: 0, horizontal: 1 },
      countDown: 4
    };
  } else {
    return {
      countUp: {
        vertical: countUp.vertical,
        square: countUp.square,
        horizontal: countUp.horizontal + 1
      },
      countDown: countDown - 1
    };
  }
}

function groupIntoFours(array) {
  let newArray = array.reduce(
    (accumulator, item, index) => {
      if (accumulator.rem.length === 4) {
        return {
          grouped: [...accumulator.grouped, accumulator.rem],
          rem: [item]
        };
      } else {
        return {
          grouped: [...accumulator.grouped],
          rem: [...accumulator.rem, item]
        };
      }
    },
    { grouped: [], rem: [] }
  );
  return [...newArray.grouped, newArray.rem];
}

function stickerInc(arrayOfStickers) {
  // TODO: remove or operators in return statements when sticker API is stable

  let stickers = [
    arrayOfStickers.filter(s => s.aspect === 'vertical'),
    arrayOfStickers.filter(s => s.aspect === 'square'),
    arrayOfStickers.filter(s => s.aspect === 'horizontal')
  ];

  // !! vars undergo mutation
  var stickerCursors = [0, 0, 0];

  let incrementStickerCursorAtIndex = indexToIncrement => {
    stickerCursors[indexToIncrement] =
      stickerCursors[indexToIncrement] < stickers[indexToIncrement].length - 1
        ? stickerCursors[indexToIncrement] + 1
        : 0;
  };

  let getNextVerticalSticker = () => {
    let index = stickerCursors[0];
    incrementStickerCursorAtIndex(0);
    return stickers[0][index] || arrayOfStickers[0];
  };
  let getNextSquareSticker = () => {
    let index = stickerCursors[1];
    incrementStickerCursorAtIndex(1);
    return stickers[1][index] || arrayOfStickers[0];
  };
  let getNextHorizontalSticker = () => {
    let index = stickerCursors[2];
    incrementStickerCursorAtIndex(2);
    return stickers[2][index] || arrayOfStickers[0];
  };

  return {
    getNextVerticalSticker,
    getNextSquareSticker,
    getNextHorizontalSticker
  };
}
