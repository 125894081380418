import React, { Component } from 'react';
import './ArticleCopy.scss';
// import { Link } from 'react-router-dom';

export default class ArticleCopy extends Component {
  render() {
    let { textColor, copy, ctaLink, ctaCopy } = this.props;

    return (
      <div className="article-copy">
        <div className={`article-copy__text copy copy--intro copy-url ${textColor}`} 
          dangerouslySetInnerHTML={{ __html: copy }}
        />
        {ctaLink && ctaCopy && (
          <p className={`article-copy__text copy copy--intro ${textColor}`}>
            <a href={ctaLink} className="link" target="blank">
              <span className="arrow" />
              <span className="link__text">{ctaCopy}</span>
            </a>
          </p>
        )}
      </div>
    );
  }
}
