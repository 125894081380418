import React, { Component } from 'react';

export default class FacebookIcon extends Component {
  render() {
    return (
      <a
        href="https://www.facebook.com/NikeSkateboarding/"
        target="blank"
      >
        <svg aria-hidden="true" className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23.9995C18.6274 23.9995 24 18.6269 24 11.9995C24 5.37209 18.6274 -0.000488281 12 -0.000488281C5.37258 -0.000488281 0 5.37209 0 11.9995C0 18.6269 5.37258 23.9995 12 23.9995ZM15.5 4.99951H13.174C11.58 4.99951 10.254 6.32151 10.254 7.91551V10.2375H8V12.9505H10.254V19.9995H12.965V12.9505H15.127L15.5 10.2375H12.965V8.44051C12.965 7.62551 13.457 7.50351 13.834 7.50351H15.5V4.99951Z" fill="#0064D3"/>
        </svg>
      </a>
    );
  }
}