import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './HeroAthlete.scss';

export default class HeroAthlete extends Component {
  constructor(props) {
    super(props);
    this.state = { playing: false };
  }

  handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      this.setState({ playing: true });
    }
  }

  render() {
    let {
      headline,
      copy,
      heroImg,
      athleteImg,
      skatingSince,
      placeOfBirth,
      skateShoe,
      textColor,
      backgroundColor,
      video
    } = this.props;

    const textColorStyle = {};
    const borderColorStyle = {};
    const backgroundColorStyle = {};
    if (textColor && textColor.startsWith('#')) {
      textColorStyle.color = textColor;
      borderColorStyle.borderColor = textColor;
      borderColorStyle.borderStyle = 'solid';
    }

    if (backgroundColor && backgroundColor.startsWith('#')) {
      backgroundColorStyle.backgroundColor = backgroundColor;
      backgroundColorStyle.borderStyle = 'solid';
    }

    return (
      <div className={`athlete-hero--${backgroundColor} athlete-hero`} style={backgroundColorStyle}>
        <div className="ctnr athlete-hero__ctnr">
          <div className={`athlete-hero__content`}>
            <div
              className={`athlete-hero__profile-pic border-color--${textColor}`}
              style={textColorStyle}
              dangerouslySetInnerHTML={{ __html: athleteImg }}
            />
            <div>
              <h1
                className={`athlete-hero__headline headline headline--alt headline--alt-lg ${textColor}`}
                style={textColorStyle}
              >
                {headline}
              </h1>
              <div className="athlete-hero__copy copy copy--lg white"
                dangerouslySetInnerHTML={{ __html: copy }}></div>
            </div>
          </div>
          <div
            className={`athlete-hero__img-ctnr ${
              this.state.playing && video ? 'playing' : ''
            }`}
            onClick={() => {
              this.setState({ playing: true });
            }}
          >
            <div
              className={`athlete-hero__img ${video ? 'has-video' : ''}`}
              dangerouslySetInnerHTML={{ __html: heroImg }}
              tabIndex={video ? 0 : -1}
              onKeyDown={this.handleKeyDown}
            />
            {video && (
              <React.Fragment>
                <ReactPlayer
                  width={'100%'}
                  height={'100%'}
                  url={`${video}`}
                  playing={this.state.playing}
                  playsinline={false}
                  className={'athlete-hero__video react-player'}
                  config={{
                    youtube: {
                      playerVars: { rel: 0, controls: 1, playsinline: 0 },
                      embedOptions: {
                        host: 'https://www.youtube-nocookie.com'
                      }
                    }
                  }}
                />
                <img
                  src="/icons/misc/VideoPlay.svg"
                  className="athlete-hero__play-btn play-btn"
                  alt="Play video"
                />
              </React.Fragment>
            )}
          </div>
        </div>
        <div className={`athlete-details ctnr`}>
          <div
            className={`athlete-details__ctnr bg--${backgroundColor} border-color--${textColor}`}
            style={{...borderColorStyle, ...backgroundColorStyle}}
          >
            <p className={`athlete-details__eyebrow eyebrow ${textColor}`} style={textColorStyle}>
              D.O.B.
            </p>
            <h3 className="white headline--alt athlete-details__headline">
              {skatingSince}
            </h3>
          </div>
          <div
            className={`athlete-details__ctnr bg--${backgroundColor} border-color--${textColor}`}
            style={{...borderColorStyle, ...backgroundColorStyle}}
          >
            <p className={`athlete-details__eyebrow eyebrow ${textColor}`} style={textColorStyle}>
              Hometown
            </p>
            <h3 className="white headline--alt athlete-details__headline">
              {placeOfBirth}
            </h3>
          </div>
          {skateShoe.productLink && (
            <a
              href={skateShoe.productLink}
              target="_blank"
              rel="noopener noreferrer"
              className={`athlete-details__ctnr bg--${backgroundColor} skate-shoe border-color--${textColor}`}
              style={{...borderColorStyle, ...backgroundColorStyle}}
              data-track-click={JSON.stringify({
                event_action: "Shop from team",
                event_category: "Shop Nike.com",
                event_label: `Shop from team: ${headline}`,
              })}
            >
              <div className="skate-shoe__details">
                <p className={`athlete-details__eyebrow eyebrow ${textColor}`} style={textColorStyle}>
                  Favorite Skate Shoe
                </p>
                <h3 className="white headline--alt athlete-details__headline">
                  {skateShoe.shoeName}
                </h3>
              </div>
              <div
                className="skate-shoe__img"
                dangerouslySetInnerHTML={{
                  __html: `
                    <div class='shop-ctnr'>
                      Shop
                      <img src='/icons/misc/shopping-cart.svg' alt='Shop Now' class='shop-ctnr__icon' />
                    </div>
                    ${skateShoe.shoeImage.asset}`
                }}
              />
            </a>
          )}
          {!skateShoe.productLink && (
            <div
              className={`athlete-details__ctnr bg--${backgroundColor} skate-shoe border-color--${textColor}`}
              style={{...borderColorStyle, ...backgroundColorStyle}}
            >
              <div className="skate-shoe__details">
                <p className={`athlete-details__eyebrow eyebrow ${textColor}`} style={textColorStyle}>
                  Favorite Skate Shoe
                </p>
                <h3 className="white headline--alt athlete-details__headline">
                  {skateShoe.shoeName}
                </h3>
              </div>
              <div
                className="skate-shoe__img"
                dangerouslySetInnerHTML={{
                  __html: `${skateShoe.shoeImage.asset}`
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
