import React, { Component } from 'react';
import './QuoteMarks.scss';

// expected props:
// fontColor: string
// text: string
// attribution: string

export class HomepageQuoteMarks extends Component {
  render() {
    let { gridArea } = this.props;

    let { copy, textColor, author } = this.props;

    return (
      <div className="quote-marks quote-marks--homepage">
        <p
          className={`quote-marks__headline headline headline--alt ${gridArea} ${textColor}`}
        >
          <img
            className="quote-marks__mark"
            src={'/icons/misc/Quotation_Start.svg'}
            alt=""
          />
          <div dangerouslySetInnerHTML={{ __html: copy }} />
          <img
            className="quote-marks__mark"
            src={'/icons/misc/Quotation_End.svg'}
            alt=""
          />
        </p>
        <p className={`quote-marks__attribution ${gridArea} date`}>
          - {author}
        </p>
      </div>
    );
  }
}

export class ArticleQuoteMarks extends Component {
  render() {
    let { textColor, copy, quoteAuthor } = this.props;

    const textStyle = {};
    if (textColor && textColor.startsWith('#')) {
      textStyle.color = textColor;
    }

    return (
      <div className="quote-marks quote-marks--article">
        <p
          className={`quote-marks__headline niko headline headline--alt ${textColor}`}
          style={textStyle}
        >
          <img
            className="quote-marks__mark"
            src={'/icons/misc/Quotation_Start.svg'}
            alt=""
          />
          <div class="copy-url" dangerouslySetInnerHTML={{ __html: copy }} />
          <img
            className="quote-marks__mark"
            src={'/icons/misc/Quotation_End.svg'}
            alt=""
          />
        </p>
        {
          quoteAuthor &&
          <p className="quote-marks__attribution date">- {quoteAuthor}</p>
        }
      </div>
    );
  }
}
