import React, { Component } from 'react';
import './ArticleEditorialImageLeftRight.scss';

// expected props:
// image: object
// imageSide: string ('left' || 'right')
// copy: string

export default class ArticleEditorialImageLeftRight extends Component {
  render() {
    let imageSide = 'left';
    let { copy, image, textColor } = this.props;
    const color = textColor ? textColor : '';
    return (
      <div
        className={`article-editorial-image-lr article-editorial-image-lr--${imageSide} ${color}`}
      >
        <div className="ctnr article-editorial-image-lr__ctnr">
          { image && (
            <div
              className={`article-editorial-image-lr__image article-editorial-image-lr__image--${imageSide}`}
              dangerouslySetInnerHTML={{__html: image.asset}}
            />
          )}
          <div className={`article-editorial-image-lr__text article-editorial-image-lr__text--${imageSide}`}>
            <div className="copy"
              dangerouslySetInnerHTML={{ __html: copy }}>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
