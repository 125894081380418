import React, { Component } from 'react';
import './InstaBorder.scss';

export default class InstaBorder extends Component {
  render() {
    let gridArea = this.props.gridArea || '';

    let {
      borderSize,
      borderColor,
      instagramImage,
      author,
      copy,
      date,
      postUrl,
      internalExternalUrl,
    } = this.props;

    let dateArray = date.split('/');

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: "Instagram click",
        event_category: "Instagram",
        event_label: `Instagram click: @${author}`,
      }
    }

    return (
      <a 
        href={internalExternalUrl.value ? internalExternalUrl.value : postUrl} 
        target={internalExternalUrl.target} 
        className={`insta-border ${gridArea}`}
        data-count-grid-module
        data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <div>
          <p className="insta-border__date date">
            {dateArray[0]}
            <br />
            {dateArray[1]}
            <br />
            {dateArray[2]}
          </p>
          <div className="insta-border__ctnt">
            <div
              className={
                `insta-border__background ` +
                `border-color--${borderColor} border-thickness--${borderSize}`
              }
              dangerouslySetInnerHTML={{ __html: instagramImage.asset }}
            />
            <p className="insta-border__user eyebrow">{internalExternalUrl.customText ? internalExternalUrl.customText : `@${author}`}</p>
            <div className="insta-border__copy copy"
              dangerouslySetInnerHTML={{ __html: copy }}></div>
          </div>
        </div>
      </a>
    );
  }
}
