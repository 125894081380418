import React, { Component } from 'react';
import './Hero.scss';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

export default class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = { playing: false };
  }
  render() {
    let {
      ctaCopy,
      eyebrow,
      textColor,
      backgroundColor,
      image,
      uri,
      // slug,
      headline,
      videoUrl,
      copy
    } = this.props;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: "Home content click",
        event_category: "Page Content",
        event_label: `Home content click: ${headline} - ${ctaCopy}`,
      },
      video: {
        event_action: "Home content click",
        event_category: "Hero Video",
        event_label: `Home content click: ${videoUrl}`,
      }
    }

    return (
      <div className={`hero hero--${backgroundColor}`}>
        <div className={`hero__content ${textColor}`}>
          <div>
            <p className="eyebrow">{ eyebrow }</p>
            <h1 className="headline headline--lg hero__headline">
              { headline }
            </h1>
            <div className="hero__copy copy copy--intro"
              dangerouslySetInnerHTML={{ __html: copy }}>
            </div>
            { uri && ctaCopy && (
              <Link
                to={`/${uri}`}
                className={`btn btn--${textColor} btn--bg-${backgroundColor}`}
                data-track-click={JSON.stringify(trackClickOptions.module)}
              >
                <span className="arrow" />{ ctaCopy }
              </Link>
            )}
          </div>
        </div>

        <div
          className={`hero__img-ctnr ${
            this.state.playing && videoUrl ? 'playing' : ''
          }`}
          onClick={() => {
            this.setState({ playing: true });
          }}
        >
          <div className="hero__video-wrapper">
            {image && (
              <div
                className={`hero__img ${videoUrl ? 'has-video' : ''}`}
                style={{
                  backgroundImage: `url(${image.dataOriginal})`,
                  backgroundSize: 'cover',
                  backgroundPosition: image.position
                }}
              />
            )}
            {videoUrl && (
              <React.Fragment>
                <ReactPlayer
                  width={'100%'}
                  height={'100%'}
                  light={!image}
                  url={`${videoUrl}`}
                  playing={this.state.playing}
                  playsinline={false}
                  className={'hero__video react-player'}
                  config={{
                    youtube: {
                      playerVars: { rel: 0, controls: 0, playsinline: 0 },
                      embedOptions: {
                        host: 'https://www.youtube-nocookie.com'
                      }
                    }
                  }}
                />
                {image && (<img
                  src="/icons/misc/VideoPlay.svg"
                  className="hero__play-btn play-btn"
                  alt="Play video"
                  data-track-click={JSON.stringify(trackClickOptions.video)}
                />)}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
