import React, { Component } from 'react';
import './RelatedContent.scss';
import { Grid } from '../../components';
import debounce from 'lodash.debounce';

export default class RelatedContent extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
  }
  componentDidMount() {
    // only fetch data if it does not already exist
    // if (!this.props.data) this.props.getData();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = debounce(() => {
    let width = window.innerWidth;
    // DEBOUNCE, lower in prod
    if (width === 0) {
      window.setTimeout(this.handleResize, 60);
      return;
    }
    this.setState({ width: window.innerWidth });
  }, 100);

  render() {
    const { width } = this.state;
    let { headline, posts, defaultHeadline, internalExternalUrl, eventAction } = this.props;

    // If there are no cards to output, don't show the module
    if(!posts.cards.length) {
      return null;
    }

    return (
      <div className="rec-content">
        <p className="rec-content__headline widget">
          { headline ? headline : defaultHeadline }
        </p>

        <Grid
          width={width}
          gridItems={posts.cards} // TODO : GRID ITEMS FROM PROPS
          stickers={posts.stickers} // TODO : STICKERS FROM PROPS
          internalExternalUrl={internalExternalUrl}
          eventAction={eventAction}
        />
      </div>
    );
  }
}