import React, { Component } from 'react';
import './ShoeBorder.scss';
import { Link } from 'react-router-dom';

// expected props:
// shoeImageURL: string
// borderColor: string
// linkURL: string

export default class ShoeBorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applyAnimation: false
    }

    this.rootRef = React.createRef();
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if(!this.mediaQuery.matches) {
        return;
      }

      this.setState({
        applyAnimation: entry.isIntersecting
      });
    });
  }

  componentDidMount() {
    const rootElement = this.rootRef.current;
    if (!rootElement) {
      return;
    }

    this.mediaQuery = window.matchMedia('(max-width: 768px)');
    this.intersectionObserver = new IntersectionObserver(this.handleIntersection.bind(this), {
      rootMargin: "-50% 0px -50% 0px"
    });

    // const parentColEl = rootElement.closest('.col');
    //
    // if (!parentColEl) {
    //   return;
    // }
    //
    // const textLeftLength = this.props.textLeft ? this.props.textLeft.length : 0;
    // const textRightLength = this.props.textRight ? this.props.textRight.length : 0;
    // const textLength = textLeftLength + textRightLength;
    //
    // if (textLength > 13) {
    //   parentColEl.classList.remove('col-3', 'col-4', 'col-5', 'col-6');
    //   parentColEl.classList.add('col-6');
    // } else if (textLength > 8) {
    //   parentColEl.classList.remove('col-3', 'col-4', 'col-5', 'col-6');
    //   parentColEl.classList.add('col-4');
    // }

    this.intersectionObserver.observe(rootElement);
  }

  render() {
    let { borderColor, ctaCopy, productImage, uri, textLeft, textRight, eventAction } = this.props;

    // Analytics.
    let trackClickOptions = {
      module: {
        event_action: `${eventAction}`,
        event_category: "Page Content",
        event_label: `${eventAction}: ${textLeft} - ${ctaCopy}`,
      }
    }

    const borderStyle = {};
    if (borderColor && borderColor.startsWith('#')) {
      borderStyle.borderColor = borderColor;
      borderStyle.borderStyle = 'solid';
    }

    return (
      <Link
        to={`/${uri}`}
        className={`shoe-border ${this.state.applyAnimation ? 'animate' : ''}`}
        data-count-grid-module
        data-track-click={JSON.stringify(trackClickOptions.module)}
      >
        <div
          className={`shoe-border__content headline--alt headline--alt-sm border-color--${borderColor}`}
          ref={this.rootRef}
          style={borderStyle}
        >
          <p>{ textLeft }</p>
          <p>{ textRight }</p>
        </div>
        <div
          className={`shoe-border__shoe lateral-shoe__shoe border-color--${borderColor}`}
          style={borderStyle}
          dangerouslySetInnerHTML={{__html: productImage.asset}}
        />
        <p
          className={`shoe-border__cta`}
        >
          <span className="arrow"></span>{ ctaCopy }
        </p>
      </Link>
    );
  }
}
