import React from 'react'
import "./ThirtyThreeToFortyOne.scss"

const ThirtyThreeToFortyOne = ({ data, links }) => {
  return (
    <React.Fragment>
      {
        (links.magazine.url !== "") && (
          <div key={data.magazine.id} className={`sandysplace__${data.magazine.id}`}>
            <a href={links.magazine.url} {...(links.magazine.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
                <img
                src={data.magazine.image.src}
                alt={data.magazine.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.bass.url !== "") && (
          <div key={data.bass.id} className={`sandysplace__${data.bass.id}`}>
            <a href={links.bass.url} {...(links.bass.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.bass.image.src}
                alt={data.bass.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.fiftyEight.url !== "") && (
          <div key={data.fiftyeight.id} className={`sandysplace__${data.fiftyeight.id}`}>
            <a href={links.fiftyEight.url} {...(links.fiftyEight.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.fiftyeight.image.src}
                alt={data.fiftyeight.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.radio.url !== "") && (
          <div key={data.radio.id} className={`sandysplace__${data.radio.id}`}>
            <a href={links.radio.url} {...(links.radio.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.radio.image.src}
                alt={data.radio.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.eightBall.url !== "") && (
          <div key={data.eightBall.id} className={`sandysplace__${data.eightBall.id}`}>
            <a href={links.eightBall.url} {...(links.eightBall.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.eightBall.image.src}
                alt={data.eightBall.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.mrLeaf.url !== "") && (
          <div key={data.mrleaf.id} className={`sandysplace__${data.mrleaf.id}`}>
            <a href={links.mrLeaf.url} {...(links.mrLeaf.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.mrleaf.image.src}
                alt={data.mrleaf.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.badge.url !== "") && (
          <div key={data.badge.id} className={`sandysplace__${data.badge.id}`}>
            <a href={links.badge.url} {...(links.badge.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.badge.image.src}
                alt={data.badge.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.rocket.url !== "") && (
          <div key={data.rocket.id} className={`sandysplace__${data.rocket.id}`}>
            <a href={links.rocket.url} {...(links.rocket.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.rocket.image.src}
                alt={data.rocket.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.skull.url !== "") && (
          <div key={data.skull.id} className={`sandysplace__${data.skull.id}`}>
            <a href={links.skull.url} {...(links.skull.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.skull.image.src}
                alt={data.skull.image.alt}
              />
            </a>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default ThirtyThreeToFortyOne
