import React, { Component } from 'react';
import './ArchiveHero.scss';

// expected props
// heroImage: object;
// headline: string;

export default function ArchiveHero({ heroImage, headline, textColor, highlightColor }) {
  return (
    <div className="archive-hero ctnr">
      { heroImage.background && (
        <div
          className="archive-hero__background"
          style={{
            backgroundImage: `url('${heroImage.background.dataOriginal}')`,
            backgroundPosition: `${heroImage.focus.x * 100}% ${heroImage.focus.y * 100}%`
          }}
        />
      )}
      <div
        className={`archive-hero__text headline--alt headline--alt-lg headline--highlight headline--highlight-${highlightColor} ${textColor}`}
      >
        {headline}
      </div>
    </div>
  );
}
