import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './ArticleHeroB.scss';
import SocialShare from '../ArticleSocialShare/ArticleSocialShare';

export default class ArticleHeroB extends Component {
  constructor(props) {
    super(props);
    this.state = { playing: false };
  }

  render() {
    let {
      title,
      heroImage,
      highlightColor,
      textColor,
      postDate,
      youtubeId
    } = this.props;

    const headlineStyle = {};
    if (textColor && textColor.startsWith('#')) {
      headlineStyle.color = textColor;
    }
    if (highlightColor && highlightColor.startsWith('#')) {
      headlineStyle.backgroundImage = `linear-gradient(90deg, ${highlightColor} 0, ${highlightColor} 0)`
    }


    return (
      <div className={`article-hero-B ${youtubeId ? 'has-video' : ''}`}>
        <div
          className={`article-hero-B__img-ctnr ${
            this.state.playing && youtubeId ? 'playing' : ''
          }`}
          onClick={() => {
            this.setState({ playing: true });
          }}
        >
          {heroImage && heroImage.background && (
            <div
              className={`article-hero-B__img ${youtubeId ? 'has-video' : ''}`}
              style={{
                backgroundImage: `url(${heroImage.background.dataOriginal})`,
                backgroundPosition: `${heroImage.focus.x * 100}% ${heroImage.focus.y * 100}%`
              }}
              alt=""
            />
          )}
          {youtubeId &&
            <React.Fragment>
              <ReactPlayer
                width={'100%'}
                height={'100%'}
                url={`${youtubeId}`}
                playing={this.state.playing}
                playsinline={false}
                className={'article-hero-B__video react-player'}
                config={{
                  youtube: {
                    playerVars: { rel: 0, controls: 1, playsinline: 0 },
                    embedOptions: {
                      host: 'https://www.youtube-nocookie.com'
                    }
                  }
                }}
              />
              <img
                src="/icons/misc/VideoPlay.svg"
                className="article-hero-B__play-btn play-btn"
                alt="Play video"
              />
            </React.Fragment>
          }
        </div>
        <div className={`article-hero-B__content ${!heroImage || !heroImage.background ? 'article-hero-B__no-img' : ''}`}>
          <div className="article-hero-B__headline-parent">
            <h1
              className={`article-hero-B__headline headline--xl headline--highlight headline--highlight-${highlightColor} ${textColor}`}
              style={headlineStyle}
            >
              {title}
            </h1>
          </div>
          <div className="article-hero-B__date-and-social">
            <p className="article-hero-B__date date">{postDate}</p>
            <SocialShare />
          </div>
        </div>
      </div>
    );
  }
}
