import React from 'react'
import "./FortyTwoToFortyEight.scss"

const FortyTwoToFortyEight = ({ data, links }) => {
  return (
    <React.Fragment>
      {
        (links.remoteControl.url !== "") && (
          <div key={data.remoteControl.id} className={`sandysplace__${data.remoteControl.id}`}>
            <a href={links.remoteControl.url} {...(links.remoteControl.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.remoteControl.image.src}
                alt={data.remoteControl.image.alt}
              />
            </a> 
          </div>
        )
      }
      {
        (links.gundam.url !== "") && (
          <div key={data.gundam.id} className={`sandysplace__${data.gundam.id}`}>
            <a href={links.gundam.url} {...(links.gundam.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.gundam.image.src}
                alt={data.gundam.image.alt}
              /> 
            </a>
          </div>
        )
      }
      {
        (links.vhsTape1.url !== "") && (
          <div key={data.vhsTapeOne.id} className={`sandysplace__${data.vhsTapeOne.id}`}>
            <a href={links.vhsTape1.url} {...(links.vhsTape1.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.vhsTapeOne.image.src}
                alt={data.vhsTapeOne.image.alt}
              /> 
            </a>
          </div>
        )
      }
      {
        (links.vhsTape2.url !== "") && (
          <div key={data.vhsTapeTwo.id} className={`sandysplace__${data.vhsTapeTwo.id}`}>
            <a href={links.vhsTape2.url} {...(links.vhsTape2.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.vhsTapeTwo.image.src}
                alt={data.vhsTapeTwo.image.alt}
              /> 
            </a>
          </div>
        )
      }
      {
        (links.vhsTape3.url !== "") && (
          <div key={data.vhsTapeThree.id} className={`sandysplace__${data.vhsTapeThree.id}`}>
            <a href={links.vhsTape3.url} {...(links.vhsTape3.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.vhsTapeThree.image.src}
                alt={data.vhsTapeThree.image.alt}
              /> 
            </a>
          </div>
        )
      }
      {
        (links.flipPhone.url !== "") && (
          <div key={data.flipPhone.id} className={`sandysplace__${data.flipPhone.id}`}>
            <a href={links.flipPhone.url} {...(links.flipPhone.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.flipPhone.image.src}
                alt={data.flipPhone.image.alt}
              />
            </a>
          </div>
        )
      }
      {
        (links.skateKey.url !== "") && (
          <div key={data.skateKey.id} className={`sandysplace__${data.skateKey.id}`}>
            <a href={links.skateKey.url} {...(links.skateKey.newTab && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}>
              <img
                src={data.skateKey.image.src}
                alt={data.skateKey.image.alt}
              />
            </a>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default FortyTwoToFortyEight
