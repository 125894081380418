import React from 'react';
import { NavBar } from './components/';
import posed, { PoseGroup } from 'react-pose';
import { StickyContainer } from 'react-sticky';
import { useCookies } from 'react-cookie';
let uniqid = require('uniqid');

// Prevent users in china from accessing the site
const disallowedCountries = ['CN'];

function Layout(props) {
  const [cookies] = useCookies(['geoloc']);

  let { children, location } = props;
  let match = location.pathname;

  let countryCode = "US";
  let geoloc = cookies.geoloc;
  if(geoloc) {
    let geoArr = geoloc.split(",");
    countryCode = geoArr[0].split("=");
    countryCode = countryCode[1];
  }

  if(typeof window !== 'undefined' && disallowedCountries.includes(countryCode)) {
    window.location = "https://nike.com/skateboarding";
    return null;
  } else if (disallowedCountries.includes(countryCode)) {
    return null;
  }

  return (
    <div>
      {/* <!-- Google Tag Manager (noscript) */}
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NFNRT8J"
      height="0" width="0" style={{ display: 'none', visibility: 'hidden'}} title="title"></iframe></noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
      <StickyContainer>
        <NavBar {...{ match }} />
        {/*<Sticky>{({ style }) => <NavBar {...{ match, style }} />}</Sticky>*/}
        <PoseGroup>
          <Transition key={location.key || uniqid()}>{children}</Transition>
        </PoseGroup>
      </StickyContainer>
    </div>
  );
}

export default Layout;

const Transition = posed.div({
  enter: {
    opacity: 1,
    delay: 300,
    beforeChildren: true,
    transition: { duration: 300 }
  },
  exit: { opacity: 0 }
});
