import React, { Component } from 'react';
import MapComponent from "./MapComponent";

export default class MapContainer extends Component {
  constructor(props) {
    super(props);

    let mapHeight = 'auto';
    if(typeof window !== "undefined") {
      mapHeight = window.innerHeight - 161;
    } 

    this.state = {
      currentLatLng: {
        lat: 37.0902, 
        lng: -95.7129
      },
      prevLatLng: {
        lat: 37.0902, 
        lng: -95.7129
      },
      prevZoom: 4,
      zoom: 4,
      allShops: this.props.shops,
      activeMarkers: this.props.shops,
      activeShop: null,
      resultsOpen: false,
      mapHeight: mapHeight,
      olTooltipOpen: false,
      profileTooltipOpen: false,
      onlyOL: false,
      onlyProfiles: false,
      activeSearch: false,
      activeMarkerCount: {},
    }
    this.searchBox = React.createRef();
    this.googleMap = React.createRef();
  }

  showCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.setState(prevState => ({
            currentLatLng: {
              ...prevState.currentLatLng,
              lat: position.coords.latitude,
              lng: position.coords.longitude
            },
            zoom: 10,
            isMarkerShown: true
          }));
          
          this._handleBoundsChange();
        }
      )
    }
  }

  _clearSearch = () => {
    this.setState({
      activeSearch: false,
      zoom: 4,
      currentLatLng: {
        lat: 37.0902, 
        lng: -95.7129
      }
    });

    document.getElementById("search-input").value = "";

    this._handleBoundsChange();
  }

  _toggleResults = () => {
    this.setState({resultsOpen: !this.state.resultsOpen});
  }

  _toggleOL = (e) => {
    // check for event, since this function can be called programmatically by
    // componentDidMount if arriving on this page from OL landing page
    if (e) {
      e.stopPropagation();
    }

    if (this.state.onlyOL) {
      this.setState({
        olTooltipOpen: !this.state.olTooltipOpen
      });

      return;
    }

    let onlyOL = !this.state.onlyOL;
    let olTooltipOpen = !this.state.olTooltipOpen;

    let activeMarkers = this.state.allShops.filter((marker) => {
      if(onlyOL && !marker["OL?"]) {
        return false;
      }
      return this.googleMap.current.getBounds().contains({lat: marker.Latitude, lng: marker.Longitude});
    });

    this.setState({
      activeMarkers,
      onlyOL,
      olTooltipOpen,
      onlyProfiles: false,
      profileTooltipOpen: false,
    });
  }

  _toggleProfiles = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.state.onlyProfiles) {
      this.setState({
        profileTooltipOpen: !this.state.profileTooltipOpen
      });

      return;
    }

    let onlyProfiles = !this.state.onlyProfiles;
    let profileTooltipOpen = !this.state.profileTooltipOpen;

    let activeMarkers = this.state.allShops.filter((marker) => {
      if(onlyProfiles && !marker["Enabled"]) {
        return false;
      }
      return this.googleMap.current.getBounds().contains({lat: marker.Latitude, lng: marker.Longitude});
    });

    this.setState({
      activeMarkers,
      onlyOL: false,
      olTooltipOpen: false,
      onlyProfiles,
      profileTooltipOpen
    });
  }

  _handleTooltipBodyClick = (e) => {
    // Stop propagation to prevent triggering this same filter's toggle function,
    // which is present on the parent and will toggle the filter off. Cicks on
    // this tooltip should only work on the close X and on the 'learn more'
    // link in the OL tooltip.
    e.stopPropagation();
  }

  _closeTooltip = (e) => {
    e.stopPropagation();

    this.setState({
      olTooltipOpen: false,
      profileTooltipOpen: false
    })
  }

  _toggleAll = (e) => {
    e.stopPropagation();

    let activeMarkers = this.state.allShops.filter((marker) => {
      return this.googleMap.current.getBounds().contains({lat: marker.Latitude, lng: marker.Longitude});
    });

    this.setState({
      activeMarkers,
      onlyOL: false,
      onlyProfiles: false,
      olTooltipOpen: false,
      profileTooltipOpen: false,
    })
  }

  _handleZoom = (prop) => {
    const zoom = this.googleMap.current.getZoom();

    if (zoom !== this.state.zoom) {
      this.setState({zoom});
    }
    this._handleBoundsChange();
  }

  _handleBoundsChange = () => {
    if(this.googleMap.current) {
      // active markers based on current filter (all/profile/OL)
      let activeMarkers = this.state.allShops.filter((marker) => {
        if((this.state.onlyOL && !marker["OL?"]) || (this.state.onlyProfiles && !marker["Enabled"])) {
          return false;
        }
        return this.googleMap.current.getBounds().contains({lat: marker.Latitude, lng: marker.Longitude});
      });

      this._countActiveMarkers();

      this.setState({
        activeMarkers,
        activeShop: null,
      });
    }
  }

  _countActiveMarkers = () => {
    let allActiveMarkers = this.state.allShops;

    if(this.googleMap.current) {
      // number of markers in current view, regardless of current filter
      allActiveMarkers = this.state.allShops.filter((marker) => {
        return this.googleMap.current.getBounds().contains({lat: marker.Latitude, lng: marker.Longitude});
      });
    }

    let activeMarkerCount = {
      all: 0,
      profile: 0,
      ol: 0
    }

    allActiveMarkers.forEach((marker) => {
      if (marker["OL?"]) {
        activeMarkerCount.ol++;
      }
      if (marker["Enabled"]) {
        activeMarkerCount.profile++;
      }
      activeMarkerCount.all++;
    });

    this.setState({
      activeMarkerCount
    });
  }

  _updateLocation = () => {
    let location = this.searchBox.current.getPlaces();
    location = location[0];

    this.googleMap.current.fitBounds(location.geometry.viewport);

    this.setState({
      activeShop: null,
      activeSearch: true
    });

    this._handleBoundsChange();
  }

  componentDidMount() {
    this.showCurrentLocation();
    this._countActiveMarkers();

    if(typeof window !== "undefined") {
      window.onresize = () => {
        this.setState({
          mapHeight: window.innerHeight - 161
        });
      };

      window.onclick = () => {
        this.setState({
          olTooltipOpen: false,
          profileTooltipOpen: false,
        });
      }

      // This hash is only present on a link on the OL landing page. If we're
      // arriving from there we want to programmatically filter the map.
      if (window.location.hash === "#orangelabel" || window.location.hash === "#profiles") {
        const hash = window.location.hash;

        const mapCheck = setInterval(() => {
          if (this.googleMap.current !== null && this.googleMap.current.getBounds()) {
            clearInterval(mapCheck);

            if (hash === "#orangelabel") {
              this._toggleOL();
            } else if (hash === "#profiles") {
              this._toggleProfiles();
            }
          }
        }, 100);
      }

      let mapHeight = window.innerHeight - 161;
      this.setState({mapHeight});
    }
  }

  goToShop = (shop) => {
    let prevLatLng = this.state.prevLatLng;
    let prevZoom = this.state.prevZoom; 

    if(this.state.activeShop === shop) {
      return false;
    } else if (!this.state.activeShop) {
      prevLatLng = this.googleMap.current.getCenter();
      prevZoom = this.googleMap.current.getZoom();
    }

    this.setState({
      activeShop: shop,
      currentLatLng: {"lat": shop.Latitude, "lng": shop.Longitude},
      prevLatLng: prevLatLng,
      prevZoom: prevZoom,
      resultsOpen: true,
      zoom: 15
    });
  }

  backToResults = () => {
    this.setState({
      activeShop: null,
      currentLatLng: this.state.prevLatLng,
      zoom: this.state.prevZoom
    });
  }

  render() {
    let key = "key=AIzaSyAdwDy1xIvoeSO_amFW0UaLoUrfv0jrLaU&";

    return (
      <div className="map-ctnr" style={{ height: `${this.state.mapHeight}px` }}>
        <MapComponent
          isMarkerShown
          googleMapURL={`https://maps.googleapis.com/maps/api/js?${key}v=3.exp&libraries=places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `${this.state.mapHeight}px` }} />}
          mapElement={<div style={{ height: `100%` }}/>}
          latlng={this.state.currentLatLng}
          markers={this.state.activeMarkers}
          allShops={this.state.allShops}
          onZoomChanged={this._handleZoom}
          onDragEnd={this._handleBoundsChange}
          reference={this.googleMap}
          searchRef={this.searchBox}
          zoom={this.state.zoom}
          backToResults={this.backToResults}
          goToShop={this.goToShop}
          activeShop={this.state.activeShop}
          updateLocation={this._updateLocation}
          toggleResults={this._toggleResults}
          resultsOpen={this.state.resultsOpen}
          olTooltipOpen={this.state.olTooltipOpen}
          profileTooltipOpen={this.state.profileTooltipOpen}
          handleTooltipBodyClick={this._handleTooltipBodyClick}
          closeTooltip={this._closeTooltip}
          toggleOL={this._toggleOL}
          onlyOL={this.state.onlyOL}
          toggleAll={this._toggleAll}
          toggleProfiles={this._toggleProfiles}
          onlyProfiles={this.state.onlyProfiles}
          activeSearch={this.state.activeSearch}
          clearSearch={this._clearSearch}
          activeMarkerCount={this.state.activeMarkerCount}
        />
      </div>
    );
  }
}
