import React from 'react'

const FiveToEight = ({data, links, randomizedImageCounts}) => {
  let footwearBlaze = Math.ceil(Math.random() * randomizedImageCounts['footwear-blaze']);
  let blazersFilename = 'blazers' + footwearBlaze.toString().padStart(3, '0') + '.png'
  const [blazersFile] = React.useState(blazersFilename)
  
  let footwearBruin = Math.ceil(Math.random() * randomizedImageCounts['footwear-bruin']);
  let bruinFilename = 'bruins' + footwearBruin.toString().padStart(3, '0') + '.png'
  const [bruinFile] = React.useState(bruinFilename)
  
  let footwearDunk = Math.ceil(Math.random() * randomizedImageCounts['footwear-dunk']);
  let dunksFilename = 'dunks' + footwearDunk.toString().padStart(3, '0') + '.png'
  const [dunkFile] = React.useState(dunksFilename)
  
  let footwearJanoski = Math.ceil(Math.random() * randomizedImageCounts['footwear-janoski']);
  let janoskiFilename = 'janoski' + footwearJanoski.toString().padStart(3, '0') + '.png'
  const [janoskiFile] = React.useState(janoskiFilename)

  return (
    <React.Fragment>
      {
        (links.dunks.url !== "") && (
          <div key={data.dunks.id} className={`sandysplace__footwear sandysplace__${data.dunks.id}`}>
            <img
                src={'/icons/sandysplace/footwear-dunk/' + dunkFile}
                alt={data.dunks.image.alt} 
              /> 
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 364.09 248.77">  
               <a href={links.dunks.url} {...(links.dunks.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
                })}>
                 <path id="Footwear_Dunk" data-name="Footwear Dunk" fill="transparent" d="M282.79,1.33c-20.28-4.65-65.26,4.12-92.83,8-25.12,3.53-29.77,5.21-42.42,3-50.09-8.84-90.61.15-119.26,17.86C10.23,41.33.19,68.3,0,84.49s11.91,59.72,75,67.16c0,0-27,16.37-27,42.61s49.86,54.51,95.26,54.51c21.21,0,30.51-3.54,41.86-3.54s17.67-.74,27.72-4.83c16.68-6.8,23.25-10.24,38.14-20.47s33.49-27.72,49.11-36.84,39.08-15.62,46-41.3c2.79-10.42,2.43-8.72,7.81-18,2.79-4.84.19-10.61,1.68-14s8.55-13,8.55-24.93S362.42,64.77,362.42,62c0-6.89-8.86-26.23-23.81-28.1-6-.74-6.26-8.76-17.31-16.37C310,9.7,301.14,5.53,282.79,1.33Z"/>
               </a> 
           </svg>
          </div>
        )
      }
      {
        (links.blazers.url !== "") && (
          <div key={data.blazers.id} className={`sandysplace__footwear sandysplace__${data.blazers.id}`}>
              <img
                src={'/icons/sandysplace/footwear-blaze/' + blazersFile}
                alt={data.blazers.image.alt}
              />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370.29 339.13">
                <a href={links.blazers.url} {...(links.blazers.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
                })}>
                  <path id="Footwear_Blazer" data-name="Footwear Blazer" fill="transparent" d="M359.75,204.06c-3,6.22-56.09,42.14-70.6,54.42s-100.47,80.65-161.3,80.65-77-39.62-77-62.23A30.72,30.72,0,0,1,63.1,252.06s-51.81,9.58-60.28-22.32c-7-26.37-.55-50.79,14.51-62.79s42.14-22,42.14-22,2.8-12.28,17.31-8.93c0,0,3.62-10.32,14-7.53,0,0,6.62-8.29,15.55-6.34,0,0,.26-16.18,14.59-12.64,0,0,3.53-10.23,11.72-6.7,0,0-1.49-13.3,11.91-12.46,0,0-2.14-13.77,9-8.75a19.23,19.23,0,0,1,12.37-13.21s-26-46.14,20.47-43.53c0,0,3.53-17.3,32.56-17.3,20.65,0,60.46-9.4,68.27-7.17S303.38,8.25,303,25c-.24,10.74-2.61,34.42-2.61,34.42s27.73-11.35,51.26-7.16c25.54,4.54,17.58,25.86,17,32.28s-7,43.35-7,61.21S366.17,190.67,359.75,204.06Z"/>
                </a>
              </svg>
          </div>
        )
      }
      {
        (links.janoskis.url !== "") && (
          <div key={data.janoski.id} className={`sandysplace__footwear sandysplace__${data.janoski.id}`}>
              <img
                src={'/icons/sandysplace/footwear-janoski/' + janoskiFile}
                alt={data.janoski.image.alt}
              />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 371.5 229.23">
                <a href={links.janoskis.url} {...(links.janoskis.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
                })}>
                  <path id="Footwear_Janoski" data-name="Footwear - Janoski" fill="transparent"  d="M0,125.86c12,16.56,168.84,94,244.74,101.21s97.93-4.36,119.91-25.68c4.61-4.46,5.91-16.32,6.42-25.39s2.28-16.93-8.47-16.93c-2.79-9.77-60.93-39.07-84.09-51.35-21.6-11.45-16.28-16-21-19.07s-13.86-4.09-13.86-4.09C243.81,71.72,228,73.4,228,73.4c-1-6-11.16-6.7-11.16-6.7-1.12-12.28-10.33-7.54-10.33-7.54-4.37-11.53-11.07-9.76-11.07-9.76C196.56,28.28,185,36.28,185,36.28,187.63,14.51,170,26,170,26,158.6,31.26,147.81,0,130.05,0c-13,0-29.4,14.6-38.7,24.65C78.42,24.56,55.44,5.58,34.05,5.58c-19.26,0-31,22-31,41.58C3.07,58.88,0,125.86,0,125.86Z"/>
                </a>
              </svg>
          </div>
        )
      }
      {
        (links.bruins.url !== "") && (
          <div key={data.bruin.id} className={`sandysplace__footwear sandysplace__${data.bruin.id}`}>
              <img
                src={'/icons/sandysplace/footwear-bruin/' + bruinFile}
                alt={data.bruin.image.alt}
              />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 361.86 423.35">
                <a href={links.bruins.url} {...(links.bruins.newTab && {
                  target: "_blank",
                  rel: "noopener noreferrer",
                })}>
                  <path id="Footwear_Bruin" data-name="Footwear - Bruin" fill="transparent" d="M278.23,392.09c9.66-8.82,18-33.39,20-47.35s14-66.79,23.44-92.46c8.79-23.79,40.19-83.72,40.19-143.44s-35.16-85.4-58.79-85.4c-16.19,0-27.91,11.16-27.91,11.16S262,0,228.56,0c-39.91,0-68.65,38.51-78.42,66.14C139.3,96.79,129.21,127.26,82.88,192c-29.62,41.41-71.16,83.72-71.16,105.77a55.76,55.76,0,0,0,4.75,22.88L0,344.09s20.09-29,98.79-29c36.84,0,50.79,38.51,50.79,38.51l.84,69.77,24-23.16s9.21,13.39,22.88,13.39,27.91-12.84,50.23-12.84C263.63,400.74,271.81,398,278.23,392.09Z"/>
                </a> 
              </svg>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default FiveToEight
