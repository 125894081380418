import React, { Component } from 'react';
import './GridSearch.scss';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';
import { CSSTransition } from 'react-transition-group';
import { IconFilter } from '../../icons';

class MultiSelectDownshift extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedTags: [] };
  }

  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: false,
          inputValue: ''
        };
      case Downshift.stateChangeTypes.blurInput:
        if (document.activeElement.closest('.grid-search-form__menu')) {
          return {
            ...changes,
            inputValue: state.inputValue,
            isOpen: true
          };
        }

        return {
          ...changes,
        }
      default:
        return changes;
    }
  };

  handleSelection = (selectedTag, downshift) => {
    const callOnChange = () => {
      const { onSelect, onChange } = this.props;
      const { selectedTags } = this.state;
      if (onSelect) {
        onSelect(selectedTags, this.getStateAndHelpers(downshift));
      }
      if (onChange) {
        onChange(selectedTags, this.getStateAndHelpers(downshift));
      }
    };
    if (this.state.selectedTags.includes(selectedTag)) {
      this.removeTag(selectedTag, callOnChange);
    } else {
      this.addSelectedTag(selectedTag, callOnChange);
    }
  };

  removeTag = (tag, cb) => {
    this.setState(
      ({ selectedTags }) => ({
        selectedTags: selectedTags.filter(i => i !== tag)
      }),
      cb
    );
  };

  removeAllTags = () => {
    this.setState(
      () => ({
        selectedTags: []
      }),
      () => {
        this.props.onChange(this.state.selectedTags);
      }
    );
  };

  addSelectedTag(tag, cb) {
    this.setState(
      () => ({
        selectedTags: [tag]
      }),
      cb
    );
  }

  getRemoveButtonProps = ({ onClick, tag, ...props } = {}) => {
    return {
      onClick: e => {
        // TODO: use something like downshift's compose EventHandlers utility instead
        onClick && onClick(e);
        e.stopPropagation();
        this.removeTag(tag, () => this.props.onChange(this.state.selectedTags));
      },
      ...props
    };
  };

  getStateAndHelpers(downshift) {
    const { selectedTags } = this.state;
    const { getRemoveButtonProps, removeTag, removeAllTags } = this;
    return {
      getRemoveButtonProps,
      removeTag,
      removeAllTags,
      selectedTags,
      ...downshift
    };
  }
  render() {
    const { render, children = render, ...props } = this.props;
    // TODO: compose together props (rather than overwriting them) like downshift does

    // Note that because we cannot attach arbitrary props to this <Downshift>
    // component, and because this is the only tracked event on the site that
    // isn't a `click`, the tracking eventListeners for this input field are
    // defined in the `Analytics.js` file instead of using a `data-track-click`
    // attribute as done everywhere else.
    return (
      <Downshift
        {...props}
        id="autocomplete"
        labelId="autocomplete-label"
        inputId="autocomplete-input"
        menuId="autocomplete-menu"
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        selectedItem={null}
      >
        {downshift => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}

export default class GridSearch extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = { scrollTop: 0, pad: false, lockoutNav: false };
    this.closeButtonRef = React.createRef();
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll());
  }

  componentDidUpdate(newProps) {
    if (newProps.selectedTags !== this.props.selectedTags) {
      this.setState({ lockoutNav: true });
      setTimeout(() => this.setState({ lockoutNav: false }), 300);
    }

    if(this.closeButtonRef.current && this.input.current) {
      this.closeButtonRef.current.addEventListener('focusout', (e) => {
        if(e.relatedTarget !== this.input.current) {
          this.input.current.focus();
        }
      });
    }
  }
  handleScroll = scrollTop => {
    if (this.state.scrollTop > window.scrollY && !this.state.lockoutNav) {
      this.setState({ scrollTop: window.scrollY, pad: true });
    } else {
      this.setState({ scrollTop: window.scrollY, pad: false });
    }
  };

  render() {
    let { popularTags, allTags, width, updateSelectedTags, style, showSurpriseMe } = this.props;

    const randomTag = allTags[Math.floor(Math.random() * allTags.length)];

    return (
      <MultiSelectDownshift
        onChange={selectedTags => {
          updateSelectedTags(selectedTags);
        }}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getMenuProps,
          getRemoveButtonProps,
          removeTag,
          removeAllTags,
          isOpen,
          inputValue,
          selectedTags,
          getItemProps,
          getLabelProps,
          highlightedIndex,
          toggleMenu
        }) => {
          let isMobile = false;

          return (
            <div
              style={{
                ...style,
                marginTop: this.state.pad && this.props.isSticky ? '47px' : '',
                transition: 'margin-top 0.3s ease'
              }}
              className={`grid-search-form--parent ${
                isOpen && !this.props.isSticky ? 'open' : ''
              }`}
            >
              <div
                className={`grid-search-form grid-search-form--${
                  this.props.isSticky ? 'sticky' : 'slippery'
                }`}
                onClick={() => {
                  if (!isOpen) {
                    toggleMenu({ inputValue: '' });
                  }
                  if (this.input.current) {
                    this.input.current.focus();
                  }
                }}
              >
                <label
                  className="grid-search-form__input-label"
                  {...getLabelProps()}
                >
                  Search for tags to filter content:
                </label>
                <div className="grid-search-form__input-container" data-grid-search-container>
                  {(!selectedTags || selectedTags.length < 1) && (
                    <input
                      ref={this.input}
                      data-track-keyup={JSON.stringify({
                        event_action: "Filter Input Change",
                        event_category: "Feed Filter",
                        event_label: `${inputValue}`,
                      })}
                      {...getInputProps({ ref: this.input })}
                      placeholder={isOpen ? '' : 'Search / Filter...'}
                      className="filter-input"
                    />
                  )}
                  {// on desktop always display tags in input container
                  !isMobile && (
                    <div className="grid-search-form__selected-tag-chips">
                      {selectedTags && selectedTags.length > 0
                        ? selectedTags.map((tag, index) => (
                            <div
                              className="tag-chip"
                              {...getRemoveButtonProps({ tag })}
                              key={tag}
                              data-filter-selected
                              data-track-click={JSON.stringify({
                                event_action: "Remove Tag",
                                event_category: "Feed Filter",
                                event_label: `${tag}`,
                              })}
                            >
                              {tag}
                              <span className="tag-chip__close close-icon" data-filter-close-button />
                            </div>
                          ))
                        : null}
                    </div>
                  )}

                  {// on mobile only display tags in input container when menu is closed
                  isMobile && !isOpen && (
                    <div className="grid-search-form__selected-tag-chips">
                      {selectedTags && selectedTags.length > 0
                        ? selectedTags.map((tag, index) => (
                            <div
                              className="tag-chip"
                              key={index}
                              data-filter-selected
                              data-track-click={JSON.stringify({
                                event_action: "Remove Tag",
                                event_category: "Feed Filter",
                                event_label: `${tag}`,
                              })}
                            >
                              {tag}
                              <span className="tag-chip__close close-icon" data-filter-close-button />
                            </div>
                          ))
                        : null}
                    </div>
                  )}

                  {selectedTags && selectedTags.length > 0 ? (
                    <button className="grid-search-form__clear-all" type="button" aria-label="Clear search" onClick={() => {
                      removeAllTags();
                      toggleMenu({ inputValue: '' });
                      if (this.input.current) {
                        this.input.current.blur();
                      }
                    }}>Clear</button>
                  ) : null}

                  <span className="grid-search-form__filter-icon"><IconFilter /></span>
                </div>

                <CSSTransition
                  in={isOpen}
                  timeout={{ exit: 300 }}
                  unmountOnExit={true}
                >
                  {state => (
                    <div
                      className={`grid-search-form__menu ${state}`}
                      {...getMenuProps()}
                    >
                      {(() => {
                        let matchingTags = matchSorter(allTags, inputValue, {threshold: matchSorter.rankings.CONTAINS});

                        return (
                          <React.Fragment>
                            <div className="mobile-selections">
                              {// on mobile only display tags in input container when menu is closed
                              isMobile && (
                                <div className="grid-search-form__menu__selected-tag-chips">
                                  {selectedTags && selectedTags.length > 0
                                    ? selectedTags.map((tag, index) => (
                                        <div
                                          className="tag-chip"
                                          {...getRemoveButtonProps({ tag })}
                                          key={index}
                                          data-filter-selected
                                          data-track-click={JSON.stringify({
                                            event_action: "Remove Tag",
                                            event_category: "Feed Filter",
                                            event_label: `${tag}`,
                                          })}
                                        >
                                          {tag}
                                          <span className="tag-chip__close close-icon" data-filter-close-button />
                                        </div>
                                      ))
                                    : null}
                                </div>
                              )}
                            </div>

                            <div className="suggestions-or-results">
                              {(inputValue === '' ||
                                matchingTags.length === 0) && (
                                <React.Fragment>
                                  {inputValue !== '' &&
                                  matchingTags.length === 0 ? (
                                    <h3 className="filter-label filter-label--alert">
                                      Bummer! Not finding a match for that. Try something else, or hit one of these:
                                    </h3>
                                  ) : (
                                    <h3 className="filter-label">Filter by:</h3>
                                  )}
                                  <ul {...getMenuProps()}>
                                    {popularTags
                                      .slice(0, 16)
                                      .map((tag, index) => (
                                        <li
                                          className={`filter-result ${
                                            selectedTags.includes(tag)
                                              ? 'selected'
                                              : ''
                                          }`}
                                          key={tag}
                                          {...getItemProps({
                                            item: tag,
                                            index
                                          })}
                                        >
                                          <button type="button"
                                            data-filter-result
                                            data-track-click={JSON.stringify({
                                              event_action: selectedTags.includes(tag)
                                                ? 'Popular Tag Click'
                                                : 'Remove Tag',
                                              event_category: "Feed Filter",
                                              event_label: `${tag}`,
                                            })}
                                          >
                                            {tag}
                                          </button>
                                        </li>
                                      ))}
                                  </ul>
                                  {showSurpriseMe && (
                                    <div>
                                      <button
                                        type="button"
                                        className="grid-search-form__surprise-me"
                                        {...getItemProps({ item: randomTag })}
                                      >
                                        <img src="/icons/misc/SurpriseMe.svg" alt="" />
                                        Surprise me
                                      </button>
                                    </div>
                                  )}
                                </React.Fragment>
                              )}

                              {inputValue !== '' && matchingTags.length !== 0 && (
                                <React.Fragment>
                                  <h3 className="filter-label">Results:</h3>
                                  <ul {...getMenuProps()}>
                                    {matchingTags
                                      .slice(0, 16)
                                      .map((tag, index) => (
                                        <li
                                          className={`filter-result ${
                                            selectedTags.includes(tag)
                                              ? 'selected'
                                              : ''
                                          }`}
                                          key={`${tag}-${index}`}
                                          {...getItemProps({
                                            item: tag,
                                            index
                                          })}
                                        >
                                          <button type="button"
                                            data-filter-result
                                            data-track-click={JSON.stringify({
                                              event_action: selectedTags.includes(tag)
                                                ? 'Remove Tag'
                                                : 'Tag Click',
                                              event_category: "Feed Filter",
                                              event_label: `${tag}`,
                                            })}
                                          >
                                            {tag}
                                          </button>
                                        </li>
                                      ))}
                                  </ul>
                                </React.Fragment>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })()}

                      <button
                        className="grid-search-form__close-btn"
                        ref={this.closeButtonRef}
                        onClick={() => toggleMenu({ inputValue: '' })}
                      >
                        <img src="/icons/nav/Close_ArrowUp.svg" alt="" />
                        <p className="eyebrow">Close</p>
                      </button>
                    </div>
                  )}
                </CSSTransition>
              </div>
            </div>
          );
        }}
      </MultiSelectDownshift>
    );
  }
}
