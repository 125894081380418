import React, { Component } from 'react';

export default class InstagramIcon extends Component {
  render() {
    return (
      <a
        href="https://instagram.com/nikesb"
        target="blank"
      >
        <svg aria-hidden="true" className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 23.9995C18.6274 23.9995 24 18.6269 24 11.9995C24 5.37209 18.6274 -0.000488281 12 -0.000488281C5.37258 -0.000488281 0 5.37209 0 11.9995C0 18.6269 5.37258 23.9995 12 23.9995ZM4.5 7.316C4.5 5.775 5.775 4.5 7.316 4.5H16.684C18.226 4.5 19.5 5.775 19.5 7.316V16.684C19.5 18.226 18.226 19.5 16.684 19.5H7.316C5.775 19.5 4.5 18.226 4.5 16.684V7.316ZM6.375 7.316C6.375 6.781 6.781 6.375 7.316 6.375H16.684C17.219 6.375 17.627 6.781 17.627 7.316V16.684C17.627 17.219 17.219 17.625 16.684 17.625H7.316C6.781 17.625 6.375 17.219 6.375 16.684V7.316ZM15.7497 7.31391C16.2667 7.31391 16.6867 7.73291 16.6867 8.24991C16.6867 8.76791 16.2687 9.18691 15.7497 9.18691C15.2317 9.18691 14.8127 8.76791 14.8127 8.24991C14.8127 7.73291 15.2327 7.31391 15.7497 7.31391ZM15.75 12C15.75 9.94 14.06 8.25 12 8.25C9.94 8.25 8.25 9.94 8.25 12C8.25 14.06 9.94 15.75 12 15.75C14.06 15.75 15.75 14.06 15.75 12ZM10.125 11.9998C10.125 10.9538 10.954 10.1228 12 10.1228C13.046 10.1228 13.875 10.9538 13.875 11.9998C13.875 13.0458 13.046 13.8748 12 13.8748C10.954 13.8748 10.125 13.0458 10.125 11.9998Z" fill="#C13584"/>
        </svg>
      </a>
    );
  }
}