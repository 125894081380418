import React, { Component } from 'react';
import "./Loading.scss";

export default class Loading extends Component {
  render() {
    return (
      <div className="loading__ctnr">
        {/* <img src="/icons/misc/loading.gif" /> */}
      </div>
    );
  }
}