import React, { Component } from 'react';
import './ArticleImage.scss';

// expected props
// image: object
// secondaryImage: object

export default class ArticleImage extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false
    }
  }
  changeModalClosed() {
    this.setState({modalOpen: false});
  }
  changeModalOpen() {
    this.setState({modalOpen: true});
  }
  render() {
    let { image, alignment, imageWidth } = this.props;
    const fontSize = image.caption && image.caption.length > 60 ? 'fs-small' : 'fs-large';
    let modalClass = this.state.modalOpen ? 'article-image__open' : '';

    return (
      <div className="article-image">
        <div className="ctnr">
          {image && image.asset &&
            <div className={`
                article-image__image
                article-image__image--${alignment}
                article-image__image--col-${imageWidth}
              `}
              dangerouslySetInnerHTML={{__html: image.asset}}
            />
          }
          {image.caption &&
            <div class="article-image__info">
              <img onClick={this.changeModalOpen.bind(this)} src="/icons/misc/NikeSB_Caption-Icon_M.png" alt="information" />
            </div>}
          {image.caption &&
            <div className={`article-image__group ${modalClass}`}>
              <img onClick={this.changeModalClosed.bind(this)} src="/icons/misc/NikeSB_CaptionClose-Icon_M.png" alt="close" />
              <p className={`article-image__caption ${fontSize}`}>{image.caption}</p>
            </div>}
        </div>
      </div>
    );
  }
}
